    <template>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 10.3332C9 9.07609 9 8.44755 9.39052 8.05703C9.78105 7.6665 10.4096 7.6665 11.6667 7.6665C12.9237 7.6665 13.5523 7.6665 13.9428 8.05703C14.3333 8.44755 14.3333 9.07609 14.3333 10.3332V11.6665C14.3333 12.9236 14.3333 13.5521 13.9428 13.9426C13.5523 14.3332 12.9237 14.3332 11.6667 14.3332C10.4096 14.3332 9.78105 14.3332 9.39052 13.9426C9 13.5521 9 12.9236 9 11.6665V10.3332Z"
                stroke="#AEBAD6" stroke-width="1.2" />
            <path
                d="M1.33331 5.6665C1.33331 6.92358 1.33331 7.55212 1.72384 7.94265C2.11436 8.33317 2.7429 8.33317 3.99998 8.33317C5.25706 8.33317 5.8856 8.33317 6.27612 7.94265C6.66665 7.55212 6.66665 6.92358 6.66665 5.6665V4.33317C6.66665 3.07609 6.66665 2.44755 6.27612 2.05703C5.8856 1.6665 5.25706 1.6665 3.99998 1.6665C2.7429 1.6665 2.11436 1.6665 1.72384 2.05703C1.33331 2.44755 1.33331 3.07609 1.33331 4.33317V5.6665Z"
                stroke="#AEBAD6" stroke-width="1.2" />
            <path
                d="M9 3.6665C9 3.04525 9 2.73462 9.10149 2.48959C9.23682 2.16289 9.49638 1.90332 9.82309 1.768C10.0681 1.6665 10.3787 1.6665 11 1.6665H12.3333C12.9546 1.6665 13.2652 1.6665 13.5102 1.768C13.8369 1.90332 14.0965 2.16289 14.2318 2.48959C14.3333 2.73462 14.3333 3.04525 14.3333 3.6665C14.3333 4.28776 14.3333 4.59839 14.2318 4.84342C14.0965 5.17012 13.8369 5.42968 13.5102 5.56501C13.2652 5.6665 12.9546 5.6665 12.3333 5.6665H11C10.3787 5.6665 10.0681 5.6665 9.82309 5.56501C9.49638 5.42968 9.23682 5.17012 9.10149 4.84342C9 4.59839 9 4.28776 9 3.6665Z"
                stroke="#AEBAD6" stroke-width="1.2" />
            <path
                d="M1.33331 12.3335C1.33331 12.9548 1.33331 13.2654 1.43481 13.5104C1.57013 13.8371 1.8297 14.0967 2.1564 14.232C2.40143 14.3335 2.71206 14.3335 3.33331 14.3335H4.66665C5.2879 14.3335 5.59853 14.3335 5.84356 14.232C6.17026 14.0967 6.42983 13.8371 6.56515 13.5104C6.66665 13.2654 6.66665 12.9548 6.66665 12.3335C6.66665 11.7122 6.66665 11.4016 6.56515 11.1566C6.42983 10.8299 6.17026 10.5703 5.84356 10.435C5.59853 10.3335 5.2879 10.3335 4.66665 10.3335H3.33331C2.71206 10.3335 2.40143 10.3335 2.1564 10.435C1.8297 10.5703 1.57013 10.8299 1.43481 11.1566C1.33331 11.4016 1.33331 11.7122 1.33331 12.3335Z"
                stroke="#AEBAD6" stroke-width="1.2" />
        </svg>
    </template>
<template>
	<div class="footer w-100 mt-auto">
		<div class="container">
			<b-row class="py-2">
				<b-col v-if="!$route.path.includes('multiview/')" cols="12" lg="4" class="text-s-s text-dark-7 text-center text-lg-left offset-lg-3 pt-3">
					<span>© {{ new Date().getFullYear() }} Castr. All rights reserved</span>
				</b-col>
				<b-col v-if="!$route.path.includes('multiview/')"
					cols="12"
					lg="4"
					md="12"
					class="pt-3"
				>
					<ul class="list-inline text-center text-lg-right mb-0">
						<li class="list-inline-item text-dark-6">
							<a
								href="https://facebook.com/castrlive"
								target="_blank"
								class="btn btn-link text-reset py-0"
							>	
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="16" height="16" transform="translate(0 0.5)" fill="black" fill-opacity="0.01"/>
								<path d="M4.9255 8.9238H6.35051V14.7903C6.35051 14.9061 6.44436 15 6.56018 15H8.97634C9.09216 15 9.18602 14.9061 9.18602 14.7903V8.95144H10.8242C10.9307 8.95144 11.0203 8.87151 11.0325 8.7657L11.2813 6.60594C11.2881 6.54652 11.2693 6.48701 11.2295 6.44244C11.1897 6.39782 11.1328 6.37228 11.073 6.37228H9.1861V5.01843C9.1861 4.61032 9.40584 4.40336 9.83929 4.40336C9.90106 4.40336 11.073 4.40336 11.073 4.40336C11.1889 4.40336 11.2827 4.30947 11.2827 4.19369V2.21123C11.2827 2.0954 11.1889 2.00155 11.073 2.00155H9.37276C9.36076 2.00096 9.33413 2 9.29488 2C8.99987 2 7.97442 2.05791 7.16439 2.80311C6.26689 3.6289 6.39165 4.61766 6.42146 4.78909V6.37224H4.9255C4.80967 6.37224 4.71582 6.46609 4.71582 6.58191V8.71408C4.71582 8.82991 4.80967 8.9238 4.9255 8.9238Z" fill="currentColor"/>
							</svg>
							</a>
						</li>
						<li class="list-inline-item text-dark-6">
							<a
								href="https://twitter.com/castrHQ"
								target="_blank"
								class="btn btn-link text-reset py-0"
							>
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="16" height="16" transform="translate(0 0.5)" fill="black" fill-opacity="0.01"/>
								<path d="M14.8562 4.4228C14.3514 4.63698 13.8098 4.78224 13.2407 4.84708C13.8218 4.51389 14.2666 3.9854 14.4774 3.3576C13.9323 3.66615 13.3307 3.89021 12.6896 4.01166C12.1762 3.48727 11.446 3.16064 10.636 3.16064C9.08216 3.16064 7.82227 4.367 7.82227 5.85403C7.82227 6.06492 7.84713 6.27091 7.89513 6.46788C5.55706 6.35544 3.48383 5.28285 2.09624 3.65302C1.85368 4.05023 1.7157 4.51305 1.7157 5.0071C1.7157 5.94184 2.2128 6.76659 2.96702 7.24912C2.50591 7.23435 2.07224 7.1129 1.69257 6.91101V6.94466C1.69257 8.24949 2.66277 9.33849 3.94922 9.58634C3.71352 9.64707 3.46498 9.68072 3.20786 9.68072C3.02617 9.68072 2.85046 9.66349 2.67819 9.63066C3.03643 10.7016 4.07521 11.4804 5.30597 11.5018C4.34347 12.2239 3.12986 12.6531 1.81171 12.6531C1.58459 12.6531 1.36088 12.64 1.14062 12.6162C2.38595 13.3819 3.86439 13.8283 5.45339 13.8283C10.6292 13.8283 13.4584 9.7234 13.4584 6.16342L13.449 5.81465C14.0018 5.43712 14.48 4.96279 14.8562 4.4228Z" fill="currentColor"/>
							</svg>
							</a>
						</li>
						<li class="list-inline-item text-dark-6">
							<a
								href="https://www.linkedin.com/company/castrlive"
								target="_blank"
								class="btn btn-link text-reset py-0"
							>
							<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0415039 2.19266C0.0415039 1.28114 0.781151 0.541504 1.69268 0.541504C2.60363 0.541504 3.34327 1.28114 3.34385 2.19266C3.34385 3.10417 2.6042 3.85928 1.69268 3.85928C0.781151 3.85928 0.0415039 3.10417 0.0415039 2.19266ZM13.7882 14.2916V14.291H13.7917V9.24817C13.7917 6.78117 13.2606 4.8808 10.3764 4.8808C8.98996 4.8808 8.05952 5.64164 7.67967 6.36294H7.63957V5.11111H4.90499V14.291H7.75244V9.74546C7.75244 8.54863 7.97931 7.39133 9.46148 7.39133C10.9219 7.39133 10.9436 8.75717 10.9436 9.82223V14.2916H13.7882ZM0.268839 5.11179H3.11972V14.2917H0.268839V5.11179Z" fill="currentColor"/>
							</svg>
							</a>
						</li>
					</ul>
				</b-col>
			</b-row>
		</div>
		<UploadFiles />
	</div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex';
import SubscriptionService from '@/services/SubscriptionService'
import UploadFiles from "@/components/ui/UploadFiles.vue"

export default {
	name: 'AppFooter',
	components: {
		UploadFiles
	},
  	async mounted () {
		try {
			if (this.baseSubscription) {
				this.baseSubscription.unsubscribeProcessing = false
			}
			this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};

			if (_.isArray(this.userSubscription.addonSubscriptions)) {
				this.userSubscription.addonSubscriptions.forEach((sub) => {
					if (sub.package.baseCharge > 0) {
						this.paidSub = true;
					}
				});
			}

			const baseCharge = _.get(this.userSubscription, ['subscription', 'package', 'baseCharge'], 0);
			if (baseCharge > 0) {
				this.paidSub = true;
			}
		} catch (e) {
			console.log('error', e)
		}
	},
	computed: {
		...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		}
	},
  	data() {
   		return {
      		userSubscription: null,
      		paidSub: false
    	}
  	}
}
</script>

<style scoped>

</style>

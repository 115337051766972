<template>
  <div :class="{ expanded: video.expanded }" class="video-item">
    <div class="flex items-start md:items-center py-2 text-tiny md:text-sm">
      <div class="w-4/12">
        <div class="video-name">
          <div v-if="video.exportStatus === 'EXPORTED'" class="title">
            <Title class="w-full h-8 p-2 -mx-2 flex items-center hover:bg-surface-3 text-sm" :editable="true"
              :title="archiveName" @changed="onNameChange" @edit="" />
            <span class="text-sm font-medium text-surface-7 mr-2">{{
              video.size
              }}</span>
            <span v-if="video.abr">
              <Badge type="orange" class="inline-flex">ABR</Badge>
            </span>
          </div>
          <span class=" text-dark-9" v-if="video.exportStatus !== 'EXPORTED'">{{ archiveName }}</span>
          <span class="ml-2" v-if="video.exportStatus !== 'EXPORTED' && video.abr">
            <Badge type="orange" class="inline-flex">ABR</Badge>
          </span>
          <span class="ml-2" v-if="video.exportStatus !== 'EXPORTED' && isLive && streamDvrEnabled">
            <Badge type="online" class="inline-flex">LIVE</Badge>
          </span>
        </div>
      </div>
      <div class="w-3/12 md:w-2/12 text-surface-9">
        <span class="text-dark-9"
          v-if="video.exportStatus === 'EXPORTED' || video.exportStatus === 'EXPORTING' || (video.duration && !isLive)">
          {{ humanTime(video.duration) || "Unknown" }}
        </span>
      </div>
      <div class="w-3/12 md:w-2/12 text-surface-9">
        {{ dateFormated(video.startTimeDate) }}
      </div>
      <div class="self-center w-2/12 md:w-4/12">
        <div class="text-right pr-0 recording-action text-nowrap flex gap-x-2 items-center justify-end">
          <span v-if="video.exportStatus == 'EXPORTING'">
            <span class="d-none d-md-inline mr-2">Exporting</span>
            <i class="fas fa-spinner fa-spin spinner-mobile" />
          </span>
          <FormButton class="d-none d-md-inline-flex" v-if="video.exportStatus === 'EXPORTED'" type="secondary"
            size="sm" @click="toggleMetadata(video)" :disabled="expiredSub">
            Embed Link
          </FormButton>
          <drop-down-menu size="sm" type="secondary" class="d-none d-md-inline-flex"
            v-if="video.exportStatus === 'NOT_EXPORTED'" position="right" :disabled="video.exportProcessing">
            <template #toggle-button>
              Save as VOD
              <span class="sr-only">Save as VOD</span>
            </template>
            <template #menu-items>
              <drop-down-menu-item @click="onVodSaveWhole">
                <icon-base class="mr-2">
                  <icon-download />
                </icon-base>
                Save whole video
              </drop-down-menu-item>
              <drop-down-menu-item @click="onVodSaveCustom('Audio track 1')">
                <icon-base class="mr-2">
                  <icon-select-range />
                </icon-base>
                Select a range
              </drop-down-menu-item>
            </template>
          </drop-down-menu>
          <ejs-tooltip v-if="video.exportStatus === 'FAILED'" content="Exporting process failed, try again">
            <icon-base class="text-danger" fill="none">
              <icon-info />
            </icon-base>
          </ejs-tooltip>
          <FormButton type="secondary" size="sm" v-if="video.exportStatus === 'FAILED' && !isLive"
            @click="exportDvrArchive2">
            Try again
          </FormButton>
          <FormButton class="d-none d-md-inline-flex shrink-0" v-if="video.exportStatus === 'NOT_EXPORTED'" size="sm"
            isIcon type="secondary" @click="playPreview('Audio track 1')">
            <icon-base>
              <icon-play />
            </icon-base>
          </FormButton>
          <FormButton class="d-none d-md-inline-flex" v-if="
            video.exportStatus === 'EXPORTED' && video.downloadUrl && allowOverdueVodDownload && (!expiredSub || video.exportStatus === 'NOT_EXPORTED')
          " size="sm" isIcon type="secondary" @click="downloadDvrArchive()">
            <icon-base>
              <icon-download />
            </icon-base>
          </FormButton>
          <span class="inline-flex gap-x-2 items-center" v-else-if="
            video.exportStatus !== 'EXPORTING' && video.downloadUrl && allowOverdueVodDownload && (!expiredSub || video.exportStatus === 'NOT_EXPORTED')
          ">
            <drop-down-menu size="sm" customClasses="button !border-none form-button inline-flex items-center !rounded-md leading-none text-nowrap box-border font-medium hover:no-underline disabled:bg-surface-5 disabled:ring-surface-5 disabled:text-surface-7 max-w-full truncate grow-0 p-0 justify-center border-none h-7 w-7 text-tiny text-surface-text-1 hover:text-white hover:text-white ring-1 ring-surface-4 hover:text-white hover:ring-surface-6 d-none d-md-inline-flex" listClasses="overflow-y-visible" class="!hidden md:!inline-flex"
              position="right" :disabled="video.exportProcessing">
              <template #toggle-button>
                <icon-base fill="none">
                  <icon-download />
                </icon-base>
                <span class="sr-only">Download</span>
              </template>
              <template #menu-items>
                <drop-down-menu-item @click="downloadDvrArchive()">
                  <icon-base class="mr-2" fill="none">
                    <icon-download />
                  </icon-base>
                  Download whole video
                </drop-down-menu-item>
                <drop-down-menu-item @click="onVodSaveCustom">
                  <icon-base class="mr-2" fill="none">
                    <icon-select-range />
                  </icon-base>
                  Select a range
                </drop-down-menu-item>
                <drop-down-menu-item isCustom>
                <drop-down-menu
                  position="right"
                  customClasses="h-8 p-2 hover:bg-surface-3 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full">
                  <template #toggle-button>
                    <icon-base class="mr-2">
                      <icon-gear />
                    </icon-base>
                    Download Video with specific audio track
                    <icon-base class="ml-2">
                      <icon-chevron-right />
                    </icon-base>
                  </template>
                  <template #menu-items>
                    <drop-down-menu-item @click="onVodSaveCustom('Audio track 1')">
                      Video with Audio track 1
                    </drop-down-menu-item>
                    <drop-down-menu-item @click="onVodSaveCustom('Audio track 2')">
                      Video with Audio track 2
                    </drop-down-menu-item>
                    <drop-down-menu-item @click="onVodSaveCustom('Audio track 3')">
                      Video with Audio track 3
                    </drop-down-menu-item>
                    <drop-down-menu-item @click="onVodSaveCustom('Audio track 4')">
                      Video with Audio track 4
                    </drop-down-menu-item>
                  </template>
                </drop-down-menu>
                <drop-down-menu
                  position="right"
                  customClasses="h-8 p-2 hover:bg-surface-3 hover:text-white text-nowrap leading-none text-tiny flex items-center w-full">
                  <template #toggle-button>
                    <icon-base class="mr-2">
                      <icon-gear />
                    </icon-base>
                    Download Audio track only
                    <icon-base class="ml-2">
                      <icon-chevron-right />
                    </icon-base>
                  </template>
                  <template #menu-items>
                    <drop-down-menu-item @click="onVodAudioSaveCustom('Audio track 1')">
                      Audio track 1
                    </drop-down-menu-item>
                    <drop-down-menu-item @click="onVodAudioSaveCustom('Audio track 2')">
                      Audio track 2
                    </drop-down-menu-item>
                    <drop-down-menu-item @click="onVodAudioSaveCustom('Audio track 3')">
                      Audio track 3
                    </drop-down-menu-item>
                    <drop-down-menu-item @click="onVodAudioSaveCustom('Audio track 4')">
                      Audio track 4
                    </drop-down-menu-item>
                  </template>
                </drop-down-menu>
              </drop-down-menu-item>
              </template>
            </drop-down-menu>
            <FormButton type="danger" size="sm" class="d-none d-md-inline-flex" isIcon
              @click="requestDeleteDvrArchive()">
              <icon-base fill="none">
                <icon-bin />
              </icon-base>
            </FormButton>
          </span>
          <ejs-tooltip v-if="
            (video.exportStatus !== 'EXPORTING' && video.downloadUrl && !allowOverdueVodDownload) || (expiredSub && video.exportStatus === 'EXPORTED')
          " :content="disabledTooltipMessage" class="d-inline-block" tabindex="0">
            <FormButton type="danger" size="sm" class="" isIcon disabled>
              <icon-base fill="none">
                <icon-download />
              </icon-base>
            </FormButton>
          </ejs-tooltip>
          <drop-down-menu size="sm" isIcon no-border type="secondary" :class="{'md:hidden': video.exportStatus != 'EXPORTED'}" class="inline-flex" position="right">
            <template #toggle-button>
              <icon-base fill="none">
                <icon-elipsis />
              </icon-base>
            </template>
            <template #menu-items>
              <drop-down-menu-item v-if="video.exportStatus === 'EXPORTED'" isCustom>
                 <button class="p-2 grid grid-flow-col gap-x-2 grid-rows-2" @click="onCreatePrerecord(video)" :disabled="disableCreatePreRecorderedStream">
                    <icon-base class="text-surface-7 row-span-3 mt-1" fill="none">
                    <icon-camera-plus />
                  </icon-base>
                  <p class="flex items-center">
                    Create a pre-record stream
                    <Badge :type="scheduledStreams.length < maxScheduledStreams ? 'main' : 'error'" class="ml-1">
                      {{ scheduledStreams.length }}/{{ maxScheduledStreams }}
                    </Badge>
                  </p>
                  <p class="text-s-s text-dark-6">Schedule time for this file to go live</p>
                 </button>
                <FormButton v-if="scheduledStreams.length >= maxScheduledStreams" size="sm"
                  :disabled="(disableCreatePreRecorderedStream || isOwner && isSubAccount)"
                  to="/subscribe?category=live" class="place-self-start">
                  Upgrade
                </FormButton>
              </drop-down-menu-item>
              <drop-down-menu-item class="block md:hidden"
                v-if="video.exportStatus !== 'EXPORTING' && !isLive && video.downloadUrl"
                @click="downloadDvrArchive()">Download</drop-down-menu-item>
              <drop-down-menu-item class="block md:hidden" v-if="video.exportStatus === 'EXPORTED'"
              @click="toggleMetadata(video)">Embed link</drop-down-menu-item>
              <drop-down-menu-item class="block md:hidden" v-if="video.exportStatus === 'NOT_EXPORTED' && !isLive"
                @click="playPreview()">
                Preview
              </drop-down-menu-item>
              <drop-down-menu-item class="block md:hidden" v-if="video.exportStatus === 'NOT_EXPORTED' && !isLive"
                @click="onVodSaveWhole">
                Save whole video
              </drop-down-menu-item>
              <drop-down-menu-item v-if="video.exportStatus === 'NOT_EXPORTED' && !isLive" @click="onVodSaveCustom">
                Select a range
              </drop-down-menu-item>
              <drop-down-menu-item @click="requestDeleteDvrArchive()">
                <icon-base class="mr-2" fill="none">
                  <icon-bin />
                </icon-base>
                Delete
              </drop-down-menu-item>
            </template>
          </drop-down-menu>
        </div>
      </div>
    </div>
    <ejs-accordion :ref="'metaDataCollapse' + video.from + video.duration + video.episodeId" class="accordion-hidden">
      <e-accordionitems>
        <e-accordionitem :content="'metaDataContent'">
        </e-accordionitem>
        <template v-slot:metaDataContent>
          <div class="flex text-tiny space-x-4 border-b border-surface-7 overflow-x-auto max-w-full my-6">
						<button  class="capitalize hover:text-white py-1 border-b-2 flex items-center text-nowrap"  :class="embedContentActiveTab === 'full' ? 'border-surface-9 text-white': 'border-transparent text-surface-8'" @click="switchEmbedContentTab('full')">
							Video + Audio
						</button>
            <button  class="capitalize text-surface-8 hover:text-white hover:no-underline py-1 border-b-2 flex items-center text-nowrap"  :class="embedContentActiveTab === 'audio' ? 'border-surface-9 text-white': 'border-transparent text-surface-8'" @click="switchEmbedContentTab('audio')">
							Audio only
						</button>
					</div>
          <div class="field-container">
            <FormButton class="absolute right-7 top-8" isLink isIcon type="link" size="sm"
              @click="clipboardCopy(getPlayerUrl(video))">
              <icon-base class="" fill="none">
                <icon-copy />
              </icon-base>
            </FormButton>
            <a target="_blank"
              class="absolute right-0 top-8 w-7 h-7 flex items-center justify-center text-surface-8 hover:text-white"
              :href="getPlayerUrl(video)">
              <icon-base fill="none">
                <icon-link-external />
              </icon-base>
            </a>
            <label>Embed Url</label>
            <textarea rows="2" class="mb-3 pr-5 textarea_url w-full bg-surface-3 rounded-md p-2" @click="clipboardCopy(getPlayerUrl(video))"
              :value="getPlayerUrl(video)"></textarea>
          </div>
          <div class="field-container">
            <FormButton class="absolute right-2 top-8" isLink isIcon type="link" size="sm"
              @click="clipboardCopy(getEpisodeIframeSnippet(video))">
              <icon-base class="" fill="none">
                <icon-copy />
              </icon-base>
            </FormButton>
            <label>
              Iframe Snippet
            </label>
            <textarea class="pr-5 textarea_snippet w-full bg-surface-3 rounded-md p-2" rows="3"
              @click="clipboardCopy(getEpisodeIframeSnippet(video))" :value="getEpisodeIframeSnippet(video)">
            </textarea>
          </div>
          <div v-if="hasHlsLink" class="field-container mt-3">
            <FormButton class="absolute right-2 top-8" isLink isIcon type="link" size="sm"
              @click="clipboardCopy(getHlsLink())">
              <icon-base class="" fill="none">
                <icon-copy />
              </icon-base>
            </FormButton>
            <label>
              HLS Manifest
            </label>
            <textarea rows="2" class="pr-5 textarea_snippet w-full bg-surface-3 rounded-md p-2" @click="clipboardCopy(getHlsLink())"
              :value="getHlsLink()"></textarea>
          </div>
        </template>
      </e-accordionitems>
    </ejs-accordion>
    <add-pre-record-stream-modal v-if="selectedVideo" :video="this.selectedVideo" :stream="this.stream"
      @modal-dismiss="onCreatePrerecordDismiss" @modal-confirm="onCreatePrerecordConfirm" />
  </div>
</template>

<script>
import _ from 'lodash';
import StreamService from "@/services/StreamService";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import AddPreRecordStreamModal from "@/components/modals/AddPreRecordStreamModal.vue";
import UserService from "@/services/UserService";
import ClipLoader from "@/components/ui/ClipLoader.vue";
import IconBase from '@/components/icon/IconBase.vue'
import IconBucket from '@/components/icon/IconBucket.vue'
import prettyMilliseconds from "pretty-ms";
import { mapGetters } from 'vuex'
import { date } from '@/filtersNew'
import IconPlay from '../icon/IconPlay.vue';
import IconDownload from '../icon/IconDownload.vue';
import FormButton from '../Atoms/FormButton.vue';
import IconGear from '../icon/IconGear.vue';
import IconBin from '../icon/IconBin.vue';
import IconCopy from '../icon/IconCopy.vue';
import IconLinkExternal from '../icon/IconLinkExternal.vue';
import { TooltipComponent } from "@syncfusion/ej2-vue-popups"
import {
  AccordionComponent,
  AccordionItemsDirective,
  AccordionItemDirective
} from "@syncfusion/ej2-vue-navigations";
import Badge from '../Badge.vue';
import Title from './Title.vue';
import IconElipsis from '../icon/IconElipsis.vue';
import IconCameraPlus from '../icon/IconCameraPlus.vue';
import IconElipsisVertical from '../icon/IconElipsisVertical.vue';
import DropDownMenu from '../Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../Atoms/DropDownMenuItem.vue';
import IconInfo from '../icon/IconInfo.vue';
import IconChevronRight from '@/components/icon/IconChevronRight.vue';
import IconSelectRange from '@/components/icon/IconSelectRange.vue';

export default {
  name: "DVRVideoListItem",
  components: {
    AddPreRecordStreamModal,
    ConfirmModal,
    ClipLoader,
    IconBase,
    IconBucket,
    IconPlay,
    IconDownload,
    IconGear,
    IconBin,
    IconInfo,
    IconCameraPlus,
    IconCopy,
    IconElipsis,
    IconElipsisVertical,
    IconLinkExternal,
    FormButton,
    DropDownMenu,
    DropDownMenuItem,
    "ejs-tooltip": TooltipComponent,
    "ejs-accordion": AccordionComponent,
    "e-accordionitems": AccordionItemsDirective,
    "e-accordionitem": AccordionItemDirective,
    Badge,
    Title,
    IconChevronRight,
    IconSelectRange
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    stream: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedVideo: null,
      archiveName: "",
      lockProcessing: false,
      statusTimerId: null,
      isLive: this.video.live,
      saveVodOptShowed: false,
      embedContentActiveTab: "full",
      expiredSub: false,
      allowOverdueVodDownload: true,
      streamDvrEnabled: null,
      disabledTooltipMessage: 'Upgrade to use the download feature. Check details on <a class="text-reset" target="_blank" href="https://castr.com/pricing/">Pricing page</a>'
    };
  },
  computed: {
    activeTeam() {
      return this.$store.state.User.activatedTeam;
    },
    hasHlsLink() {
      //allow for singlemusic
      const userList = ["5eb70a3188bbc76c25c73991", "5b2e1c65f1e043163435d4e9"];
      return userList.includes(UserService.getUserId(this.activeTeam));
    },
    planUnlimVodDownload() {
      const sub = this.$store.state.User.addonSub.find(({ category }) => category === 'live')
      if (!sub) {
        return false
      }

      return _.get(sub, ['package', 'definition', 'unlimitedVodDownload'], false)
    },
    ...mapGetters({
      scheduledStreams: 'Streams/scheduledStreams',
      maxScheduledStreams: 'User/maxScheduledStreams',
      isOwner: 'User/isOwner',
      isSubAccount: 'User/isSubAccount',
      parentAccount: 'User/parentAccount',
      userPermissions: 'User/userPermissions',
      teamOwner: 'User/teamOwner'
    }),
    disableCreatePreRecorderedStream() {
      if (this.userPermissions && !this.userPermissions.preRecordedStreamManage && !this.isOwner) {
        return true
      } else return false
    },
    tooltipText() {
      return `
				<span class="text-dark-8 text-small">
					You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
					:href="'mailto:${this.userEmail}'">${this.userEmail}</a> to gain access.
				</span>`
    }
  },
  watch: {
    "video.live": function (newVal, oldVal) {
      this.isLive = newVal;
    },
  },
  async mounted() {
    this.archiveName = this.video.name ? this.video.name : this.video.fileName;
    const user = UserService.getUser(this.activeTeam)
    let unlimVodDownload = false
    this.streamDvrEnabled = this.stream.metadata.dvrArchive;

    // allow if plan has feature or user account allowed
    if (this.planUnlimVodDownload || _.isBoolean(user.unlimitedVodDownload) && user.unlimitedVodDownload) {
      unlimVodDownload = true
    }

    // explicitly block for user account
    if (_.isBoolean(user.unlimitedVodDownload) && !user.unlimitedVodDownload) {
      unlimVodDownload = false
    }

    if (Math.round(new Date().getTime() / 1000) - this.video.from > 86400 * 3 && !unlimVodDownload && this.video.exportStatus === 'EXPORTED') {
      // this.video.downloadUrl = null;
      this.allowOverdueVodDownload = false;
    }

    const subscriptions = this.$store.state.User.subscriptions;
    const subDetail = _.filter(subscriptions, (sub) => sub.category === this.stream.type && sub.enabled === true)
    if (subDetail.length === 0 || subDetail[0].enabled === false) {
      this.expiredSub = true;
      this.disabledTooltipMessage = 'Please renew your subscription plan to use this feature.'
    }
  },
  methods: {
    switchEmbedContentTab(tab) {
      this.embedContentActiveTab = tab;
    },
    toggleEmbedLink() {
      this.video.expanded = !this.video.expanded;
    },
    downloadDvrArchive() {
      this.video.downloadUrl = this.video.downloadUrl.replace(
        ".castr.io",
        ".global.ssl.fastly.net"
      );
      window.location.href = this.video.downloadUrl + '?packing=compat';
    },
    playPreview(audioTrack, audioOnly) {
      if (typeof audioTrack === 'string') {
        this.video.audioTrack = { name: audioTrack, audioOnly: audioOnly }
      } else delete this.video.audioTrack
      this.$emit("reqeust-video-preview", this.video);
    },
    requestDeleteDvrArchive() {
      this.$emit("request-dvr-archive-delete", this.video);
    },
    async onNameChange(newName) {
      if (this.video.fileName === newName) {
        return;
      }

      try {
        const q = {
          streamId: this.stream._id,
          from: this.video.from,
          duration: this.video.duration,
          name: newName,
        };

        await StreamService.setStreamDvrArchiveName(q);
        this.video.name = newName;
        this.$notify({
          group: "success",
          // title: "Couldn't change archive name",
          text: `The ${newName} name has been updated`,
        });
      } catch (err) {
        this.$notify({
          group: "error",
          title: "Couldn't change archive name",
          text: err.message,
        });
      }
    },
    exportDvrArchive2() {
      this.video.again = true;
      this.$emit("request-dvr-archive-export", this.video);
    },
    exportDvrArchive() {
      this.$emit("request-dvr-archive-export", this.video);
    },
    clipboardCopy(text) {
      try {
        if (text instanceof Function) text = text();

        this.$copyText(text);
        this.$notify({ group: "info", text: "Copied to clipboard" });
      } catch (e) { }
    },
    getPlayerUrl() {
      if (this.stream.type === "live") {
        const abr = this.video.abr ? "true" : "false";
        const audioOnly = this.embedContentActiveTab === "audio" ? true : false;
        return (
          `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${this.stream.key}?range=${this.video.from}-${this.video.duration}&abr=${abr}`
          + (this.video.namedHls ? '&namedHls=true' : '')
          + (audioOnly ? "&onlyAudio=true" : "")
        );
      }
    },
    getHlsLink() {
      const host = "singlemusic-archives.akamaized.net";
      const url = `https://${host}/episodes/edge-en/${this.stream.key}/archive-${this.video.from}-${this.video.duration}.mp4/index.m3u8`;
      return url;
    },
    getEpisodeIframeSnippet() {
      const iframeSrc = this.getPlayerUrl(this.video);
      return `<iframe src="${iframeSrc}" frameborder="0" width="590" height="430" allow="autoplay"  scrolling="no"  allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`;
    },
    showVodSaveOpt() {
      this.saveVodOptShowed = !this.saveVodOptShowed;
    },
    onVodSaveWhole() {
      this.saveVodOptShowed = false;
      this.exportDvrArchive();
    },
    onVodSaveCustom(audioTrack) {
      this.saveVodOptShowed = false;
      this.playPreview(audioTrack);
    },
    onVodAudioSaveCustom(audioTrack) {
      this.saveVodOptShowed = false;
      this.playPreview(audioTrack, true);
    },
    onCreatePrerecord(video) {
      this.selectedVideo = video;
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'modal-add-pre-record' + video.name);
      }, 100);

    },
    onCreatePrerecordDismiss() {
      this.selectedVideo = null
    },
    onCreatePrerecordConfirm() {
      this.selectedVideo = null
    },
    humanTime(t) {
      return prettyMilliseconds(t * 1000, { unitCount: 2 });
    },
    dateFormated(item) {
      return date(item)
    },
    toggleMetadata(video) {
			const metadata = this.$refs['metaDataCollapse' + video.from + video.duration + video.episodeId].ej2Instances
			metadata.items[0].properties.expanded = !metadata.items[0].properties.expanded
			metadata.expandItem(metadata.items[0].properties.expanded);
		},
  },
  beforeDestroy() {
    clearInterval(this.video.exportStatusTimer);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-item {
  font-size: var(--font-s-s);
}

.field-container {
  position: relative;
}

.btn-icon_a {
  position: absolute;
  right: 0;
  top: 30px;
}

.btn-icon_b {
  position: absolute;
  right: 25px;
  top: 30px;
}

.video-item .subtitle_input {
  font-weight: 400;
  font-size: var(--font-s-m);
  color: var(--c-dark-9);
}

.textarea_snippet,
.textarea_url {
  resize: none;
}

@media (max-width: 767px) {
  .video-item>.row {
    margin-right: -5px;
    margin-left: -5px;
  }

  :deep(.video-item .row>div) {
    padding-right: 5px;
    padding-left: 5px;
  }

  .spinner-mobile {
    position: absolute;
    top: -5px;
    left: -12px;
  }
}
</style>

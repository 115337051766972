<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'main',
  },
  title: {
    type: String,
  },
  tag: {
    type: Boolean,
    default: false
  }
})

const type = computed(() => {
  switch (props.type) {
    case 'main':
      return 'text-surface-9 bg-surface-4';
    case 'online': return 'text-white bg-success';
    case 'offline': return 'text-white bg-offline';
    case 'orange': return 'text-white bg-orange'
    case 'error': return 'text-white bg-error'
    case 'blue': return 'text-white bg-main'
    default: return props.tag ? '!rounded-full badge-' + props.type : 'text-surface-9 bg-surface-4'
  }
})
</script>

<template>
  <span class="flex text-xs font-medium leading-none rounded px-2 py-1" :class="type">
    {{ title }}
    <slot />
  </span>
</template>

<style>

</style>

<template>
	<div>
		<div class="castr-card__body p-0"  @click="navigateManage">
			<div class="info mr-auto">
				<div class="d-flex flex-column justify-content-start align-items-center w-100">
					<div class="card-image">
						<div v-if="this.stream">
						<stream-thumb :stream="this.stream" :media-pulse="mediaPulse" :compact-mode="false" class="video-thumb w-100" />
						</div>
					</div>
					<div class="card-content position-relative">
						<a v-if="!showStreamError" class="card_link position-absolute" target="_blank" :href="this.getLink()" v-on:click.stop><img src="@/assets/icons/card_click.svg" alt="Castr"></a>
				<h3 class="text-s-m text-500 mb-1">
							{{  toShortString(streamhighlight && streamhighlight.name, 25) }}
						</h3>
						<div v-if="!showStreamError" class="d-flex align-items-center justify-content-start mb-2">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5758 3.42426C11.4294 2.27786 9.57076 2.27787 8.42436 3.42426L7.92436 3.92426C7.69005 4.15858 7.31015 4.15858 7.07583 3.92426C6.84152 3.68995 6.84152 3.31005 7.07583 3.07573L7.57583 2.57574C9.19086 0.960709 11.8093 0.960705 13.4244 2.57573C15.0394 4.19076 15.0394 6.80923 13.4244 8.42426L12.4244 9.42426C10.8093 11.0393 8.19086 11.0393 6.57583 9.42426C6.34152 9.18995 6.34152 8.81005 6.57583 8.57573C6.81015 8.34142 7.19005 8.34142 7.42436 8.57573C8.57076 9.72213 10.4294 9.72213 11.5758 8.57573L12.5758 7.57573C13.7222 6.42934 13.7222 4.57066 12.5758 3.42426ZM8.57583 7.42426C7.42944 6.27786 5.57076 6.27786 4.42436 7.42426L3.42436 8.42426C2.27796 9.57066 2.27796 11.4293 3.42436 12.5757C4.57076 13.7221 6.42944 13.7221 7.57584 12.5757L8.07583 12.0757C8.31015 11.8414 8.69005 11.8414 8.92436 12.0757C9.15868 12.31 9.15868 12.6899 8.92436 12.9243L8.42436 13.4243C6.80934 15.0393 4.19086 15.0393 2.57583 13.4243C0.960806 11.8092 0.960807 9.19076 2.57583 7.57573L3.57583 6.57574C5.19086 4.96071 7.80933 4.9607 9.42436 6.57573C9.65868 6.81005 9.65868 7.18995 9.42436 7.42426C9.19005 7.65858 8.81015 7.65858 8.57583 7.42426Z" fill="#AEBAD6"/>
							</svg>
							<p class="mb-0 tex-dark-8 text-s-ss text-normal">{{this.stream && this.stream.name}}</p>
						</div>
						<div v-else class="d-flex">
							<div>
								<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5758 3.42426C11.4294 2.27786 9.57076 2.27787 8.42436 3.42426L7.92436 3.92426C7.69005 4.15858 7.31015 4.15858 7.07583 3.92426C6.84152 3.68995 6.84152 3.31005 7.07583 3.07573L7.57583 2.57574C9.19086 0.960709 11.8093 0.960705 13.4244 2.57573C15.0394 4.19076 15.0394 6.80923 13.4244 8.42426L12.4244 9.42426C10.8093 11.0393 8.19086 11.0393 6.57583 9.42426C6.34152 9.18995 6.34152 8.81005 6.57583 8.57573C6.81015 8.34142 7.19005 8.34142 7.42436 8.57573C8.57076 9.72213 10.4294 9.72213 11.5758 8.57573L12.5758 7.57573C13.7222 6.42934 13.7222 4.57066 12.5758 3.42426ZM8.57583 7.42426C7.42944 6.27786 5.57076 6.27786 4.42436 7.42426L3.42436 8.42426C2.27796 9.57066 2.27796 11.4293 3.42436 12.5757C4.57076 13.7221 6.42944 13.7221 7.57584 12.5757L8.07583 12.0757C8.31015 11.8414 8.69005 11.8414 8.92436 12.0757C9.15868 12.31 9.15868 12.6899 8.92436 12.9243L8.42436 13.4243C6.80934 15.0393 4.19086 15.0393 2.57583 13.4243C0.960806 11.8092 0.960807 9.19076 2.57583 7.57573L3.57583 6.57574C5.19086 4.96071 7.80933 4.9607 9.42436 6.57573C9.65868 6.81005 9.65868 7.18995 9.42436 7.42426C9.19005 7.65858 8.81015 7.65858 8.57583 7.42426Z" fill="#AEBAD6"/>
								</svg>
							</div>
							<div class="mb-0 text-danger text-s-ss text-normal">Linked livestream removed. Delete and create new Live Clipping.</div>
						</div>
						<div class="d-flex align-items-center justify-content-between mb-0 mt-3">
							<!-- <p class="text-s-ss text-dark-7 d-inline-block posted_highlight text-400 mb-0"></p> -->
							<b-button  variant="outline-danger" size="sm" class="py-0 px-0 delete_highlight"
								@click="requestDelete($event)">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<ellipse cx="8" cy="3.5" rx="5" ry="1.5" stroke="#F46464" stroke-width="1.5"/>
								<path d="M13 3.5L11.75 13C11.4 14 10 14.5 8 14.5C6 14.5 4.65 14 4.25 13L3 3.5" stroke="#F46464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import StreamThumb from '@/components/ui/StreamThumb.vue';
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue';
import HighlightService from '@/services/HighlightService'
import { shortString } from "@/filtersNew"

export default {
	name: 'HighlightCardView',
	components: {
		StreamThumb,
		ToggleStreamModal,
		// StreamPlayerHls
	},
	props: {
		streamhighlight: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
        
	},
	data() {
		return {
			scopeAlive: true,
			randId: Math.random()
				.toString()
				.slice(2),
			statusProcessing: false,
			streamStatus: false,
			mediaPulse: null,
			mediaPulseTimeoutCtrl: null,
            stream:null,
			streamCountdownSecLeft: null,
			startDateMonth: null,
			startDateDay: null,
			notificationEnabled: null,
			posted: 0,
			showStreamError: false,
		};
	},
	computed: {
		userEmail() {
			return this.$store.state.User.email;
		},
		countryFlagIcon() {
			let identifier = _.get(this.stream, ['uiRegion', 'identifier'], this.stream.region.identifier)
			// hack for studio@jackshoot.com
			if (this.stream.user === '5da088ba2564bd064957e474' && this.stream.uiRegion && this.stream.uiRegion._id === '62e2a0e108cb9dc2bcfb17d8') {
				identifier = 'kr'
			}

			// hack for kevin@stream.works
			if (this.stream.user === '5ba39c5e688503562c8097b4' && this.stream.uiRegion && this.stream.uiRegion._id === '63f3624d8ae252ca28ed79d2') {
				identifier = 'ae'
			}

			return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
		},
		streamAlive() {
			return this.streamStatus && this.mediaPulse && this.mediaPulse.alive;
		},
		getBytes() {
			if (this.stream.totalBytes && this.stream.totalBytes > 0) {
				let totalBytes = this.stream.totalBytes;
				var i = Math.floor(Math.log(totalBytes) / Math.log(1000)),
					sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
				return (totalBytes / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + sizes[i];
			} else {
				return 0;
			}
		},
		toggleCheck() {
			if (this.chosenStreams) {
				const result = this.chosenStreams.find(({ _id }) => _id === this.stream._id);
				if (result && !result.added) {
					return true
				}
				return false
			}

		},
		userloaded() {
			return this.$store.state.User.userloaded;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
	},
	watch: {
		// memberPermission(){
		// 	this.permissionProcessing = false
		// }
	},
	async mounted() {
        if(this.streamhighlight){
			this.stream = this.streamhighlight?.streamobj[0];
			if(!this.stream) {
				this.showStreamError = true;
			} else {
				this.streamStatus = this.stream.enabled;
				const gethighlight = await HighlightService.getHighlightbyid(this.streamhighlight._id);
				if(gethighlight.clips.length > 0){
					let clips = gethighlight.clips.filter(c=>{
						return c.status == 'completed'
					});
					this.posted = clips.length;
				}
			}
       }
       this.subscribeMediaPulse();
	},
	destroyed() {
		this.scopeAlive = false;
		this.unsubscribeMediaPulse();
	},
	methods: {
		checkDelPermission() {
			let liveStreamDelete = (this.memberPermission && (this.stream.type === 'live') && this.memberPermission.liveStreamDelete);
			let reStreamDelete = (this.memberPermission && (this.stream.type === 'restream') && this.memberPermission.recordedStreamDelete);
			let preRecordedStreamDelete = (this.memberPermission && (this.stream.type === 'scheduled') && this.memberPermission.preRecordedStreamDelete);
			let permission = liveStreamDelete || reStreamDelete || preRecordedStreamDelete;
			return permission;
		},
		checkTogglePermission() {
			let liveStreamToggle = (this.memberPermission && (this.stream.type === 'live') && this.memberPermission.liveStreamToggle);
			let reStreamToggle = (this.memberPermission && (this.stream.type === 'restream') && this.memberPermission.recordedStreamToggle);
			let preRecordedStreamToggle = (this.memberPermission && (this.stream.type === 'scheduled') && this.memberPermission.preRecordedStreamToggle);
			let permission = liveStreamToggle || reStreamToggle || preRecordedStreamToggle;
			return permission;
		},
		checkManagePermission() {
			let liveStreamManage = (this.memberPermission && (this.stream.type === 'live') && this.memberPermission.liveStreamManage);
			let reStreamManage = (this.memberPermission && (this.stream.type === 'restream') && this.memberPermission.recordedStreamManage);
			let preRecordedStreamManage = (this.memberPermission && (this.stream.type === 'scheduled') && this.memberPermission.preRecordedStreamManage);
			let permission = liveStreamManage || reStreamManage || preRecordedStreamManage;
			return permission;
		},
		removeDuplicates(array) {
			const uniqueArray = array.filter((thing, index) => {
				const _thing = JSON.stringify(thing.template);
				return index === array.findIndex(obj => {
					return JSON.stringify(obj.template) === _thing;
				});
			});
			return uniqueArray;
		},
		getIcon(icon, template) {
			if (template == 'youtube events') {
				icon = 'youtube.svg';
			}
			icon = icon.replace('png', 'svg');
			try {
				return require('@/assets/images/platforms/colored/' + icon);
			} catch (err) {
				return require('@/assets/images/platforms/colored/custom.svg');
			}
		},
		countOccurrences(arr, key, val) {
			var count = arr.filter(function (element) {
				return element[key] == val;
			});
			return count.length
		},
		onStreamPlaying(ev, streamKey, playerInstace) {
			setTimeout(() => {
				if (playerInstace) playerInstace.pause();
			}, 1000);
		},
		subscribeMediaPulse() {
			const streamKey = this.stream.key;
			const self = this;
			(async function loop() {
				if (!self.scopeAlive) return;

				let timeoutInterval = 5000;
				let shouldUpdatePulse = self.stream.enabled;
				if (shouldUpdatePulse && document.hidden) {
					shouldUpdatePulse = false;
					timeoutInterval = 6000;
				}

				if (shouldUpdatePulse) {
					if (!document.hasFocus()) {
						timeoutInterval = 14000;
					}
				}

				if (shouldUpdatePulse) {
					try {
						const pulseObject = await StreamStatsService.getStreamMediaPulse(
							streamKey
						);
						self.onMediaPulse(pulseObject);
					} catch (e) { }
				}

				// reschedule
				self.mediaPulseTimeoutCtrl = setTimeout(
					loop.bind(this),
					timeoutInterval
				);
			}());
		},
		onMediaPulse(pulse = {}) {
			this.mediaPulse = pulse;
			this.$emit('pulse-update', this.stream._id, pulse);
			// if(pulse.alive){
			// 	this.$emit('has-livestream', true);
			// }
		},
		unsubscribeMediaPulse() {
			clearTimeout(this.mediaPulseTimeoutCtrl);
		},
		requestDelete(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			this.$emit('delete-highlight', this.streamhighlight);
		},
		getLink(){
			if(this.showStreamError) return;

			let viewName = 'HighlightSingleManage';
			if (this.deleteBlock != true && this.lightMode != true && !this.previewMode) {
			const routeObject = {
					name: viewName,
					params: { highlightId: this.streamhighlight._id  }
				};
				const resolvedRoute = this.$router.resolve(routeObject);
				const link = resolvedRoute.href;
				return link;
			}
		},
		navigateManage() {
			if(this.showStreamError) return;
					let viewName = 'HighlightSingleManage';
					if (this.deleteBlock != true && this.lightMode != true && !this.previewMode) {
						this.$router.push({
							name: viewName,
							params: { highlightId: this.streamhighlight._id  }
						});
					}
		},
		showStreamToggleModal(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			if(this.notAllowed && !this.stream.enabled){
				this.$root.$emit('bv::show::modal', 'increase-limit');
			}
			if ((!this.notAllowed || (this.notAllowed && this.stream.enabled)) && (!this.activeTeam || this.checkTogglePermission())) {
				if(this.stream.type == 'restream'){
					this.$emit('check-multistream', this.stream);
				}else{
					this.$root.$emit('bv::show::modal', 'modalToggleStream' + this.stream._id, '#btnShow')
				}
			}
		},
		async toggleStatus(ev, newStatus) {
			// ev.preventDefault();
			// ev.stopPropagation();
			this.$emit('toggle-stream', this.stream);
		},
		onStreamCountdownTick(last, isFinished) {
			if (isFinished) {
				this.streamCountdownSecLeft = null;
			}
		},
		allPlatforms(platforms) {
			return platforms.length
		},
		linkedPlatforms(platforms) {
			return platforms.filter(item => item.enabled).length
		},
		toShortString(value, limit) {
			return shortString(value, limit)
		}
	},

};
</script>

<style scoped>
.sa-platform-info {
	background: var(--c-dark-1);
	border-radius: 6px;
	height: 32px;
	align-items: center;
}

.sa-platform-icon.mr-2>img {
	height: 22px;
}

.platform_details_wrapper {
	min-height: 32.7px;
}

.castr-card {
	background-color: var(--c-dark-2);
	border-radius: 8px;
	position: relative;
	margin-bottom: 30px;
}

.castr-card.compact {
	background-color: transparent;
	display: flex;
}

.thumb {
	height: 212px;
	background-color: #000;
	border-radius: 8px 8px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	max-height: 100%;
	max-width: 100%;
}

.compact .thumb {
	height: 48px;
	width: 80px;
	flex: 0 0 80px;
	border-radius: 8px;
	overflow: hidden;
}

:deep(.thumb .stream-preview) {
	max-height: 212px;
	height: 212px;
	width: 100%;
	max-width: 100%;
	object-fit: cover;
	border-radius: 8px 8px 0 0;
	cursor: pointer;
}

:deep(.thumb video.stream-preview) {
	z-index: 2;
}

:deep(.compact.thumb .stream-preview.ready) {
	border-radius: 8px;
}

:deep(.thumb>.video-thumb.compact .stream-preview) {
	max-height: 56px;
}

.badge {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 2;
}
.badge-platforms {
	border-radius: 6px;
	line-height: 1.4;
	cursor: pointer;
}
.badge-success {
	z-index: 90;
}

.badge_bitrate {
	font-size: var(--font-s-m);
}

.badge_bitrate>span {
	margin-right: .10rem;
}

.compact .badge {
	top: 30%;
	left: 80%;
}

.castr-card__body {
	padding: 16px;
}

.castr-card__region {
	font-size: var(--font-s-m);
	color: var(--c-dark-9);
}

.compact .castr-card__region {
	font-size: var(--font-s-s);
}

.compact .castr-card__body {
	flex: 1 1 calc(100% - 80px);
	display: flex;
	padding: 5px 15px;
	align-items: center;
	justify-content: space-between;
}

.meta-info,
.info {
	cursor: pointer;
}

.compact .meta-info {
	order: 2;
}

.compact .castr-card__body p {
	margin-bottom: 0;
}

.compact .actions .btn_delete {
	order: 2;
	margin-left: .5rem;
}

.compact .actions .btn_enable {
	order: 1;
}

.compact .castr-card__body {
	padding: 0 0 0 10px;
	position: relative;
}

.grid-item:not(:last-child) .compact .castr-card__body::after {
	content: '';
	width: 100%;
	border-top: 1px solid var(--c-dark-2);
	position: absolute;
	bottom: -16px;
}

.badge-status {
	height: 24px;
	min-width: 22px;
	padding: 0 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.badge-bitrate {
	position: static;
}

.success_puls {
	left: 29px;
}

.card-title {
	margin-bottom: .25rem;
}

.compact .card-title {
	font-size: var(--font-s-l);
	margin-bottom: 0;
}

.vod-item {
	position: relative;
}

.vod-item:after {
	content: '';
	position: absolute;
	width: 100%;
	border-top: 1px solid var(--c-dark-2);
	bottom: 0;
	width: calc(100% - 30px);
}

.castr-vod-wrap .vod-lists:not(.vod-label) .vod-item:hover:after {
	display: none;
}

.compact span.badge.badge_bitrate {
	position: absolute;
	right: 100%;
	left: unset;
	top: 0px;
	transform: translateX(-25%);
	font-weight: 400;
	color: var(--c-dark-7);
}

.info.info-scheduled {
	display: flex;
	justify-content: space-between;
}

.info.info-scheduled .badge-bitrate {
	padding-right: 0px;
}

.meta-info .badge_bitrate:after {
	content: "-";
	position: absolute;
	top: 4px;
	left: 35px;
}

.compact .meta-info .badge_bitrate:after {
	display: none;
}

.compact .badge-dark-3,
.compact .badge-success {
	margin-left: auto;
	margin-right: .5rem;
}

.compact.lightMode .badge-dark-3,
.compact.lightMode .badge-success {
	margin-left: 0;
	margin-right: .5rem;
}

.actions_dropdown {
	display: none;
}

.lightMode {
	border-radius: 0;
	border-bottom: 1px solid var(--c-dark-3);
}

.lightMode:hover {
	border-radius: 8px;
	background-color: var(--c-dark-3);
}

.lightMode .castr-card__body {
	padding: 10px;
}

.lightMode.checked {
	border-radius: 8px;
	background-color: var(--c-main-1);
}

.sub-streams .info,
:deep(.sub-streams .thumb .stream-preview) {
	cursor: unset;
}

.castr-card__day {
	background-color: var(--c-dark-3);
	padding: 2px 8px;
	border-radius: 4px;
	margin-right: 10px;
}
.cursor-hand:hover {
	cursor: pointer
}
.card-image {
    height: 200px;
    background-color: #000;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    background: #000;
    width: 100%;
	overflow: hidden;
}
.card-content{
	border-radius: 0px 0px 8px 8px;
}

.card-content {
    width: 100%;
    padding: 20px;
    text-align: left;
    background-color: var(--c-dark-2);
}
.posted_highlight{
	border-radius: 6px;
background: var(--grayscale-01, #141E3D);
padding: 4px 8px;
}
@media (min-width: 768px) {
	.btn_delete {
		opacity: 0;
		transition: .3s;
		margin-left: auto;
		margin-right: .5rem;
	}
	.compact .btn_delete {
		opacity: 1;
		margin-left: .25rem;
	}
	.castr-card:hover .btn_delete {
		opacity: 1;
		transition: .3s;
	}
}
@media only screen and (max-width: 767px) {
	.btn_delete {
		margin-left: auto;
		margin-right: .5rem;
	}
	.compact .actions_dropdown {
		display: block;
	}

	.compact .castr-card__body {
		flex-wrap: wrap;
	}

	.compact span.badge.badge_bitrate {
		position: static;
	}

	.compact .badge {
		top: 0%;
		left: unset;
		right: 0;
	}

	.grid-item:not(:last-child) .castr-card.compact::after {
		content: '';
		width: 100%;
		border-top: 1px solid var(--c-dark-2);
		position: absolute;
		bottom: -18px;
	}

	.grid-item:not(:last-child) .compact .castr-card__body::after {
		display: none;
	}

	.compact .thumb {
		height: 56px;
		width: 88px;
		flex: 0 0 88px;
	}

	:deep(.compact .badge-dark-3) {
		background: none;
		color: var(--c-dark-6);
		margin-left: -6px;
	}

	:deep(.compact .badge-success) {
		background: none;
		color: var(--c-success-1);
		margin-left: -6px;
	}

	.compact .actions_buttons {
		display: none;
	}

	.compact .actions_dropdown {
		margin-top: -3rem;
	}

	.compact .info {
		flex: 0 0 100%;
	}

	.compact.lightMode .info {
		flex: 0 0 68%;
	}

	.castr-card {
		margin-bottom: 10px;
	}

	.grid-item:not(:last-child) .castr-card.compact::after {
		bottom: -5px;
	}
}

</style>

<script setup>
import _ from 'lodash'
import FormButton from '../../components/Atoms/FormButton.vue'
import FormSwitch from '../../components/Atoms/FormSwitch.vue'
import IconBase from '../../components/icon/IconBase.vue'
import IconChevronDown from '../../components/icon/IconChevronDown.vue';
import SubscribePlanItem from './SubscribePlanItem.vue';
import SubscribePlansCustomWidget from './SubscribePlansCustomWidget.vue';

const props = defineProps({
  subscriptionPackages: {
    type: Array,
  },
  plansData: {
    type: Array,
  },
})

const yearly = defineModel('yearly')

</script>

<template>
  <div class="relative">
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:grid-rows-[auto_1fr_auto] rounded-xl w-full lg:w-10/12 mx-auto divide-x divide-surface-9">
      <div
        class="lg:col-span-5 flex items-center justify-center gap-x-3 bg-surface-3 !p-4 rounded-t-xl text-tiny sticky lg:static top-16 ring-1 ring-surface-3">
        <span>Monthly</span>
        <FormSwitch v-model="yearly" />
        <span><span>Yearly</span> <span class="text-orange">- Save upto 37% + upfront bandwidth + more
            streams</span></span>
      </div>
      <SubscribePlanItem v-for="(plan, index) in plansData" :key="index" :plan="plan" :is-yearly="yearly" />
      <div class="lg:col-span-5 flex items-center justify-center bg-white rounded-b-xl py-2 border-t border-surface-9">
        <FormButton target="_self" href="app/subscribe#bottom"
          customClasses="text-surface-1 text-tiny flex items-center gap-x-2">
          Compare Plans
          <IconBase>
            <IconChevronDown />
          </IconBase>
        </FormButton>
      </div>
    </div>
    <SubscribePlansCustomWidget class="absolute left-[87%] top-20 !hidden lg:!block" />
  </div>
</template>

<style></style>

<script setup>
import { computed, inject } from 'vue';
import SubscriptionService from '@/services/SubscriptionService'
import { useStore } from 'vuex'
import _ from 'lodash'

import FormButton from '../../components/Atoms/FormButton.vue'

import Badge from '../../components/Badge.vue';

const props = defineProps({
  plan: {
    type: Object,
  },
  isYearly: {
    type: Boolean
  },
  isCart: {
    type: Boolean
  }
})

const store = useStore()

const { updateSelectedPaymentMethod, selectPackage } = inject('selectedPaymentMethod')

const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])

const price = computed(() => props.isYearly ? props.plan.info?.yearly : props.plan.baseCharge)

const isCurrentSubscription = (plan) => {
  const mainSubscription = addonSubscriptions.value.find( subscription => subscription.category === 'live' && subscription.enabled)
  return mainSubscription?.package?._id === plan?._id
}

const selectSubscriptionPackage = async (pack) => {
  selectPackage(pack)
  const checkpaymentMethods = await SubscriptionService.getUserPaymentMethods()
  updateSelectedPaymentMethod(checkpaymentMethods[0])
}
</script>

<template>
  <header class="bg-light-1 !px-3 !py-4 border-b border-surface-9 h-[220px] flex flex-col" :class="{'rounded-t-md': isCart}">
    <Badge v-if="props.plan?.info?.popular" type="blue" class="absolute !text-[10px] font-medium" title="POPULAR" />
    <h2 class="text-[15px] font-medium text-surface-1 mt-8">{{ props.plan?.info?.name ? props.plan?.info?.name : props.plan?.name }}</h2>
    <p class="flex items-end my-2">
      <span class="text-xl text-surface-1 font-medium leading-4">
        ${{ price ? price : props.plan?.baseCharge }}
      </span>
      <span class="text-surface-5 text-sm leading-4">
        /month
      </span>
    </p>
    <template v-if=props.isYearly>
      <p class="text-tiny text-surface-5">Billed as ${{ props.plan.baseCharge }} yearly</p>
      <p class="text-tiny text-yellow">Saved ${{ props.plan.info?.saved }}</p>
    </template>
    <FormButton v-if="!isCart" :disabled="isCurrentSubscription(props.plan)" full-width class="justify-center mt-auto" :label="isCurrentSubscription(props.plan) ? 'Active plan' : 'Subscribe Now'" @click="selectSubscriptionPackage(props.plan)" />
  </header>
</template>

<style></style>

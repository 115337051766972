<template>
  <div
    class="view-wrapper d-flex aligh-items-center justify-content-center"
    :style="{ 'min-height': minWindowHeight || 'auto' }"
  >
    <div class="form">
      <div class="text-center">
        <div class="castr-logo-wrapper">
          <div class="logo-icon-dot dot-lg"></div>
          <h3 class="title">
            <!-- <a href="/">
              <img
                class="logo-icon"
                src="https://castr.com/wp-content/uploads/2023/12/Christmas_logo_2023.png"
                alt="castr logo"
              /> -->
            <!-- </a> -->
            <a href="/">
              <img
                class="logo-icon"
                src="@/assets/castr.svg"
                alt="castr logo"
              />
            </a>
          </h3>
        </div>
        <p style="font-size: 15px">Single Ingest, Unlimited Platforms</p>
      </div>
      <br />
      <!-- error placeholder -->
      <b-alert
        v-if="error"
        show
        variant="danger"
        class="left inline"
        style="margin: 15px 0"
        >{{ error.message }}</b-alert
      >

      <div class="field-container">
        <label>
          Username / Email
        </label>
        <b-input
          v-model="form.user"
          type="email"
          size="md"
          placeholder="username or email"
          @keypress="onInputChange('user')"
          @keypress.enter="tryLogin"
        />

        <p v-show="formErrors.user" class="text-danger">identify yourself</p>
      </div>

      <div class="field-container" style="margin-bottom: 10px">
        <label>Password</label>
        <b-input
          v-model="form.password"
          type="password"
          size="md"
          placeholder="Enter password"
          @keypress="onInputChange('password')"
          @keypress.enter="tryLogin"
        />

        <p v-show="formErrors.password" class="text-danger">specify password</p>
      </div>
      <div v-if="!authenticated">
        <b-row align-v="center" v-if="!processing">
          <b-col>
            <b-button
              variant="primary"
              size="md"
              @click="tryLogin"
              >SIGN IN</b-button
            >
          </b-col>
          <b-col>
            <router-link to="/forgot-password">Forgot password?</router-link>
          </b-col>
        </b-row>

        <b-progress
          v-if="processing"
          :value="100"
          :max="100"
          animated
          class="w-100"
          style="margin-top: 5px"
        ></b-progress>

        <div style="margin-top: 30px">
          Don't have an account?
          <br />
          <router-link to="/signup">Get Started</router-link>
        </div>
      </div>
      <div v-else class="text-center">System will now redirect</div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

export default {
	name: "SignIn",
	mounted() {
    console.log('test');
		this.minWindowHeight = window.innerHeight + "px";

    // Try auto login
    if (this.$route.query && this.$route.query.a_token) {
      setTimeout(() => {
        this.tryAutoLogin(this.$route.query.a_token)
      }, 1000);
    }
	},
	data() {
		return {
			minWindowHeight: null,
			error: null,
			processing: false,
			authenticated: false,
			formErrors: {
				user: false,
				password: false,
			},
			form: {
				user: null,
				password: null,
			},
			onInputChange(prop) {
				this.formErrors[prop] = false;
			},
		};
	},
	methods: {
		async tryLogin() {
			this.error = null;
			if (!this.validateForm()) return;

			this.processing = true;
			try {
				const { user, authKey } = await UserService.login(
					this.form.user,
					this.form.password
				);
				UserService.setSession(user, authKey);
				this.authenticated = true;
				// this.$store.dispatch('Auth/login');
				// TODO: user router instead of page reload, to not load vuex state twice
				// setTimeout( () => this.$router.push({ path: '/dashboard'}), 1000);
				this.$notify({ group: 'success', text: 'Successfully logged in' });
				setTimeout(() => {
					window.location.href = window.location.origin + '/app/dashboard';
				}, 1000);
			} catch (err) {
				this.error = err;
			}

			this.processing = false;
		},
    async tryAutoLogin(authKey) {
			this.error = null;

			this.processing = true;
			try {
        const userProfile = await UserService.getUserInfo();
				UserService.setSession(userProfile, authKey);
				this.authenticated = true;
				this.$notify({ group: 'success', text: 'Your sub-account was activated' });
				setTimeout(() => {
					window.location.href = window.location.origin + '/app/dashboard';
				}, 1000);
			} catch (err) {
				this.error = err;
			}

			this.processing = false;
		},
		validateForm() {
			const props = _.keys(this.form);
			let valids = 0;
			_.each(props, (prop) => {
				const val = this.form[prop];
				if (val) valids++;
				this.formErrors[prop] = !val;
			});

			return valids === props.length;
		},
	},
	components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.castr-logo-wrapper {
  position: relative;
  display: inline-block;
}
.castr-logo-wrapper .logo-icon-dot {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  right: 1px;
  top: 16px;
  background-color: #ec0104;
  -webkit-animation: blink 2s ease-in-out infinite;
  animation: blink 2s ease-in-out infinite;
}
.castr-logo-wrapper .logo-icon-dot.dot-lg {
  top: 20px;
  right: 3px;
}
.logo-icon {
  width: 130px;
  margin-left: -5px;
}
.form {
  display: inline-block;
  align-self: center;
  padding: 60px 0;
}
.field-container {
  width: 300px;
  padding: 10px 0;
  /* border-bottom: 1px solid #22244d; */
}
.field-container:last-of-type {
  border-bottom: none;
}
</style>

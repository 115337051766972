<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();

const props = defineProps({
  email: {
    type: String,
    required: true
  }
})

</script>

<template>
  <main>
        <p class="text-sm text-surface-7 mb-3">
					You do not have permission to access this feature.<br>
					Please contact 
					<ejs-tooltip class="email-tooltip text-underline text-dark-9 text-500 text-md"
									:content="email">
						team owner 
					</ejs-tooltip>
					to request access.
				</p>
  </main>
</template>

<style scoped>
 .email-tooltip {
	display: inline;
	font-weight: 500 !important;
	font-size: 13px !important;
 }
</style>
import MultiviewService from "@/services/MultiviewService";
import router from "@/router";
import _ from "lodash";

const defaultState = () => ({
  multiviews: [],
  multiview: {},
  currentMultiviewId: "",
  multiviewLoaded: false,
  teamPermissionError: false
});

const state = defaultState();

const mutations = {
  GET_MULTIVIEWS(state, multiviews) {
    state.multiviews = multiviews;
  },
  GET_MULTIVIEW(state, multiview) {
    state.multiview = multiview;
    state.multiviewLoaded = true;
  },
  SET_CURRENT_MULTIVIEW(state, id) {
    state.currentMultiviewId = id;
  },
  SET_MULTIVIEW_SOURCE_STREAM(state, { index, stream }) {
      state.multiview.streams[index] = stream
  },
  REMOVE_MULTIVIEW(state, id) {
    const objIndex = state.multiviews.findIndex(obj => { 
      return obj._id === id
    })
    if (objIndex >= 0) {
      state.multiviews.splice(objIndex, 1)
    }
  },
  TOGGLE_MULTIVIEW(state, id) {
    const objIndex = state.multiviews.findIndex(obj => { 
      return obj._id === id
    })

    if (objIndex >= 0) {
      Object.assign(state.multiviews[objIndex], { enabled: !state.multiviews[objIndex].enabled })
      state.multiview.enabled = !state.multiview.enabled
    }
  },
  SET_MULTIVIEW_LAYOUT(state, { id, streams, layout }) {
    const objIndex = state.multiviews.findIndex(obj => { 
      return obj._id === id
    })

    if (objIndex >= 0) {
      Object.assign(state.multiviews[objIndex], { layout, streams })
      state.multiview.layout = layout
      state.multiview.streams = streams
    }
  },
  SET_MULTIVIEW_NAME(state, { id, name }) {
    const objIndex = state.multiviews.findIndex(obj => { 
      return obj._id === id
    })

    if (objIndex >= 0) {
      Object.assign(state.multiviews[objIndex], { name })
      state.multiview.name = name
    }
  },
  SET_MULTIVIEW_TEAM_PERMISSION_ERROR(state, { perm }) {
    state.teamPermissionError = perm;
  },
};

const actions = {
  async getMultiviews({ commit }) {
    const multiviews = await MultiviewService.getMultiviews();
    commit("GET_MULTIVIEWS", multiviews);
  },
  async getMultiview({ commit }, id) {
    const multiview = await MultiviewService.getMultiview(id);
    // resize streams array to layout size
    const layout = multiview.layout
    const newStreams = []
    if (_.isArray(multiview.streams)) {
      for (let i = 0; i < layout; i++) {
        newStreams[i] = null
        if (multiview.streams[i]) {
          newStreams[i] = multiview.streams[i]
        }
      }
      multiview.streams = newStreams
    }

    commit("GET_MULTIVIEW", multiview);
  },
  setSource({ commit }, { index, stream }) {
    commit("SET_MULTIVIEW_SOURCE_STREAM", { index, stream })
  },
  setCurrentMultiview({ commit }, id) {
    commit("SET_CURRENT_MULTIVIEW", id);
  },
  removeMultiview({ commit }, { id }) {
    commit("REMOVE_MULTIVIEW", id)
  },
  toggleMultiview({ commit }, { id }) {
    commit("TOGGLE_MULTIVIEW", id)
  },
  setLayout({ commit }, { id, streams, layout }) {
    commit("SET_MULTIVIEW_LAYOUT", { id, streams, layout })
  },
  setName({ commit }, { id, name }) {
    commit("SET_MULTIVIEW_NAME", { id, name })
  },
  setTeamPermissionError({ commit }, { error }) {
    commit("SET_MULTIVIEW_TEAM_PERMISSION_ERROR", { perm: error })
  },
};

const getters = {
  multiviews: (state) => state.multiviews,
  multiview: (state) => state.multiview,
  currentMultiview: (state) => {
    if (state.currentMultiviewId === '') {
      return state.multiviews.find(
        (mv) =>
          mv._id === (router.currentRoute.params && router.currentRoute.params.multiviewId)
      );
    } else {
      return state.multiviews.find((mv) => mv._id === state.currentMultiviewId);
    }
  },
  multiviewLoaded: (state) => state.multiviewLoaded,
  teamPermissionError: (state) => state.teamPermissionError
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

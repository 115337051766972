<template>
  <g>
    <path d="M65 90C89.8521 90 110 69.8917 110 45C110 20.1083 89.7633 0 65 0C40.1479 0 20 20.1083 20 45C20 69.8917 40.1479 90 65 90Z" fill="#232D4D"/>
    <path d="M112.737 15.1649V74.0025C112.737 75.7288 111.299 77.1674 109.572 77.1674H20.1649C18.4386 77.2393 17 75.8008 17 74.0745V15.1649C17 13.3666 18.4386 12 20.1649 12H109.572C111.37 12 112.737 13.4386 112.737 15.1649Z" fill="#343E61" stroke="#404B72" stroke-miterlimit="10"/>
    <path d="M112.737 15.1649V19.6964H17V15.1649C17 13.3666 18.4386 12 20.1649 12H109.572C111.37 12 112.737 13.4386 112.737 15.1649Z" stroke="#404B72" stroke-miterlimit="10"/>
    <path d="M21.2438 17.1786C21.8397 17.1786 22.3227 16.6956 22.3227 16.0997C22.3227 15.5038 21.8397 15.0208 21.2438 15.0208C20.6479 15.0208 20.1649 15.5038 20.1649 16.0997C20.1649 16.6956 20.6479 17.1786 21.2438 17.1786Z" fill="#404B72"/>
    <path d="M24.6244 17.1786C25.2203 17.1786 25.7033 16.6956 25.7033 16.0997C25.7033 15.5038 25.2203 15.0208 24.6244 15.0208C24.0285 15.0208 23.5455 15.5038 23.5455 16.0997C23.5455 16.6956 24.0285 17.1786 24.6244 17.1786Z" fill="#404B72"/>
    <path d="M28.077 17.1786C28.6729 17.1786 29.156 16.6956 29.156 16.0997C29.156 15.5038 28.6729 15.0208 28.077 15.0208C27.4812 15.0208 26.9981 15.5038 26.9981 16.0997C26.9981 16.6956 27.4812 17.1786 28.077 17.1786Z" fill="#404B72"/>
    <rect x="21" y="24" width="28" height="22" rx="1" fill="#404B72"/>
    <path d="M38.5 34.134C39.1667 34.5189 39.1667 35.4811 38.5 35.866L34 38.4641C33.3333 38.849 32.5 38.3679 32.5 37.5981L32.5 32.4019C32.5 31.6321 33.3333 31.151 34 31.5359L38.5 34.134Z" fill="#8794B5"/>
    <rect x="51" y="49" width="28" height="22" rx="1" fill="#404B72"/>
    <rect x="51" y="24" width="28" height="22" rx="1" fill="#404B72"/>
    <rect x="81" y="49" width="28" height="22" rx="1" fill="#404B72"/>
    <path d="M68.5 34.134C69.1667 34.5189 69.1667 35.4811 68.5 35.866L64 38.4641C63.3333 38.849 62.5 38.3679 62.5 37.5981L62.5 32.4019C62.5 31.6321 63.3333 31.151 64 31.5359L68.5 34.134Z" fill="#8794B5"/>
    <path d="M68.5 59.134C69.1667 59.5189 69.1667 60.4811 68.5 60.866L64 63.4641C63.3333 63.849 62.5 63.3679 62.5 62.5981L62.5 57.4019C62.5 56.6321 63.3333 56.151 64 56.5359L68.5 59.134Z" fill="#8794B5"/>
    <path d="M98.5 59.134C99.1667 59.5189 99.1667 60.4811 98.5 60.866L94 63.4641C93.3333 63.849 92.5 63.3679 92.5 62.5981L92.5 57.4019C92.5 56.6321 93.3333 56.151 94 56.5359L98.5 59.134Z" fill="#8794B5"/>
    <path d="M26.5872 83.4264H10.2045C8.39962 83.4264 6.94183 81.9655 6.94183 80.1568V57.4088C6.94183 51.0783 12.0788 46 18.3264 46C24.5741 46 29.7111 51.1479 29.7111 57.4088V80.2264C29.7805 81.9655 28.3227 83.4264 26.5872 83.4264ZM13.4672 76.9568H23.3246V57.4088C23.3246 54.6957 21.1032 52.4696 18.3959 52.4696C15.6885 52.4696 13.4672 54.6957 13.4672 57.4088V76.9568Z" fill="#68759C"/>
    <path d="M33.3208 89.618H3.67917C1.66604 89.618 0 87.9484 0 85.931V66.2439C0 64.2265 1.66604 62.5569 3.67917 62.5569H33.3208C35.334 62.5569 37 64.2265 37 66.2439V85.931C37 87.9484 35.334 89.618 33.3208 89.618Z" fill="#68759C"/>
    <path d="M21.5937 73.5042C21.5937 74.7234 20.8985 75.7685 19.9136 76.291V79.6583C19.9136 80.4711 19.2763 81.1097 18.4652 81.1097C17.6542 81.1097 17.0169 80.4711 17.0169 79.6583V76.291C16.032 75.7685 15.3368 74.7234 15.3368 73.5042C15.3368 71.7625 16.7272 70.3691 18.4652 70.3691C20.2033 70.3691 21.5937 71.7625 21.5937 73.5042Z" fill="#232D4D"/>
  </g>
</template>

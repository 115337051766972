<script setup>
import FormButton from '../../components/Atoms/FormButton.vue';
import Spinner from "@/components/ui/Spinner.vue"
import StreamSourceDropdown from "@/components/ui/StreamSourceDropdown.vue"
import MultiviewStreamPreview from "@/components/Multiview/MultiviewStreamPreview.vue"
import MultiviewService from '@/services/MultiviewService'
import { useNotification } from "@kyvg/vue3-notification"
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronLeft from '../../components/icon/IconChevronLeft.vue';
import IconMultiviewLayout from '../../components/icon/IconMultiviewLayout.vue';
import IconBin from '../../components/icon/IconBin.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import SelectLayoutModal from '@/components/modals/SelectLayoutModal.vue'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import Title from "@/components/ui/Title.vue"
import IconMultiviewLockedPlaceholderImage from '../../components/icon/IconMultiviewLockedPlaceholderImage.vue'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';

import { ref, computed, onUnmounted, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { notify } = useNotification()

const instance = getCurrentInstance();
const root = instance.proxy.$root;

const multiview = computed(() => store.getters['Multiview/multiview'])
const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const maxMultiviews = computed(() => store.getters['User/maxMultiviews'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const teamPermissionError = computed(() => store.getters['Multiview/teamPermissionError'])
const userEmail = computed(() => store.getters['User/userEmail'])

const streamSourceDropdownVisible = ref(false)
const modalDEMOVideo = ref(null)

onMounted(async () => {
	store.dispatch('Ui/setLoadingStatus', true)
	await store.dispatch('Streams/getStreams')

	try {
		await store.dispatch('Multiview/getMultiviews')
		await store.dispatch('Multiview/getMultiview',  route?.params?.id)
		store.dispatch('Multiview/setTeamPermissionError', { error: false })
	} catch(err){
		if (err && err.toString().includes('not have access to')) {
			store.dispatch('Multiview/setTeamPermissionError', { error: true })
		}

		notify({
			group: "error",
			text: err || "Multiview load failed",
		});
	}

	store.dispatch('Ui/setLoadingStatus', false)

	const mainWrapper = document.querySelector('.main-content-wrapper');
	if (mainWrapper) {
		mainWrapper.style.overflow = 'scroll';
	}

})
onUnmounted(() => {
})

const onSourceDropdownShow = () => {
  streamSourceDropdownVisible.value = true
}

const onSourceDropdownHide = () => {
  streamSourceDropdownVisible.value = false
}

const onSourceStreamSelected = async (stream) => {
	const streamObj = {
		_id: stream?._id,
		key: stream?.key,
		enabled: stream?.enabled,
		name: stream?.name
	}
	const index = Number(stream.refLink)
	const streams = _.map(multiview.value.streams, (item) => {
		return item?._id ?? null
	})

	streams[index]  = streamObj?._id ?? null

	try {
		await MultiviewService.updateMultiviews(
			multiview.value._id,
			{
				layout: multiview.value.layout,
				streams: streams
			}
		);

		store.dispatch('Multiview/setSource', { index , stream: streamObj?._id ? streamObj : null })

		notify({
			group: "success",
			text: `Stream source ${streamObj?._id ? 'updated' : 'removed'}`,
		});
	} catch (err) {
		notify({
			group: "error",
			text: err.message || "Multiview source update failed",
		});
	}
}

const toggleMultiview = async () => {
	try {
		await MultiviewService.toggleMultiview(
			multiview.value._id,
		);

		store.dispatch('Multiview/toggleMultiview', { id: multiview.value._id })

		notify({
			group: "success",
			text: `Multiview ${multiview.value.enabled ? 'disabled' : 'enabled'}`
		});
	} catch (err) {
		notify({
			group: "error",
			text: err.message || "Multiview source update failed",
		});
	}
}

const onMultiviewDelete = async () => {
	if (!multiview.value._id) {
		return
	}

	try {
		await MultiviewService.deleteMultiview(multiview.value._id)
		store.dispatch('Multiview/removeMultiview', { id: multiview.value._id })
		notify({
			group: "success",
			text: "Multiview deleted",
		});

		router.push({
			name: "MultiviewDashboard",
		})
	} catch (err) {
		notify({
			group: "error",
			text: err.message || "Multiview delete failed",
		});
	}
}

const onDeleteMultiviewRequest = (id) => {
	root.$emit("bv::show::modal", 'delete-multiview-content')
}

const onChangeLayoutRequest = (id) => {
	root.$emit("bv::show::modal", 'change-layout')
}

const showDemo = () => {
	modalDEMOVideo.value.show()
}

const onLayoutResize = async (newLayout) => {
	const currentLayout = multiview.value.layout
	let streams = multiview.value.streams
	if (newLayout < currentLayout) {
		streams = streams.slice(0, newLayout)
	} else {
		streams = streams.concat(Array(newLayout - streams.length).fill(null));
	}

	try {
		await MultiviewService.updateMultiviews(
			multiview.value._id,
			{
				layout: newLayout,
				streams: streams,
			}
		);

		store.dispatch('Multiview/setLayout', { id: multiview.value._id, streams, layout: newLayout })

		notify({
			group: "success",
			text: `Multiview layout chagned`
		});
	} catch (err) {
		notify({
			group: "error",
			text: err.message || "Multiview layout chagned failed",
		});
	}

}

const onSourceStreamEdit = async ({ stream, refLink }) => {
	await onSourceStreamSelected( { refLink })
}

const  saveMultiviewName = async (name) => {
	try {
		await MultiviewService.updateMultiviews(
			multiview.value._id,
			{
				layout: multiview.value.layout,
				name: name,
			}
		);

		store.dispatch('Multiview/setName', { id: multiview.value._id, name })

		notify({
			group: "success",
			text: `Multiview name changed`
		});
	} catch (err) {
		notify({
			group: "error",
			text: err.message || "Multiview name update failed",
		});
	}
}

</script>

<template>
	<div class="container">
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-surface-8 mt-96" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />

		<main v-else-if="!hasLiveSubscription || isLiveSubscriptionExpired || maxMultiviews === 0 || teamPermissionError">
			<header v-if="!teamPermissionError" class="flex items-center mb-1 mt-5">
				<router-link  to="/multiview"
						class="d-none d-md-flex mt-1 h-4 w-4 items-center justify-center text-surface-8 hover:text-white mr-2"
					>
						<icon-base class="" fill="none">
							<icon-chevron-left />
						</icon-base>
					</router-link>

      				<Title :editable="false" :title="multiview?.name" @changed="saveMultiviewName" />

            </header>
			<div v-if="!teamPermissionError" class="mb-3">
				<p class="text-dark-6 text-s-s m-0">
					<a target="_blank" class="text-reset mr-3 inline-flex items-center"
						href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
						<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
						fill="none">
						<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
							stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
						<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
							stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
						</svg>
						How Multiview works
					</a>
					<a class="text-reset mr-3 inline-flex items-center" @click.prevent="showDemo">
						<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
						fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
							fill="#8794B5" />
						</svg>
						Multiview Demo
					</a>
				</p>
			</div>
            <section class="text-center mt-48 align-items-center">
                <div class="flex flex-col items-center">
                    <icon-base width-view-box="130" height-view-box="90" width="130" height="90" iconColor="none" class="mb-3">
                        <icon-multiview-locked-placeholder-image />
                    </icon-base>
                </div>

				<p v-if="teamPermissionError" class="text-sm text-surface-7 mb-3">
					You do not have permission to access this feature.<br>
					Please contact 
					<ejs-tooltip class="email-tooltip text-underline text-dark-9 text-500 text-md"
									:content="userEmail">
					team owner 
								</ejs-tooltip>
				to request access.
				</p>
                <p v-else class="text-sm text-surface-7 mb-3">Your current plan does not support this feature. <br>Please upgrade to get full access to multiview </p>
				            
                <p v-if="!teamPermissionError" class="flex flex-col w-full items-center">
                    <FormButton to="/subscribe" class="w-64 justify-center">
                        Upgrade Plan
                    </FormButton>
                </p>
            </section>
		</main>
			
		<div v-else
			class="custom-grid-template grid grid-cols-1 grid-rows-[auto_auto] md:grid-cols-[minmax(0,_1fr)_280px] gap-x-4 !px-4 md:!px-0 container justify-self-start ml-0">
			<main class="md:max-w-[calc(100%-10px)] md:pl-4 order-2">
				<header class="d-flex align-items-center mb-1 mt-5">
					<router-link to="/multiview"
						class="d-none d-md-flex mt-1 h-4 w-4 items-center justify-center text-surface-8 hover:text-white mr-2"
					>
						<icon-base class="" fill="none">
							<icon-chevron-left />
						</icon-base>
					</router-link>

      				<Title :editable="true" :title="multiview?.name" @changed="saveMultiviewName" />

					<div class="flex ml-auto">
						<FormButton v-if="multiview.enabled" @click.prevent="toggleMultiview()" type="secondary" size="sm" class="mr-2">
							Disable
						</FormButton>
						<FormButton v-else @click.prevent="toggleMultiview()" type="primary" size="sm" class="mr-2">
							Enable
						</FormButton>
						<FormButton @click.prevent="onDeleteMultiviewRequest()" type="danger" size="sm" class="" isIcon>
							<icon-base>
								<icon-bin />
							</icon-base>
						</FormButton>
					</div>
				</header>

				<div class="mb-3">
					<p class="text-dark-6 text-s-s m-0">
						<a target="_blank" class="text-reset mr-3 inline-flex items-center"
							href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							</svg>
							How Multiview works
						</a>
						<a class="text-reset mr-3 inline-flex items-center" @click.prevent="showDemo">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
								fill="#8794B5" />
							</svg>
							Multiview Demo
						</a>
					</p>
				</div>

				<section>
					<ul class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4" >
						<li class="" 
							v-for="(stream, index) in multiview.streams" 
							:key="index"
						>
						<div class="flex relative justify-content-center h-80" 
							:class="{
								'bg-surface-2': !stream || !multiview.enabled, 
								'bg-black': stream && multiview.enabled
							}"
						>
							<div v-if="!stream || !multiview.enabled" class="flex items-center" style="width: 300px;">
								<!-- TODO: refact -->
								<ejs-tooltip 
									v-if="!multiview.enabled" 
									:content="'Enable to continue using Multiview'">
									<stream-source-dropdown 
										@on-show="onSourceDropdownShow" 
										@on-hide="onSourceDropdownHide"
										@on-source-selected="onSourceStreamSelected" 
										:dropRight="false" 
										:refLink="index"
										:onlyLiveSelector="true" 
										:selectedStream="stream"
										:dropdownDisabled="!multiview.enabled"
										/>
								</ejs-tooltip>
								<stream-source-dropdown v-else
										@on-show="onSourceDropdownShow" 
										@on-hide="onSourceDropdownHide"
										@on-source-selected="onSourceStreamSelected" 
										:dropRight="false" 
										:refLink="index"
										:onlyLiveSelector="true" 
										:selectedStream="stream"
										:dropdownDisabled="!multiview.enabled"
										/>
							</div>
							<multiview-stream-preview v-else 
								@on-source-stream-edit="onSourceStreamEdit"
								:stream="stream" 
								:refLink="index" 
							/>

						</div>
					</li>
				</ul>
				</section>


			</main>

			<div class="layout-button mb-3">
				<FormButton @click.prevent="onChangeLayoutRequest" type="secondary" size="sm" class="">
					<icon-base>
						<icon-multiview-layout />
					</icon-base>
					
					<span class="ml-2">Layout</span>
				</FormButton>
			</div>

		</div>

		<confirm-modal 
			modalId="delete-multiview-content" 
			message="Are you sure you want to delete your Multiview ?"
			modal-type="danger" 
			@modal-confirm="onMultiviewDelete" />

		<select-layout-modal 
			modalId="change-layout" 
			:layout="multiview.layout"
			@on-layout-resize="onLayoutResize" />

		<demo-video-modal ref="modalDEMOVideo" videoid="cm5nfhg2e05nqm5nfs3y3h7j1" />
	</div>
</template>

<style scoped>
 .email-tooltip {
	display: inline;
	font-weight: 500 !important;
	font-size: 13px !important;
 }
 .multiview-wrapper {
	min-height: 300px;
 }

 .layout-button {
	position: fixed;
	bottom: 0;
	right: 48%;
	z-index: 99;
}

@media (max-width: 1508px) {
  .custom-grid-template {
    grid-template-columns: minmax(0, 1fr) 50px !important;
  }
}

</style>

<script setup>
import _ from 'lodash'
import FormButton from '../../components/Atoms/FormButton.vue'
import IconBase from '../../components/icon/IconBase.vue'
import IconChevronRight from '../../components/icon/IconChevronRight.vue';
import IconCheckmark from '../../components/icon/IconCheckmark.vue';
import IconWhatsUp from '../../components/icon/IconWhatsUp.vue';

const props = defineProps({
  subscriptionPackages: {
    type: Array,
  },
  plansData: {
    type: Array,
  },
})

const yearly = defineModel('yearly')

</script>

<template>
  <div class="group">
    <h2
      class="bg-gradient-to-r from-[#82c3ff] to-[#e8fafb] text-dark-1 !py-2 !px-4 rounded-xl font-medium  w-48 flex items-center justify-between rotate-90">
      Custom Plan
      <icon-base fill="none">
        <icon-chevron-right />
      </icon-base>
    </h2>
    <div class="!hidden group-hover:!flex flex-col bg-white absolute right-[68px] -top-20 rounded-xl w-[200px] shadow">
      <div class="bg-gradient-to-b from-[#82c3ff] to-[#e8fafb] !p-4 rounded-t-xl">
        <h2 class="font-medium text-dark-1">Custom Plan</h2>
        <h3 class="text-2xl font-medium text-dark-1 mb-2">On Request</h3>
        <FormButton class="!mb-3 justify-center" size="lg" label="Book a meeting" full-width href="https://calendly.com/castr/sales?utm_source=dashboard&utm_medium=website&utm_campaign=dashboard-checkout" />
        <FormButton size="lg"
          href="https://wa.me/16049988035"
          customClasses="!border !border-success inline-flex items-center justify-center !rounded-md leading-none text-nowrap box-border font-medium hover:no-underline max-w-full truncate grow-0 py-2 !px-3 h-10 text-base bg-transparent hover:!border-success-light !text-success hover:!text-success-light w-full">
          Chat with us
          <icon-base classIcon="ml-2" fill="none">
            <icon-whats-up />
          </icon-base>
        </FormButton>
      </div>
      <hr class="!border-surface-8 my-6">
      <div class="!px-4">
        <p class="text-surface-6 text-tiny mb-2">Custom trial available. Try our service at no risk.</p>
        <dl class="grid grid-cols-[16px_1fr] gap-2 text-dark-1 text-sm !mb-4">
          <dt>
            <figure
              class="bg-success mx-auto flex items-center justify-center size-4 rounded-full mt-1">
              <IconBase classIcon="text-white" width="12px" height="12px">
                <IconCheckmark />
              </IconBase>
            </figure>
          </dt>
          <dd>
            24/7 Dedicated Tech Support
          </dd>
          <dt>
            <figure
              class="bg-success mx-auto flex items-center justify-center size-4 rounded-full mt-1">
              <IconBase classIcon="text-white" width="12px" height="12px">
                <IconCheckmark />
              </IconBase>
            </figure>
          </dt>
          <dd>
            Best pricing in the market
          </dd>
          <dt>
            <figure
              class="bg-success mx-auto flex items-center justify-center size-4 rounded-full mt-1">
              <IconBase classIcon="text-white" width="12px" height="12px">
                <IconCheckmark />
              </IconBase>
            </figure>
          </dt>
          <dd>
            No contract to get started with us
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<style></style>
<!-- [writing-mode:vertical-lr]  -->
<script setup>
import { ref, computed } from 'vue'
import _ from 'lodash';
// import moment from 'moment'
import { useNotification } from "@kyvg/vue3-notification";
import moment from 'moment-timezone';
// import Spinner from "@/components/ui/Spinner.vue"
// import StreamService from '@/services/StreamService'
import PlayoutService from '@/services/PlayoutService';
import MultiviewService from '@/services/MultiviewService';
import IconBase from '@/components/icon/IconBase.vue'
import IconGlobe from '@/components/icon/IconGlobe.vue'
import IconChevronDown from '@/components/icon/IconChevronDown.vue'
import AddPlayoutErrorAlert from '@/components/TVPlayout/AddPlayoutErrorAlert.vue'
import { useStore } from 'vuex'
import FormButton from '../Atoms/FormButton.vue'
import FormInput from '../Atoms/FormInput.vue'
import IconCross from '../icon/IconCross.vue'
import { SidebarComponent as EjsSidebar } from '@syncfusion/ej2-vue-navigations'
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue'
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue'
import MultiviewTile from '@/components/Multiview/MultiviewTile.vue'

const store = useStore()
const { notify } = useNotification()

const modalAddMultiviewSidebar = ref()
const error = ref(null)
const search = ref('')
const playoutModeDropdown = ref()
const playoutTimeZoneDropdown = ref()
const selectedTile = ref(2)

const playout = ref({
	name: '',
})

const emit = defineEmits(['multiview-added'])

const closeModal = (() => {
	modalAddMultiviewSidebar.value.hide()
})
const toggleSidebar = () => {
	modalAddMultiviewSidebar.value.toggle();
}
const onReset = (() => {
	playout.value = {
		name: '',
	}
	// selectedTile.value = 2
	error.value = null
})

const createMultiview = (async () => {
	try {
		store.dispatch('Ui/setLoadingStatus', true)
		const newPayout = await MultiviewService.createMultiview(
			playout.value.name,
			selectedTile.value,
		);
		emit('multiview-added', newPayout)
		notify({ group: 'success', text: 'New Multiview Added' })
		closeModal()
		store.dispatch('Ui/setLoadingStatus', false)
	} catch (err) {
		console.log(err);
		error.value = err.message
		store.dispatch('Ui/setLoadingStatus', false)
	}
})

const tileSelected = ((tileNum) => {
    selectedTile.value = tileNum
})

const onSidebarClosed = () => {
	onReset()
}

defineExpose({ toggleSidebar })

</script>

<template>
	<Teleport to="body">
		<ejs-sidebar ref="modalAddMultiviewSidebar" @close="onSidebarClosed" :closeOnDocumentClick="true" :isOpen="false" type="Over" position="Right" :showBackdrop="true" target=".apimaincontent" id="modal-add-playout" class="bg-surface-2 h-screen !w-full md:!w-[420px] rounded-t-2xl md:rounded-t-none">
			<div class="modal-container relative z-30 bg-surface-2 rounded-t-2xl md:rounded-t-none">
				<div class="modal-container w-full md:pt-12">
					<div class="flex items-center justify-between px-3">
						<div class="mt-3 !hidden md:!block">
							<router-link :to="{ path: '/dashboard' }" tag="a" class="modal-logo relative">
								<img height="32px" class="logo-icon" src="@/assets/castr.svg" alt="Streaming Dashboard - Castr" />
								<div class="logo-icon-dot" />
							</router-link>
						</div>
						<div class="!mt-3 md:!mt-5 text-right absolute right-4 top-0 md:static rounded-full bg-surface-4 md:bg-transparent flex md:block items-center justify-center">
							<form-button type="link" isIcon @click="closeModal">
								<icon-base iconColor="none"
									class="">
									<icon-cross />
								</icon-base>
							</form-button>
						</div>
					</div>
					<div class="!mt-0 md:!mt-20 px-3">
						<h3 class="text-s-xxl text-500 bg-surface-3 md:bg-transparent !-mx-4 md:!mx-0 rounded-t-2xl md:rounded-t-none !p-4 md:!p-0">New Multiview</h3>
						<p class="text-dark-7 mb-4 text-sm !mt-4 md:!mt-0"></p>
						<AddPlayoutErrorAlert :error="error" v-if="error" />
					</div>
					<div class="px-3 mb-3">
						<label class="text-dark-9 text-tiny">
							Name
						</label>
						<FormInput id="someid" v-model="playout.name" placeholder="Multiview name" />
					</div>
					<div class="px-3 mb-3">
						<label class="text-dark-9 text-s-ss">
							Layout
						</label>

                        <div class="flex items-center justify-between">
                            <MultiviewTile 
                                v-for="(item, index) in [2, 4, 6]" 
                                :tiles="item" 
                                :key="index" 
                                :selected="item == selectedTile"
                                @tile-selected="tileSelected"
                            />
                        </div>

					</div>
					<div class="px-3">
						<div class="text-center">
							<FormButton :disabled="playout.name.length === 0" @click="createMultiview" type="primary" size="md"
								class="w-full mt-4 justify-center">
								Create
							</FormButton>
							<FormButton type="secondary" size="md" class="w-full mb-3 mb:md-0 mt-2 justify-center"
								@click="closeModal">
								Cancel
							</FormButton>
						</div>
					</div>
				</div>
			</div>
		</ejs-sidebar>
	</Teleport>
</template>

<style>
.timezone-list {
	@apply max-h-[300px] overflow-y-auto
}
</style>
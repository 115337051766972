<script setup>
import _ from 'lodash'
import FormSwitch from '../../components/Atoms/FormSwitch.vue'
import subscribePlansCompareDescription from './subscribePlansCompareDescription'
import SubscribePlanItemCompareRow from './SubscribePlanItemCompareRow.vue';
import SubscribePlanItemHeader from './SubscribePlanItemHeader.vue';
import SubscribePlanItemCompareHeader from './SubscribePlanItemCompareHeader.vue';
import SubscribePlansCustomWidget from './SubscribePlansCustomWidget.vue';

const props = defineProps({
  subscriptionPackages: {
    type: Array,
  },
  plansData: {
    type: Array,
  },
})

const { rows } = subscribePlansCompareDescription
const yearly = defineModel('yearly')

</script>

<template>
  <div class="relative">
    <div class="grid grid-cols-[50%_50%] lg:grid-cols-1 !overflow-auto lg:!overflow-visible">
      <div class="lg:hidden bg-white sticky left-0 z-[10] shadow-md">
        <ul class="text-tiny text-surface-1">
          <li class="h-[220px] block bg-white w-full">
            <div
              class="!p-4 text-tiny text-surface-1 bg-light-1 flex flex-col items-start justify-center size-full border-b border-surface-9 rounded-tl-xl md:sticky top-0 z-10"
              id="bottom">
              <FormSwitch class="mb-2" label="Yearly" v-model="yearly" />
              <p class="text-yellow font-medium">Save 37% + more concurrent streams</p>
            </div>
          </li>
          <li class="divide-x divide-surface-9 border-b border-surface-9 !border-l-0  odd:bg-white even:bg-light-1"
            v-for="(row, index) in rows">
            <SubscribePlanItemCompareHeader class="basis-[20%] shrink-0 grow-0 leading-none text-xs" :key="index"
              :data="row" :is-yearly="props.isYearly" />
          </li>
        </ul>
      </div>
      <div class="lg:w-10/12 mx-auto">
        <div
          class="grid grid-cols-[repeat(5,50vw)] lg:grid-cols-[20%_repeat(4,16%)] rounded-xl mx-auto divide-x divide-surface-9  lg:w-full">
          <div
            class="!p-4 text-tiny text-surface-1 bg-light-1 flex-col items-start justify-center size-full border-b border-surface-9 rounded-tl-xl md:sticky top-0 z-10 !hidden lg:!flex"
            id="bottom">
            <FormSwitch class="mb-2" label="Yearly" v-model="yearly" />
            <p class="text-yellow font-medium">Save 37% + more concurrent streams</p>
          </div>
          <SubscribePlanItemHeader class="last-of-type:rounded-tr-xl sticky top-0" :plan="plan"
            v-for="(plan, index) in plansData" :key="index" :is-yearly="yearly" />
          <SubscribePlanItemCompareRow v-for="(row, index) in rows" :key="index" :row="row" :is-yearly="yearly" />
        </div>
      </div>
    </div>
    <SubscribePlansCustomWidget class="absolute left-[87%] top-20 !hidden lg:!block" />
  </div>
</template>

<style></style>

<script setup>
import { computed, ref, onMounted, getCurrentInstance, watch } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import _ from "lodash";
import moment from "moment";
import Spinner from "@/components/ui/Spinner.vue";
import FormButton from '@/components/Atoms/FormButton.vue';
import IconBase from "@/components/icon/IconBase.vue";
import IconPlus from "@/components/icon/IconPlus.vue";
import AddVodModal from "@/components/modals/AddVodModal.vue";
import SubscriptionService from "@/services/SubscriptionService";
import AlertModal from "@/components/modals/AlertModal.vue";
import { useRouter } from 'vue-router'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;

const router = useRouter()

const noAccess = ref(false)
const streams = ref([])
const isVodPaid = ref(false)
const isVodTrial = ref(false)
const emptyVodSub = ref(true)
const vodTrialExpiredIn = ref(0) // days

const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const vodStreams = computed(() => store.getters['Streams/vodStreams'])
const vodFiles = computed(() => store.getters['Streams/vodFiles'])
const baseSubscription = computed(() => store.getters['User/baseSubscription'])
const addonSubscriptions = computed(() => store.getters['User/addonSubscriptions'])
const maxVodFolders = computed(() => store.getters['User/maxVodFolders'])
const maxVodStorage = computed(() => store.getters['User/maxVodStorage'])

const userSubscription = computed(() => {
  return {
    subscription: baseSubscription.value,
    addonSubscriptions: addonSubscriptions.value,
    maxVodItems: maxVodFolders.value,
    maxVodStorage: maxVodStorage.value
  }
})

const allVodStreams = computed(() => {
  return _.orderBy(streams.value, ['creationTime'], ['desc']).filter((stream) => {
    if (stream.type !== 'vod') return false;
    return true
  });
})

const userloaded = computed(() => {
  return store.state.User.userloaded;
})

const activeTeam = computed(() => {
  return store.state.User.activatedTeam;
})

const memberPermission = computed(() => {
  return activeTeam.value && store.state.User.memberPermission;
})

const userEmail = computed(() => {
  return store.state.User.email;
})

watch(
  () => memberPermission.value,
  async (configNew, configOld) => {
    const permission = await checkPermissions();

    if (!activeTeam.value || permission) {
      noAccess.value = false;
    } else {
      noAccess.value = true;
    }
  },
  { deep: true }
)

onMounted(async () => {
  store.dispatch('Ui/setLoadingStatus', true);
  await store.dispatch('Streams/getStreams');
  if(memberPermission.value && !memberPermission.value.hostingManage) {
    noAccess.value = true;
    // notify({
    //   group: "error",
    //   text: `You are not allowed to manage "Video Hosting", please contact your team's admin or owner.`,
    // });
    // router.push({ path: '/' });
    // return;
  }
  if (vodStreams.value.length === 0) {
    store.dispatch('Ui/setLoadingStatus', false)
  } else {
    await store.dispatch('Streams/getVodFilesByVodId', vodStreams.value[0]?._id)
    if (vodFiles.value.length > 0) {
      router.push({
        name: "VODStreamManage",
        params: { streamId: vodStreams.value[0]._id, videoId: vodFiles.value.at(-1).id },
      });
    } else {
      router.push({
        name: "VODBucketVideos",
        params: { streamId: vodStreams.value[0]._id },
      });
    }
  }
  store.dispatch("Ui/getAllTags");
  if (!activeTeam.value) await store.dispatch('User/loadUser', null, { root: true });
  root.$on('teamchanging', data => {
  });
  root.$on('teamchanged', data => {
    const redirect = checkRedirect()
    if (redirect) {
      router.push({ path: '/' });
    }
  });

})

const checkPermissions = () => {
  return memberPermission.value && (memberPermission.value.hostingManage || memberPermission.value.hostingDelete);
}

const activateTrial = async () => {
  const subCat = "vod";
  try {
    await SubscriptionService.requestTrial(subCat);
    await store.dispatch('User/loadUser', null, { root: true });
    getVodSubExpiredDays();
    notify({
      group: "success",
      text: `Video Hosting Trial is activated successfully`,
    });
  } catch (e) {
    console.error(e);
    notify({
      group: "error",
      text: `Video Hosting Trial is activated unsuccessfully`,
    });
    return;
  }
}

const getVodSubExpiredDays = () => {
  let originalVodSub;
  let paidSub;
  let trialSub;
  let hasMaxVodItems = false;
  if (_.isArray(userSubscription.value)) {
    userSubscription.value.forEach((sub) => {
      if (sub.category === "vod") emptyVodSub.value = false;
      if (sub.category === "vod" && sub.package.baseCharge === 0) {
        trialSub = sub;
        isVodTrial.value = true;
      }
      if (
        sub.category === "vod" &&
        sub.package.baseCharge > 0 &&
        sub.enabled
      ) {
        originalVodSub = sub;
      }
      if (
        sub.category !== "vod" &&
        sub.package.definition.maxVodItems &&
        parseInt(sub.package.definition.maxVodItems, 10) > 0
      ) {
        paidSub = sub;
        if (sub.enabled === true) hasMaxVodItems = true;
      }
    });
  }
  if (_.isObject(userSubscription.value)) {
    userSubscription.value.addonSubscriptions.forEach((sub) => {
      if (sub.category === "vod") emptyVodSub.value = false;
      if (sub.category === "vod" && sub.package.baseCharge === 0) {
        trialSub = sub;
        isVodTrial.value = true;
      }
      if (
        sub.category === "vod" &&
        sub.package.baseCharge > 0 &&
        sub.enabled
      ) {
        originalVodSub = sub;
      }
      if (
        sub.category !== "vod" &&
        sub.package.definition.maxVodItems &&
        parseInt(sub.package.definition.maxVodItems, 10) > 0
      ) {
        paidSub = sub;
        if (sub.enabled === true) hasMaxVodItems = true;
      }
    });
  }
  if (trialSub) {
    vodTrialExpiredIn.value = moment(trialSub.cend).diff(moment(), "day");
  }

  if (paidSub) {
    isVodPaid.value = true;
    let paidSubExpiredIn = moment(paidSub.cend).diff(moment(), "day");

    // Compare with the previous expire day to get which is available
    if (trialSub && vodTrialExpiredIn.value > paidSubExpiredIn) {
      paidSubExpiredIn = vodTrialExpiredIn.value;
      isVodPaid.value = false;
    }

    vodTrialExpiredIn.value = paidSubExpiredIn;

    if (hasMaxVodItems) {
      isVodTrial.value = false;
      vodTrialExpiredIn.value = 1;
    }
  }

  if (originalVodSub) {
    isVodPaid.value = true;
    const originalVodSubExpiredIn = moment(originalVodSub.cend).diff(
      moment(),
      "day"
    );

    if (originalVodSubExpiredIn > vodTrialExpiredIn.value) {
      vodTrialExpiredIn.value = originalVodSubExpiredIn;
      isVodTrial.value = false;
    }
  }
  return vodTrialExpiredIn.value;
}

const checkManagePermissions = () => {
  if (!memberPermission.value || !activeTeam.value) return true;
  let hostingManage = (memberPermission.value && memberPermission.value.hostingManage);
  return hostingManage;
}

const redirect = () => {
  setTimeout(() => {
    router.push({ path: '/' });
    window.location.reload();
  }, 1000);
}
const checkRedirect = () => {
  let parts = window.location.pathname.split('/');
  let redirect = false;
  parts.map(function (p) {
    if ((p == 'multistreams') || (p == 'scheduled') || (p == 'videos') || (p == 'livestreams')) {
      redirect = true;
    }
  });
  return redirect;
}
const showVodAddModal = () => {
  if (!checkManagePermissions()) return;
  root.$emit('bv::show::modal', 'modal-add-vod');
}

</script>

<template>
  <Spinner v-if="loadingStatus || !userloaded || (userSubscription.length == 0)" text="Retrieving data..."
    classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
  <div v-else class="container">
    <h1 class="text-2xl font-medium mt-14">Video Hosting</h1>
    <div v-if="!noAccess">
      <section>
        <div v-if="isVodTrial && !isVodPaid">
          <p v-if="vodTrialExpiredIn >= 0">
            Your trial plan will end in {{ vodTrialExpiredIn }} days.Please
            <router-link to="/subscribe?category=live">
              Upgrade
            </router-link>
            to extend your service.
          </p>
          <p v-else>
            Your trial plan has ended.Please
            <router-link to="/subscribe?category=live">
              Upgrade
            </router-link>
            to extend your service.
          </p>
        </div>
        <div v-if="
          (!emptyVodSub || !isVodTrial) && isVodPaid && vodTrialExpiredIn < 0
        ">
          <p>
            Your plan has ended.Please
            <router-link to="/subscribe?category=live">
              Upgrade
            </router-link>
            to continue.
          </p>
        </div>
      </section>
      <section v-if="
        vodStreams.length === 0 &&
       ( userSubscription.maxVodItems > 0 ||
        userSubscription.maxVodStorage > 0 ||
        allVodStreams.length > 0)
      " class="flex justify-center items-center flex-col mt-36">
          <p class="text-base text-surface-9 font-medium !mb-3">It’s empty here</p>
          <p class="text-sm text-surface-6 !mb-2">Go ahead and create a new folder.</p>
          <FormButton
            :id="activeTeam && !checkManagePermissions ? 'member-disabled_new_folder' : ''"
            :disabled="vodTrialExpiredIn < 0" @click="showVodAddModal">
            <icon-base class="!ml-1">
              <icon-plus />
            </icon-base>
            Create Folder
          </FormButton>

      </section>

      <section class="vod-placeholder text-center mt-100" v-else-if="!isVodTrial">
        <h2>Activate Video Hosting for 7 days</h2>
        <p>Activate the trial now to see what Video Hosting can do for you!</p>
        <a href="javascript:void(0)" class="castr-btn castr-btn-primary" @click="activateTrial">
          Activate Trial
        </a>
      </section>
    </div>
    <div class="mt-6 text-center" v-else>
        <h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
        <p class="text-dark-6 text-s-m">Contact the team owner at <a class="text-dark-6 text-s-m text-underline"
            :href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</p>
    </div>
    <add-vod-modal @new-channel="onNewStream" />
    <alert-modal description="Access required" modal-id="disabled-paused"
      :message="'Access required! You don’t have access to the &q u ot;' + (store.state.User.ownermember && (Object.keys(store.state.User.ownermember).length > 0) && store.state.User.ownermember.name) + '&quot;. Contact the team’s or g aniz e   r (' + (store.state.User.ownermember && (Object.keys(store.state.User.ownermember).length > 0) && store.state.User.ownermember.owner.name) + ') for details.'"
      ok-text="Got It" @modal-confirm="redirect" />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

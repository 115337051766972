<template>
  <div class="view">
    <Spinner v-if="loadingStatus || !this.userloaded" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)" spinner-size="15px" />
    <div class="row" v-else>
      <div class="d-flex flex-wrap w-100" v-if="!this.activeTeam || (this.memberPermission && this.memberPermission.teamView)">
        <div class="col-sm-12" v-if="this.teamAllowed && ((ownerDetails && ownerDetails.length > 0) || (ownerMembers.members && ownerMembers.members.length > 0))">
          <div class="my-team">
            <div class="my-team-header d-flex justify-content-between align-items-center">
              <div class="flex-grow-1">
                <h1 class="page-title text-500 mb-1 d-flex justify-content-start align-items-center">
                  <!-- {{ (this.teamOwners.length > 0) && this.teamOwners[0].teamname }}  -->
                    <textarea
                    v-model="ownerTeamname"
                    ref="teamTitle"
                    rows="1"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    title="Edit team name"
                    @focus="resizeTextarea" 
                    @keyup="resizeTextarea"
                    class="page-title page-title_input ml-n3 pl-3 overflow-hidden form-control form-control-sm"
                    @keydown.enter.prevent="preventEnter"
                    @change="onTeamNameChange"
                    :class="{'inactive':activeTeam}"
                  ></textarea>
                </h1>
                <Badge variant="dark-3" id="member_counter" class="team-count text-500 mb-3" pill v-if="this.memberCount" > {{this.totalTeammembers ? (this.totalTeammembers.length - 1) : '..'}}/{{this.memberCount}} members</Badge>
                  <b-tooltip
                  :target="'member_counter'"
                  placement="top"
                  v-if="this.memberCount"
                  >
                  <span class="text-dark-8 text-small">
                    Need more members? <a class="text-underline text-white" target="_blank" href="#">Contact us</a> </span>
                </b-tooltip>
                <p class="text-dark-7 text-s-m mb-3 font-normal" v-if="!activeTeam"><a class="text-dark-7 text-underline" href="https://docs.castr.com/en/articles/6290473-castr-team-setting-up-and-manage-your-team-members" target=_blank>Learn more</a> about your team members' permissions.</p>
              </div>

              <FormButton 
              @click="onInviteBtnClicked"
              variant="primary"
              size="lg"
              v-show="!activeTeam && !loadingStatus"
              :disabled="this.memberCount && ((this.totalTeammembers && this.totalTeammembers.length) > this.memberCount)"
              >
              Invite Member
              </FormButton>
            </div>
          <div class="team-details">
            <table class="min-w-full table-auto main-table">
              <thead class="text-dark-6">
                <tr>
                  <th class="py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Members</th>
                  <th class="py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Role</th>
                  <th class="py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Email</th>
                  <th class="py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-wider">Access</th>
                  <th class="py-3 text-right text-xs font-medium text-gray-500 capitalize tracking-wider status">Status</th>
                  <th class="py-3"></th>
                </tr>
              </thead>
              <tbody :key="tableUpdateCounter">
                <tr v-for="(member, index) in totalTeammembers" :key="index"
                  :class="{'bg-gray-50': index % 2 === 0, 'single-content': (totalTeammembers && totalTeammembers.length < 2)}">
                  <td class="whitespace-nowrap">
                    <span :class="{'grayed-text': member.status === 'pending'}">
                      {{ member.name.length > 25 ? member.name.substring(0, 25) + "..." : member.name }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap">
                    <span class="capitalize pr-2" :class="{'grayed-text': member.status === 'pending'}">{{ member.role || (member.permissions && member.permissions.role) }}</span>
                  </td>
                  <td class="whitespace-nowrap">
                    <span :class="{'grayed-text': member.status === 'pending'}">{{ member.email }}</span>
                  </td>
                  <td class="whitespace-nowrap">
                    <span :class="{'grayed-text': member.status === 'pending'}">{{ countStreams(member.permissions) }}</span>
                  </td>
                  <td class="whitespace-nowrap">
                    <div class="flex items-center justify-end" v-if="member.role !== 'Owner'">
                      <div class="grayed-text text-sm mr-4" v-if="member.status === 'pending'">
                        Invitation sent
                      </div>
                      <div class="flex items-center" :class="{'active-member': member.status !== 'pending'}">
                        <FormSwitch 
                          class="ml-2 mr-0 form-checkbox"
                          :disabled="permissionTogglestatus || !(activeTeam == null)"
                          v-model="member.enabled"
                          @change="onchangeMemberStatus(index)"
                          v-if="member.status !== 'pending'"
                        />
                        
                        <DropDownMenu
                          menu-class="dropdown-menu_md teamlist-option" 
                          right no-caret 
                          variant="icon"
                          size="sm"
                          toggle-class="pr-1 pl-1" 
                          class=""
                          v-if="!activeTeam"
                          :is-icon="true"
                          customDropdownClass="options-dropdown"
                          >
                          <template #toggle-button>
                            <img src="@/assets/icons/settings.svg" alt="Settings">
                          </template>
                          <template #menu-items>
                            <DropDownMenuItem v-if="member && !member.status" class="p-0" @click="editmember(index)">
                              <span class="text-white">Edit Role</span>
                            </DropDownMenuItem>
                            <DropDownMenuItem class="p-0" v-if="member && !member.status && canEditAccess(member && member.permissions)" @click="editAccess(index, member.permissions)">
                              <span class="text-white">Edit Access</span>
                            </DropDownMenuItem>
                            <DropDownMenuItem variant="delete" class="p-0" @click="loadMember(index)">
                              <span class="text-white">Remove</span>
                            </DropDownMenuItem>
                          </template>
                        </DropDownMenu>

                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
        <div class="col-sm-5 text-center d-flex justify-content-center flex-column align-items-center mh-60 mx-auto" v-else>
          <div class="slide-1 mx-auto" v-if="!showForm">
            <h2 class="text-s-xxxl text-500 mb-2">Teamwork begins here</h2>
            <p class="text-dark-7 text-s-xl font-normal mb-4">Invite admins & moderators, assign permissions, have fun. <a target="_blank" class="text-dark-7 text-s-xl font-normal text-underline" href="https://docs.castr.com/en/articles/6290473-setting-up-castr-team-and-manage-your-team-members">Learn more</a></p>
            <b-button
              variant="primary"
              class="mt-3"
              size="lg"
              v-if="teamAllowed"
              @click="showForm = !showForm"
              >
              Create Team
            </b-button>
            <div class="bg-dark-2 py-3 px-3 rounded" v-else>
              <h4 class="text-s-xl text-500 mb-2">Upgrade to use this feature</h4>
              <p class="text-dark-7 text-s-xl font-normal mb-4">Your plan doesn’t allow creating a team</p>
              <a target="_blank" class="btn btn-primary btn-md" href="/app/subscribe">See Pricing Plans</a>
            </div>
          </div>
          <div class="slide-2 bg-dark-2 rounded w-100 mx-auto" v-else>
            <h4 class="mb-4 text-left text-s-xl text-500 mb-4">What is your team name?</h4>
            <div>
              <div class="text-left">
                  <b-input
                    size="md"
                    class="mb-4"
                    name="teamname"
                    placeholder=""
                    v-model="teamname"
                  />
              </div>
              <div class="text-right">
                <b-button
                  size="md"
                  variant="outline-secondary"
                  @click="resetForm()"
                  class="mr-3"
                  >Cancel</b-button
                >
                <b-button
                  size="md"
                  type="submit"
                  variant="primary"
                  :disabled="teamname === ''"
                  @click="setTeam()"
                  >Save</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-access w-100 mt-0 pl-3" v-else>
			<div class="text-center pt-5 mt-5">
				<h4 class="text-dark-9 text-s-xl text-500 mb-1">You don’t have permission to access this.</h4>
				<p class="text-dark-6 text-s-m">Contact the team owner at <a  class="text-dark-6 text-s-m text-underline" :href="'mailto:'+userEmail">{{userEmail}}</a> to gain access.</p>
			</div>
			</div>
    </div>
  	<invite-team-member-modal 
    :teamId="this.tid"
    :members="totalTeammembers"
    @teamMemberInvitationInfo="pathToChooseStreamModal"
    />
  	<update-member-modal 
    :teamId="this.tid"
    :member="totalTeammembers && loadCurrentMember && totalTeammembers[loadCurrentMember]"
    @update-permissions="onUpdatePermissions"
    @teamMemberInvitationInfo="pathToChooseStreamModal"
    />
    <choose-stream-for-moderator-modal
      modal-size="md"
      cancel-text="Cancel"
      :teamId="this.tid"
      :invitation-data="invitationData"
      :member="totalTeammembers && loadCurrentMember && totalTeammembers[loadCurrentMember]"
      @modal-confirm="changeMemberStatus"
      @on-cancel="onCancel"
    />
    <confirm-modal
			:message="'Remove '+ (loadCurrentMember && totalTeammembers[loadCurrentMember] ? (totalTeammembers[loadCurrentMember].name.length > 25 ? totalTeammembers[loadCurrentMember].name.substring(0,25)+'..' : totalTeammembers[loadCurrentMember].name) : '')+ '?'"
			body="This member won’t be able to login to the dashboard and see your streams anymore."
			modal-id="remove-member"
			modal-size="md"
      modal-type="danger"
			:ok-text="'Remove ' + (loadCurrentMember && totalTeammembers[loadCurrentMember] ? (totalTeammembers[loadCurrentMember].name.length > 25 ? totalTeammembers[loadCurrentMember].name.substring(0,25)+'..' : totalTeammembers[loadCurrentMember].name) : '')"
			cancel-text="Cancel"
			@modal-confirm="removeMember"
		/>
    <confirm-modal
			:message="((this.permissionTogglestatus && loadCurrentMember && !totalTeammembers[loadCurrentMember].enabled) ? 'Enable ' : 'Disable ')+ ((loadCurrentMember && totalTeammembers[loadCurrentMember] ? totalTeammembers[loadCurrentMember].name : ''))+ '?'"
			:body="'The team member '+((this.permissionTogglestatus && loadCurrentMember && totalTeammembers[loadCurrentMember] && totalTeammembers[loadCurrentMember].enabled) ? 'won’t':'will')+' be able to see and manage your dashboard.'"
			modal-id="change-status"
			modal-size="md"
			:ok-text="(this.permissionTogglestatus && loadCurrentMember && !totalTeammembers[loadCurrentMember].enabled) ? 'Enable' : 'Disable'"
			cancel-text="Cancel"
			@modal-confirm="changeMemberStatus"
      @modal-cancel="closeStatusmodel"
      :closeoutsideClick="true"
		/>
  </div>
</template>

<script>
import _ from 'lodash';
import UserService from "@/services/UserService";
import { mapGetters } from 'vuex';
import AlertModal from "@/components/modals/AlertModal.vue";
import InviteTeamMemberModal from '@/components/modals/InviteTeamMemberModal.vue';
import UpdateMemberModal from '@/components/modals/UpdateMemberModal.vue';
import ChooseStreamForModeratorModal from '@/components/modals/ChooseStreamForModeratorModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import Spinner from "@/components/ui/Spinner.vue";
import Badge from '../../components/Badge.vue';
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import FormButton from '../../components/Atoms/FormButton.vue';

export default {
  name: "ManageTeam",
  components: {
    AlertModal,
    InviteTeamMemberModal,
    UpdateMemberModal,
    ChooseStreamForModeratorModal,
    ConfirmModal,
    Spinner,
    Badge,
    FormSwitch,
    DropDownMenuItem,
    DropDownMenu,
    FormButton,
  },
  data() {
    return {
      teamAllowed: true,
      showForm: false,
      // processing: true,
      teamArray: null,
      myTeam: null,
      expanded: true,
      shortStringNumber: 15,
      tid: null,
      ownerTeam: null,
      loadCurrentMember: null,
      userSubscription: null,
      ownerTeamname: null,
      activeTeammembers: [],
      pendingTeammembers: [],
      teamOwners: [],
      teamname: '',
      permissionTogglestatus: false,
			memberCount: null,
      invitationData: {},
      prevStateSelectedUserCopy: null,
      tableUpdateCounter: 0,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "Ui/loadingStatus",
      baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
   fields() {
      const allFields = [
        {
          key: "name",
          label: "Members",
          sortable: false,
          tdClass: 'py-2 pl-0 text-dark-9 text-s-m font-normal',
          thClass: 'pl-0'
        },
        { 
          key: "role", 
          label: "Role", 
          sortable: false ,
          tdClass: 'py-2 text-dark-9 text-s-m font-normal text-capitalize'
        },
        { 
          key: "email", 
          label: "Email", 
          sortable: false ,
          tdClass: 'py-2 text-dark-9 text-s-m font-normal'
        },
        { 
          key: "aioStreams", 
          label: "AIO Streams Access", 
          sortable: false ,
          tdClass: 'py-2 text-dark-9 text-s-m font-normal'
        },
        { 
          key: "status", 
          label: "Status", 
          sortable: false,
          tdClass: 'py-2 pr-0',
          thClass: 'pr-5 text-right'
        }
        
      ];

      return allFields;
    },
    totalTeammembers() {
      let team_id = this.tid;
      if((this.activeTeammembers?.length > 0)){
         this.activeTeammembers.map(async function(m){
          let permissions = await UserService.getMemberpermission(team_id, m._id);
          m.role = permissions.role;
          m.enabled = permissions.isEnabled;
          m.permissions = permissions
        });
      }
      if((this.pendingTeammembers?.length > 0)){
        this.pendingTeammembers.map(function(m){
          m.enabled = true;
          m.role = m.role || m.permissions.role
        });
      }
      let totalMembers = this.pendingTeammembers && this.activeTeammembers?.concat(this.pendingTeammembers);
      if(!this.activeTeam){
        return this.teamOwners?.concat(totalMembers);
      }else{
        if((this.ownerTeam?.length > 0)){
          this.ownerTeam.filter(x => x._id).map(async function(m){
            let permissions = await UserService.getMemberpermission(team_id, m._id);
            m.role = permissions.role;
            m.enabled = permissions.isEnabled;
            m.permissions = permissions
          });
        }
        return this.ownerTeam;
      }
      
    },
    ownerDetails() {
			let teams = _.cloneDeep(this.$store.state.User.ownerDetails)
      return teams;
		},
    activeTeam(){
        return this.$store.state.User.activatedTeam;
    },
    ownerSubscriptions(){
			return this.$store.state.User.subscriptions
		},
    memberDetails() {
			return this.$store.state.User.memberDetails;
		},
    ownerMembers() {
      if(this.$store.state.User.ownermember.ownerId){
        this.ownerId = this.$store.state.User.ownermember.ownerId;
      }else{
        this.ownerId = null;
      }
			return _.cloneDeep(this.$store.state.User.ownermember);
		},
    activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
    memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
    userEmail() {
			return this.$store.state.User.email;
		},
		userloaded(){
			return this.$store.state.User.userloaded;
		},
    nameWidth() {
      switch (true) {
        case (this.ownerTeamname.length >= 4 &&  this.ownerTeamname.length < 15): return this.ownerTeamname.length*16.5+50
        case (this.ownerTeamname.length >= 15) : return this.ownerTeamname && this.ownerTeamname.length*13+40
        default:
          return 90;
      }
    }
  },
  watch: {
    loadCurrentMember(curr) {
      if(curr) {
        if(this.totalTeammembers && this.loadCurrentMember && this.totalTeammembers[this.loadCurrentMember]) {
          this.prevStateSelectedUserCopy = _.cloneDeep(this.totalTeammembers[this.loadCurrentMember]);
        }
      }
    },
    ownerSubscriptions(){
      this.getMembercount();
    },
    ownerDetails(newval,oldval){
      this.fetchMyteam();
    },
    async activeTeam(){
      // await this.$store.dispatch('User/getinfoOwner');
      if(this.$store.state.User.memberDeleted){
        setTimeout(function(){
          // this.processing = true;
          this.$store.dispatch('Ui/setLoadingStatus', true);
        }, 1000);
      }
      this.fetchMyteam();
      
    }
  },
  updated() {
		this.$nextTick(() => {
			let area = this.$refs.teamTitle;
			if (area) {
				area.style.height = area.scrollHeight + 'px';
			}
		})
	},
  async mounted() {
    this.$store.dispatch('Ui/setLoadingStatus', true);
    // this.processing = true;
    await this.$store.dispatch('User/getinfoOwner', null, { root: true });
    this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};
    if((this.ownerDetails.length > 0)){
      this.fetchMyteam();
    }
    if((this.ownerMembers.name)){
      this.ownerTeamname = this.ownerMembers.name;
    }
      this.getMembercount();

   if (this.windowWidth > 767) {
      this.shortStringNumber = 40;
      this.expanded = true;
    } else {
      this.shortStringNumber = 5;
      this.expanded = false;
    }
    this.$root.$on('teamchanging', data => {
      this.$store.dispatch('Ui/setLoadingStatus', true);
      // this.processing = true;
      this.ownerTeamname = '';
      this.memberCount = null;
    });
    this.$root.$on('teamchanged', async data => {
      this.ownerTeamname = this.ownerMembers.name;
      this.getMembercount();
      this.fetchMyteam();
      this.$store.dispatch('Ui/setLoadingStatus', false);
      // this.processing = false;
    });
    setTimeout(function(){
      this.$store.dispatch('Ui/setLoadingStatus', false);
      //  this.processing = false;
     }.bind(this), 1000);
    // track event
    window.trackEvent(`Team Page`);
    window.s = this.totalTeammembers;
  },
  methods: {
    onCancel() {
      if(this.totalTeammembers && this.loadCurrentMember && this.totalTeammembers[this.loadCurrentMember]) {
        this.totalTeammembers[this.loadCurrentMember] = this.prevStateSelectedUserCopy;
        this.tableUpdateCounter++;
      }
    },
    onInviteBtnClicked() {
      this.$root.$emit('bv::show::modal', 'modal-invite-team-admin')
    },
    async setTeam() {
      if(!this.teamname){
        return false
      }
      try {
          // this.processing = true;
          this.$store.dispatch('Ui/setLoadingStatus', true);
          let res = await UserService.setTeam(this.teamname);
          this.showForm = false;
          if (res) {
            await this.$store.dispatch('User/getinfoOwner', null, { root: true });
            // this.processing = false;
            this.$store.dispatch('Ui/setLoadingStatus', false);
            this.$notify({
              group: "success",
              text: `You've successfully created your team`,
            });
          } 
        }catch (err) {
          this.teamname = '';
          this.$notify({
            group: "error",
            text: err.message || "Something went wrong. Please try again later.",
          });
        }
    
    },
    toggleView() {
      this.expanded = !this.expanded;
      if (this.expanded === true) {
        this.shortStringNumber = 15;
      } else this.shortStringNumber = 5;
    },
    async fetchMyteam(){
      if((this.ownerDetails.length > 0)){
        // this.processing = true;
      this.tid = this.ownerDetails[0]._id;
      this.activeTeammembers = _.cloneDeep(this.ownerDetails[0].members)
      try {
           let teamDetails = await UserService.getTeamOwner(this.tid);
           this.ownerTeamname = this.ownerDetails[0].name;
            this.teamOwners = [
                {
                id: this.ownerDetails[0].ownerId,
                role: 'Owner',
                email: this.$store.state.User.email,
                teamname: this.ownerDetails[0].name,
                name: teamDetails?.owner.name
              }
            ];
            let pendings = await UserService.getInvited(this.tid);
            this.pendingTeammembers = pendings;
            // this.processing = false;
            
      } catch (err) {
          this.$notify({
            group: "error",
            text: err.message || "Something went wrong. Please try again later.",
          });
          // this.processing = false;
          return;
        }
      }else{
        this.tid = this.ownerMembers?.id;
        if(this.ownerMembers && Object.keys(this.ownerMembers).length != 0){
            if(this.ownerMembers.members){
              this.ownerMembers.members.map((m)=>{
                m.enabled = m.isEnabled;
              });
            }
            let owner = [
              {
              name: this.ownerMembers?.owner?.name,
              role: 'Owner',
              email: this.ownerMembers?.owner?.email,
            }
          ];
          let pendingOwner = await UserService.getInvited(this.ownerMembers.id);
          this.ownerTeam = owner.concat(this.ownerMembers.members).concat(pendingOwner);
        }
        // this.processing = false;
      }
    },
    closeStatusmodel(){
      // this.totalTeammembers[this.loadCurrentMember].enabled = this.permissionTogglestate;
      // this.permissionTogglestate = null;
      this.permissionTogglestatus = true;
      //this.totalTeammembers[this.loadCurrentMember].enabled = !this.totalTeammembers[this.loadCurrentMember].enabled;
      
      this.permissionTogglestatus = false;
    },
    async changeMemberStatus(){
      this.permissionTogglestatus = true;
      if(this.loadCurrentMember){
        let endpoint = 'member';
        let mid = this.totalTeammembers[this.loadCurrentMember].id;
        if(this.totalTeammembers[this.loadCurrentMember].hasOwnProperty('_id')){
          mid = this.totalTeammembers[this.loadCurrentMember]._id;
        }
        try{
          const change = await UserService.toggleMember(mid, this.tid,  !this.totalTeammembers[this.loadCurrentMember].enabled , endpoint);
          if(change.success){
            this.totalTeammembers[this.loadCurrentMember].enabled = change.updatedPermission.isEnabled;
            
          }
        }catch(err){
          this.$notify({
            group: "error",
            text: err.message || "Something went wrong. Please try again later.",
          });
          
          return
        }
      }
      this.permissionTogglestatus = false
      // this.totalTeammembers[this.loadCurrentMember].enabled = !this.totalTeammembers[this.loadCurrentMember].enabled;
    },
    async onchangeMemberStatus(row){
      this.permissionTogglestatus = true;
      this.loadCurrentMember = row;
      this.totalTeammembers[this.loadCurrentMember].enabled = !this.totalTeammembers[this.loadCurrentMember].enabled;
      // this.permissionTogglestate = this.totalTeammembers[row].enabled;
      return this.$root.$emit('bv::show::modal', 'change-status');
    },
    async removeMember(){
      if(this.loadCurrentMember){
        let endpoint = 'member';
        let mid = this.totalTeammembers[this.loadCurrentMember]._id;
        if(this.totalTeammembers[this.loadCurrentMember].status == 'pending'){
          endpoint = 'invitation';
          mid = this.totalTeammembers[this.loadCurrentMember].id;
        }
        // this.processing = true;
        const remove = await UserService.removeMember(mid, this.tid, endpoint);
        // const remove = { success: true }
        if(remove.success){
          // this.processing = true;
          this.$store.dispatch('Ui/setLoadingStatus', true);
          this.activeTeammembers = this.activeTeammembers.filter(m => m._id !== mid)
          this.pendingTeammembers = this.pendingTeammembers.filter(m => m.id !== mid)
          // this.$store.dispatch('User/getinfoOwner', null, { root: true });
          
          // this.processing = false;
          this.$store.dispatch('Ui/setLoadingStatus', false);
          // this.fetchMyteam();
        }
      }
    },
    loadMember(row) {
      this.loadCurrentMember = row;
      this.$bvModal.show("remove-member");
    },
    editmember(row) {
      this.loadCurrentMember = row;
      this.$bvModal.show("update-member");
    },
    editAccess(row, permissions) {
      this.loadCurrentMember = row;
      this.invitationData.defaultPermissions = permissions
      this.invitationData.action = 'update'
      this.$root.$emit('bv::show::modal', 'modalChooseStreamForModerator')
    },
    resetForm(){
      this.showForm = !this.showForm;
      this.teamname = '';
    },
    preventEnter(e) {
			e.preventDefault();
		},
    async onTeamNameChange() {
			if (!this.ownerTeamname) {
        await this.$store.dispatch('User/getinfoOwner', null, { root: true });
        this.ownerTeamname = this.ownerDetails[0].name;
        };

			if (this.ownerDetails[0].name === this.ownerTeamname) return;
			// try changing Team name
			try {
				const nameChanged = await UserService.changeTeamname(this.tid, this.ownerTeamname);
        if(nameChanged.success){
          this.$notify({
            group: "success",
            text: `Your team was successfully renamed`,
          });
        }
			} catch (err) {
				this.$notify({
					group: 'error',
					title: "Couldn't change team name",
					text: err.message
				});
        
			}
		},
    resizeTextarea: function(e) {
      let area = e.target;
      area.style.height = area.scrollHeight + 'px';
    },
    getMembercount() {
				let memberCountarray = [];
        let userSubscription = this.ownerSubscriptions;
        // this.processing = true;
        const teamCounter = _.map(this.ownerSubscriptions, function(single){
        if(single.enabled){
          let streamCountoverride = _.get(single, ['definitionOverride', 'numOfTeamMembers']);
            if(streamCountoverride){
              memberCountarray.push(streamCountoverride);
            }else{
              memberCountarray.push(_.get(single, ['package', 'definition', 'numOfTeamMembers']));
            }
          }
        });
        memberCountarray = memberCountarray.filter(function(el) {
          if(el && el!=0){
            return el==+el;
          }
      });
      
    // this.processing = false;
  
				if(memberCountarray.length) {
          this.teamAllowed = true;
          this.memberCount = (memberCountarray.length > 1 ? Math.max.apply(Math, memberCountarray) : memberCountarray[0])
        }else{
          this.teamAllowed = false;
        }
		},
    onUpdatePermissions(permissions) {
      this.totalTeammembers[this.loadCurrentMember].role = permissions.role;
      this.totalTeammembers[this.loadCurrentMember].permissions = permissions;
    },
    pathToChooseStreamModal(data) {
      this.invitationData = data
    },
    countStreams(permission) {
      let text = '';
      const vodAccess = permission && (permission.hostingManage || permission.hostingDelete);
      if (permission && !permission.liveStreamDelete && !permission.liveStreamManage && !permission.liveStreamToggle && !permission.liveStreamView) {
        if(!vodAccess) return 'No access'
      } else if (permission && permission.liveStreamAccessIds && permission.liveStreamAccessIds.length === 1) {
        text = '1 stream';
      } else if (permission && permission.liveStreamAccessIds && permission.liveStreamAccessIds.length > 1) {
        text = permission.liveStreamAccessIds.length + ' streams';
      } else {
        text = 'All streams';
      }

      if(!vodAccess) {
        if(!permission) return 'All streams and VOD Folders';
        return text;
      } else if(text) {
        text += ' & ';
      }

      if(permission && permission.vodStreamAccessIds && permission.vodStreamAccessIds.length === 1) {
        text += '1 VOD Folder'
      } else if(permission && permission.vodStreamAccessIds && permission.vodStreamAccessIds.length > 1) {
        text += permission.vodStreamAccessIds.length + ' VOD Folders';
      } else {
        if(text.includes('All')) {
          text += 'VOD Folders';
        } else {
          text += 'All VOD Folders';
        }
      }

      return text;
    },
    canEditAccess(permissions) {
      if ((permissions && !permissions.liveStreamDelete && !permissions.liveStreamManage && !permissions.liveStreamPlatformToggle && !permissions.liveStreamToggle && !permissions.hostingManage && !permissions.hostingDelete && permissions.role === 'moderator') || permissions && permissions.role === 'admin') {
        return false
      } else return true
    }
  }
}
</script>

<style scoped>
.page-title {
  max-width: 800px;
  resize: none;
}
.slide-1 {
    max-width: 365px;
}
.slide-2 {
    padding: 24px 40px;
    max-width: 384px;
    border-radius: 8px !important;
}
textarea.page-title.inactive {
    pointer-events: none;
}
.member-account .active-member {
    padding-right: 32px;
}
.teamlist-option .dropdown-menu_md{
  padding: 0px;
}
@media (max-width: 768px) {
	textarea.page-title {
    width: 100% !important;
    /* height: auto !important; */
    /* white-space: nowrap; */
    word-break: break-word;
    /* height: 58px !important */
}
.my-team-header .page-title {
    flex-direction: column;
    align-items: flex-start !important;
}
.my-team-header>div {
    width: 100%;
}
.my-team-header {
    flex-direction: column;
    align-items: flex-start !important;
}

.my-team-header button {
    margin-bottom: 15px;
}
}

#member_counter {
  width: max-content;
}

.main-table th {
  font-size: 16px;
}

.main-table td {
  color: rgb(209, 219, 240);
}

.main-table .status {
  padding-right: 36px;
}

.team-details {
  max-width: 100%;
  overflow-x: auto;
  height: 60vh;
  padding-bottom: 100px;
}

.team-details td, th {
  padding: 8px 12px;
}

.grayed-text {
  color: var(--c-dark-6);
}
</style>

<style>
.options-dropdown {
  transform: translateX(-65%);
}
</style>

<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();

const props = defineProps({
  tiles: {
    type: Number,
    required: true,
    default: 2,
    validator(v) {
        if (v % 2 === 0 && v > 1 && v < 13 ) {
            return true
        }
        return false
    }
  },
  selected: {
    type: Boolean,
    default: false,
  },
  fontClass: {
    type: String,
    default: 'text-dark-9 text-x-ss'
  }
})

const tileStyles = ref(
  {
    2: {
      columns: 2,
      rows: 1,
      columnSize: '42.5px',
      rowSize: '57px'
    },
    4: {
      columns: 2,
      rows: 2,
      columnSize: '43px',
      rowSize: '27.5px'
    },
    6: {
      columns: 2,
      rows: 3,
      columnSize: '43px',
      rowSize: '17.5px'
    },
    8: {
      status: false,
    },
    12: {
      status: false,
    },
  }
)

const tileStyle = computed(() => {
    return {
        'grid-template-columns': `repeat(${tileStyles.value[props.tiles].columns}, ${tileStyles.value[props.tiles].columnSize})`,
        'grid-template-rows': `repeat(${tileStyles.value[props.tiles].rows}, ${tileStyles.value[props.tiles].rowSize})`,
    }
})

const emit = defineEmits({
	'tile-selected': (value) => { return value },
})

const onTileSelected = (d) => {
	emit('tile-selected', props.tiles);
}

</script>

<template>
  <main>
    <div class="flex items-center flex-col ">
        <a href="#" @click.prevent="onTileSelected">
            <div 
                class="grid-container" 
                :style="tileStyle"
                :class="{ 'selected': props.selected }" 
            >
                <div 
                    v-for="(item, index) in Array(Number(props.tiles)).fill(0)" 
                    :key="index" 
                    class="grid-item">
                </div>
            </div>
        </a>

        <div :class="fontClass">{{ tiles }} items</div>
  </div>
  </main>
</template>

<style scoped>
    .grid-container {
      display: grid;
      gap: 2px; /* Spacing between items */
      background-color:rgba(64, 75, 114, 1); /* Background color of the grid */
      padding: 3px;
      border-radius: 8px;
      width: 94px;
      height: 64px;
    }
    .grid-item {
      /* Item background color */
      background-color: rgba(52, 62, 97, 1);
      border-radius: 3px;
    }
    .item-label {
      text-align: center;
      margin-top: 10px;
      font-size: 14px;
    }
    .grid-container:hover {
        background: #e1e1e1;
        border: 0.5px solid rgba(52, 62, 97, 1);
    }

    .selected {
        background: #e1e1e1;
        border: 0.5px solid rgba(52, 62, 97, 1);
    }
</style>
<template>
	<div class="choose-stream-modal">
		<b-modal size="lg" @hidden="clearData" @shown="checkStreamList" id="modalChooseStreamForModerator" ref="modalChooseStreamForModerator" :size="modalSize" hide-header
			hide-footer centered :no-close-on-backdrop="closeoutsideClick">
			<Spinner v-if="loading" text="Retrieving data..." classes="text-dark-8 my-5" spinner-color="var(--c-dark-8)" spinner-size="15px" />
			<div v-else>
				<b-row>
					<b-col>
						<h2 class="text-s-xl text-500">Choose streams and VOD folders which the moderator should have access to</h2>
						<p class="text-s-l text-dark-8 mb-3">You can make changes in the future.</p>
					</b-col>
				</b-row>

				<div class="tab-area my-4">
					<a class="tab-headers" @click.prevent="onSelectTab('live')" id="aio-tab"
						:class="{ 'tab-selected': selectedTab === 'live', 'tab-disabled': !this.isAioAllowed }">Stream</a>
					<b-tooltip v-if="!this.isAioAllowed" target="aio-tab">
						No permission is set for All in One stream
					</b-tooltip>
					
					<a class="tab-headers" @click.prevent="onSelectTab('vod')" id="vod-tab"
						:class="{ 'tab-selected': selectedTab === 'vod', 'tab-disabled': !this.isVodAllowed }">VOD</a>
					<b-tooltip v-if="!this.isVodAllowed" target="vod-tab">
						No permission is set for Video Hosting
					</b-tooltip>
				</div>
				<b-row v-if="selectedTab === 'live'">
					<b-col class="text-s-s text-dark-8">
						<b-button :disabled="!hasAppSubscription" size="sm" variant="outline-secondary" @click="toggleAll">
							{{ this.selected.length != this.streams.length ? 'Select' : 'Deselect' }} All Streams
						</b-button>
					</b-col>
				</b-row>
				<b-row v-else-if="selectedTab === 'vod'">
					<b-col class="text-s-s text-dark-8">
						<b-button :disabled="!hasVodAppSubscription" size="sm" variant="outline-secondary" @click="toggleAllVods">
							{{ this.selectedVods.length != this.vodStreams.length ? 'Select' : 'Deselect' }} All VOD
						</b-button>
					</b-col>
				</b-row>
				<div class="list-wrapper" v-if="selectedTab === 'live'">
					<b-form-checkbox-group id="checkbox-group" v-model="selected" name="flavour-2">
						<b-row no-gutters class="my-2 mx-n4">
							<b-col class="d-flex align-items-center py-2 px-4" cols="12" v-for="stream in streams" :key="stream._id">
								<b-checkbox :disabled="!hasAppSubscription" :value="stream._id" class="mr-2" />
								<stream-card-view class="flex-grow-1" :stream="stream" :compact-mode="true" :light-mode="false"
									:preview-mode="true" :storage="true" />
							</b-col>
						</b-row>
					</b-form-checkbox-group>
				</div>
				<div class="list-wrapper" v-else-if="selectedTab === 'vod'">
					<b-form-checkbox-group id="checkbox-group" v-model="selectedVods" name="flavour-2">
						<div v-for="streamq in vodStreams" :key="streamq._id" name="vodStreams" class="d-flex align-items-center">
							<b-checkbox :disabled="!hasVodAppSubscription" :value="streamq._id" class="mr-2" />
							<img src="@/assets/images/solid_folder.svg" alt="Folder" class="mr-3" />
							<div class="vodList_single w-100 d-flex align-items-center justify-content-between">
								<p class="text-500 text-s-l mb-0">
								<b-badge variant="dark-3" v-if="streamq.liveParent"
									class="badge_beta align-self-center mr-2">Live-to-VOD
								</b-badge>
								{{
									streamq.name.length > 20
									? streamq.name.substring(0, 20 - 3) + "..."
									: streamq.name
								}}
								</p>
							</div>
						</div>
					</b-form-checkbox-group>
				</div>
				<b-row class="mt-3">
					<b-col cols="12" lg="6">
						<p class="text-s-s text-dark-6 mb-0">The moderator will have access to <span class="text-dark-8 highlighted-text">{{ hasAppSubscription ? selected.length : 'all' }} stream(s)</span> and <span class="text-dark-8 highlighted-text">{{ hasVodAppSubscription ? (selectedVods.length + ' folder(s).') : 'all folders.' }}</span></p>
						<p v-if="!hasAppSubscription" class="text-s-s text-primary"><a class="" target="_blank" href="https://castr.com/app/subscribe?category=live">Upgrade to Ultra plan to select streams and VOD folders.</a></p>
					</b-col>
					<b-col cols="12" lg="6" class="text-lg-right mt-3 mt-lg-0">
						<b-button variant="outline-secondary" @click="onDismiss" class="mr-2" size="md">
							{{ cancelText }}
						</b-button>
						<span id="update-access-button">
							<b-button :disabled="disableErrorMsg.length" v-if="showConfirm" :variant="modalType" size="md" @click="onConfirm">
								{{ this.invitationData.action === 'update' ? 'Update Access' : 'Send Invite'}}
							</b-button>
						</span>
						<b-tooltip v-if="disableErrorMsg.length" target="update-access-button">
							{{disableErrorMsg}}
						</b-tooltip>
					</b-col>
				</b-row>
			</div>
			
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StreamCardView from '@/components/ui/StreamCardView.vue'
import UserService from "@/services/UserService";
import Spinner from "@/components/ui/Spinner.vue"

export default {
	name: 'ChooseStreamForModeratorModal',
	components: {
		StreamCardView,
		Spinner
	},
	props: {
		modalId: {
			type: String,
			default: 'modal-confirm'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		modalType: {
			type: String,
			default: 'primary'
		},
		message: {
			type: String,
			required: false
		},
		body: {
			type: String,
			required: false
		},
		streamCat: {
			type: String
		},
		okText: {
			type: String,
			default: 'Yes'
		},
		cancelText: {
			type: String,
			default: 'No'
		},
		showConfirm: {
			type: Boolean,
			default: true,
			required: false
		},
		closeoutsideClick: {
			type: Boolean,
			default: false
		},
		confirmCancelEvent: {
			type: Boolean,
			default: true
		},
		teamId: {
			type: String,
			default: ''
		},
		invitationData: {
			type: Object,
			default: () => ({})
		},
		member: {
			type: Object,
			default: () => ({})
		},
	},
	mounted () {
		if(!this.isAioAllowed) {
			this.selectedTab = 'vod';
		}
	},
	data() {
		return {
			selectedTab: 'live',
			loading: true,
			processing: false,
			streams: [],
			selected: [],
			selectedVods: [],
			appSubscriptions: [
				"5c6e7408b408c1b41dd32f4b",
				"5f51edf6945c4776daf32b4b",
				"602cc85e03afd67aa9831cef",
				"602cc8a503afd67aa9831cf0",
				"632d504580222842f411e158",
				"6361ecd609950a6a642540bc",
				"6361ecd609950a6a642540bd",
				"5bc86f0519bb1bf6acd7b0c1",
				"5bc86f0519bb1bf6acd7b0c4",
				"5bc86f0519bb1bf6acd7b0c5",
				"5bc86f0519bb1bf6acd7b0c8",
				"5bc86f0519bb1bf6acd7b0c9",
				"5bc86f0519bb1bf6acd7b0cb",
				"5bc86f0519bb1bf6acd7b0cc",
				"5bc86f0519bb1bf6acd7b0cd",
				"5bc86f0519bb1bf6acd7b0cf",
				"5bc86f0519bb1bf6acd7b0d0",
				"5bc86f0519bb1bf6acd7b0d1",
				"5c6e7406b408c1b41dd32f49",
				"5c6e7407b408c1b41dd32f4a",
				"5c6e7408b408c1b41dd32f4b",
				"5d03972956a0e43c9eb21e8d",
				"5d2439aed7948c7bf58cf338",
				"5d3f4d83d0a3536a940b9894",
				"5e8f4515db1d5264d2fb21d9",
				"5f3386a869994a42e9d99412",
				"5f51edf6945c4776daf32b4b",
				"5fb6032103afd67aa9831ca9",
				"5ffbefe503afd67aa9831cc1",
				"6026109403afd67aa9831cea",
				"602cc81503afd67aa9831cee",
				"602cc8de03afd67aa9831cf1",
				"6039b30403afd67aa9831d11",
				"6041939f03afd67aa9831d1a",
				"610367bb897c55166875bd28",
				"61ee1d372c50f931fd216ba8",
				"621340722c50f931fd216bb5",
				"623d3e7786ea94257d0d8a33",
				"628672b168012c08db494aaf",
				"6361ecd609950a6a642540bb",
				"6361ecd609950a6a642540be",
				"6435044340771d569f1e98fa",
				"643502dd40771d569f1e98f0",
				"65106e92fc0bcbc1cd269481"
			]
		};
	},
	computed: {
		...mapGetters({
			allInOneStreams: "Streams/allInOneStreams",
			subscriptions: "User/subscriptions",
			vodStreams: "Streams/vodStreams",
		}),
		isAioAllowed() {
			if(!this.member && !this.invitationData) return false;
			const permissions = this.member?.permissions || this.invitationData?.defaultPermissions;		
			if(!permissions) return false;	
			return permissions.liveStreamView || permissions.liveStreamDelete || permissions.liveStreamManage || permissions.liveStreamToggle;			
		},
		isVodAllowed() {
			if(!this.member && !this.invitationData) return false;
			const permissions = this.member?.permissions || this.invitationData?.defaultPermissions;
			if(!permissions) return false;			
			return permissions.hostingManage || permissions.hostingDelete;
		},
		disableErrorMsg() {
			if(!this.member && !this.invitationData) return 'Loading...';
			const permissions = this.member?.permissions || this.invitationData?.defaultPermissions;			
		
			if(!permissions) return 'Loading...';
			const hasAio = permissions.liveStreamDelete || permissions.liveStreamManage || permissions.liveStreamToggle;
			const hasVod = permissions.hostingManage || permissions.hostingDelete;
			let error = "";
			if(hasAio && !this.selected.length && this.allInOneStreams.length) error = "At least 1 Livestream needs to be selected."
			if(hasVod && !this.selectedVods.length && this.vodStreams.length) {
				if(error) {
					error = "At least 1 Livestream & 1 VOD Folder needs to be selected."
				} else {
					error = "At least 1 VOD Folder needs to be selected."
				}
			}
			return error;
		},
		hasAppSubscription() {
			let teamSpecificStreamSharing = false
			const subscriptionsIds = this.subscriptions.map((item) => {
				if (item?.definitionOverride?.teamSpecificStreamSharing) teamSpecificStreamSharing = true
				return item.package._id
			})

			const whiteListPlans = [
				'643502dd40771d569f1e98f0', // Ultra Monthly
				'6435044340771d569f1e98fa', // Ultra Yearly
			]
			const found = whiteListPlans.some(item=> subscriptionsIds.indexOf(item) >= 0)
			return found || teamSpecificStreamSharing
		},
		hasVodAppSubscription() {
			let teamSpecificVodSharing = false
			const subscriptionsIds = this.subscriptions.map((item) => {
				if (item?.definitionOverride?.teamSpecificVodSharing) teamSpecificVodSharing = true
				return item.package._id
			})

			const whiteListPlans = [
				'643502dd40771d569f1e98f0', // Ultra Monthly
				'6435044340771d569f1e98fa', // Ultra Yearly
			]
			const found = whiteListPlans.some(item=> subscriptionsIds.indexOf(item) >= 0)
			return found || teamSpecificVodSharing
		}
	},
	watch: {
		allInOneStreams() {
			this.streams = this.allInOneStreams.map((x) => x)
		},
		isAioAllowed(newState) {
			if((!this.member && !this.invitationData)) return;
			
			if(!newState) {
				this.onSelectTab('vod');
				this.selected = [];
			} else {
				this.onSelectTab('live');
			}
		},
		isVodAllowed(newState) {
			console.log('isVodAllowed', { newState, data: (this.member || this.invitationData) });
			if(!newState && (this.member || this.invitationData)) {
				this.onSelectTab('live');
				this.selectedVods = [];
			}
		},
	},
	methods: {
		onSelectTab(tab) {
			if(tab === 'live' && !this.isAioAllowed) return;
			if(tab === 'vod' && !this.isVodAllowed) return;
			this.selectedTab = tab;
		},
		async onConfirm() {
			if(this.invitationData.action === 'update') {
				const newPermissions = {...this.invitationData.defaultPermissions}
				newPermissions.liveStreamAccessIds = this.selected
				newPermissions.vodStreamAccessIds = this.selectedVods;
				if (this.member && (this.member.status == 'pending')) {
					try {
						await UserService.updateInvitedpermission(this.member._id, newPermissions);
						this.$notify({ group: 'success', text: `${this.member?.name}'s permissions were successfully updated` });
						this.$refs['modalChooseStreamForModerator'].hide();
						window.location.reload();
					} catch(error) {
							console.log(error);
							this.$notify({
								group: "error",
								text: error,
							});
					}
				} else {
						try {
							await UserService.updateMemberpermission(this.teamId, this.member._id, newPermissions);
							this.$notify({ group: 'success', text: `${this.member?.name}'s permissions were successfully updated` });
							this.$refs['modalChooseStreamForModerator'].hide();
							window.location.reload();
						} catch(error) {
							console.log(error);
							this.$notify({
								group: "error",
								text: error,
							});
						}
				}
			} else {
				// const newPermissions = 
				this.invitationData.defaultPermissions.liveStreamAccessIds = this.selected
				this.invitationData.defaultPermissions.vodStreamAccessIds = this.selectedVods
				try {
					const adminInvite = await UserService.inviteTeamadmin(
						this.teamId,
						this.invitationData.admin.name,
						this.invitationData.admin.email,
						this.invitationData.defaultPermissions
					);
					if (adminInvite.success) {
						this.$notify({
							group: "success",
							text: `You sent an invitation to ${this.invitationData.admin.name}`,
						});
					}
					this.$refs['modalChooseStreamForModerator'].hide();
					window.location.reload();
				} catch (err) {
					console.log(err);
					this.$notify({
						group: "error",
						text: err,
					});
				}
			}
		},
		toggleAll() {
			this.selected = this.selected.length < this.streams.length ? this.streams.map(item => item._id) : []
		},
		toggleAllVods() {
			this.selectedVods = this.selectedVods.length < this.vodStreams.length ? this.vodStreams.map(item => item._id) : [];
		},
		onDismiss() {
			this.$refs['modalChooseStreamForModerator'].hide();
		},
		clearData() {
			this.loading = true
			this.selected = []
			this.selectedVods = [];
			this.$emit('on-cancel');
		},
		async checkStreamList() {
			await this.$store.dispatch('Streams/getStreams')
			if (this.hasAppSubscription) {
				if ( this.invitationData.action === 'update' && this.invitationData.defaultPermissions.liveStreamAccessIds ) {
					if(this.isAioAllowed) this.selected = this.invitationData.defaultPermissions.liveStreamAccessIds
				}
			} else {
				if(this.isAioAllowed) this.selected = this.streams.map(item => item._id)
			}
			if (this.hasVodAppSubscription) {
				if ( this.invitationData.action === 'update' && this.invitationData.defaultPermissions.vodStreamAccessIds ) {
					if(this.isVodAllowed) this.selectedVods = this.invitationData.defaultPermissions.vodStreamAccessIds
				}
			} else {
				if(this.isVodAllowed) this.selectedVods = this.vodStreams.map(item => item._id)
			}
			this.loading = false
		}
	},
};
</script>

<style scoped>
.list-wrapper {
	max-height: 13rem;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 1rem -1.5rem;
	padding: 0 1.5rem;
}

:deep(.list-wrapper .castr-card) {
	margin-bottom: 0;
}

:deep(.list-wrapper .col-12:has(input:checked)) {
	background-color: var(--c-dark-3);
}
:deep(.list-wrapper .info) {
	cursor: default;
}
@media (min-width: 576px) {
	:deep(* .modal-dialog.modal-md) {
		max-width: 550px;
	}
}
.tab-area {
	display: flex;
	font-size: var(--font-s-s);
	border-bottom: 1px solid #404B72;
}

.tab-area a:hover {
	cursor: pointer;
	border-color: transparent;
	color: var(--surface-8);
}

.tab-area a {
	text-decoration: none;
	padding: 8px;
	padding-left: 16px;
	padding-right: 16px;
	font-size: var(--font-s-l);
}

.tab-area a.tab-selected {
	text-decoration: none;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-color: var(--surface-8);
}

.highlighted-text {
	color: #FAFAFB;
	font-weight: 500;
}
.tab-disabled {
	background-color: var(--c-dark-4);
	color: var(--c-dark-7);
	cursor: default;
}
.tab-disabled:hover {
	cursor: default !important;
	color: var(--c-dark-7);
}
</style>

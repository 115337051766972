<template>
	<div class="">
		<!-- Modal Component -->
		<b-modal
			id="modal-dvr-archive-video-preview"
			ref="modalDvrArchivePreviewVideo"
			hide-header
			@show="initSlider"
			hide-footer
			size="lg"
			centered
		>
			<div class="p-md-4">
				<b-row>
					<b-col cols="12" md="7">
						<!-- iframe widget -->
						<div class="placeholder-wrapper">
							<code>Processing playback ..</code>
						</div>
						<iframe
							:src="videoUrl"
							class="embed"
							frameborder="0"
							width="550"
							height="300"
							allow="autoplay"
							scrolling="no"
							allowfullscreen
							webkitallowfullscreen
							mozallowfullscreen
							oallowfullscreen
							msallowfullscreen
						/>
						<TimeLine 
							:position="[rangeStartComp, rangeEndComp]" 
							:height="20" 
							:timeLine="timeline" 
							@range="onTimeLineRangeChanged"
							v-if="!slider !== '1'"
						/>
					</b-col>
					<b-col class="d-flex flex-column justify-content-between" v-if="active">
						<b-list-group class="list-group-flush">
							<b-list-group-item class="p-2 text-truncate" v-if="!video.dvr">
								<b-row>
									<b-col md="5" class="text-s-m text-dark-7">
										Video Name
									</b-col>
									<b-col class="text-s-m text-truncate text-right">
										{{ video.fileName }}
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2 text-truncate" v-if="this.stream.type === 'scheduled'">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										File Size
									</b-col>
									<b-col class="text-s-m text-truncate text-right">
										{{ bytesFormated(video.bytes) }}
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="this.stream.type === 'scheduled'">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										Duration
									</b-col>
									<b-col class="text-s-m text-right" v-html="(humanTime(video.mediaInfo.duration))">
										
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="this.stream.type === 'scheduled'">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										Media Source
									</b-col>
									<b-col class="text-s-m text-right">
										
											<span
											v-if="video.mediaInfo"
										>{{ video.mediaInfo.width }} x {{ video.mediaInfo.height }}, </span>
										<span
											v-if="video.mediaInfo"
										>{{ video.mediaInfo.fps }} FPS</span>
										
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="this.stream.type === 'scheduled'">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										Codecs
									</b-col>
									<b-col class="text-s-m text-right">
										<b-badge
											v-for="(codec, index) in video.mediaInfo.codecs.filter(({ type}) => type !== 'general')"
											:key="index"
											:variant="codec.type"
											class="mx-1 text-white text-500"
										>{{ codec.codec }}</b-badge>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="video.dvr">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										Duration
									</b-col>
									<b-col class="text-s-m text-right" v-html="(humanTime(video.duration))">
										
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2" v-if="video.id && this.stream.type != 'scheduled' ">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										Video Id
									</b-col>
									<b-col class="text-s-m text-right">
										{{ video.id }}
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2">
								<b-row>
									<b-col cols="5" class="text-s-m text-dark-7">
										<span v-if="this.stream.type != 'scheduled'">
											Created Date
										</span>
										<span v-if="this.stream.type === 'scheduled'">
											Uploaded Date
										</span>
									</b-col>
									<b-col class="text-s-m text-right">
										{{ dateFormated(video.creationTime) }}
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item class="p-2">
								<b-row>
									<b-col class="text-dark-7">
										<drop-down-menu type="secondary" position="right" class="justify-center w-full flex"
										fullWidth ref="audioTrackDropdown">
											<template #toggle-button>
												<div class="w-full flex justify-between items-center">
													<span class="text-left">{{ video.audioTrack.name }}</span>
													<div class="flex justify-end">
														<icon-base>
															<icon-chevron-down />
														</icon-base>
													</div>
												</div>
											</template>
											<template #menu-items>
												<drop-down-menu-item 
													v-for="(item, index) in audioTracks" :key="index" @click="selectAudioTrack(item)">
													{{ item }}
												</drop-down-menu-item>
											</template>
										</drop-down-menu>
									</b-col>
								</b-row>
							</b-list-group-item>
							<!-- <b-list-group-item class="p-2" v-else>
								<b-row>
									<b-col class="text-s-m">
									
									</b-col>
								</b-row>
							</b-list-group-item> -->
						</b-list-group>
						<b-row v-if="this.stream.type != 'scheduled' " class="mt-auto">
							<b-col class="text-s-m">
								<b-alert show variant="secondary" class="text-dark-7 text-s-s d-flex p-2 mb-0"> 
									<svg data-v-3503005c="" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2 mt-1"><path data-v-3503005c="" fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="#D1DBF0"></path></svg>
									<p class="mb-0">Move the green anchors to select a range.</p>
								</b-alert>
							</b-col>
						</b-row>
						<b-row v-if="this.stream.type != 'scheduled' " class="p-2">
							<b-col cols="5" class="text-s-m text-dark-7">
								<p class="mb-0">Selected range:</p>
							</b-col>
							<b-col class="text-s-m text-dark-7  text-right">
								<span v-html="humanTimeRange(rangeStart)"></span>
									-
								<span v-html="humanTimeRange(rangeEnd)"></span>
							</b-col>
						</b-row>
						<b-row v-if="this.stream.type != 'scheduled' " class="p-2">
							<b-col cols="12" class="mb-2">
								<div class="d-flex w-100 gap-2">
									<b-button @click="onDownloadCustomRange" class="justify-content-center flex-grow-1" size="md" variant="outline-secondary" style="width: 50%">
										<svg class="mr-2" width="16" height="15" viewBox="0 0 16 15" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd"
												d="M11.9247 5.92417C12.159 5.68985 12.159 5.30995 11.9247 5.07564C11.6903 4.84132 11.3104 4.84132 11.0761 5.07564L8.60039 7.55137V0.999902C8.60039 0.668532 8.33176 0.399902 8.00039 0.399902C7.66902 0.399902 7.40039 0.668532 7.40039 0.999902V7.55137L4.92465 5.07564C4.69034 4.84132 4.31044 4.84132 4.07613 5.07564C3.84181 5.30995 3.84181 5.68985 4.07613 5.92417L7.57613 9.42417C7.81044 9.65848 8.19034 9.65848 8.42465 9.42417L11.9247 5.92417ZM0.900391 10.2999L0.900391 10.325C0.900384 10.8637 0.900378 11.3043 0.929632 11.6624C0.959912 12.033 1.0245 12.3677 1.18377 12.6803C1.43304 13.1695 1.83079 13.5672 2.32002 13.8165C2.63261 13.9758 2.96733 14.0404 3.33794 14.0707C3.69598 14.0999 4.13655 14.0999 4.67524 14.0999H4.67526H4.70039H11.3004H11.3255H11.3255C11.8642 14.0999 12.3048 14.0999 12.6628 14.0707C13.0335 14.0404 13.3682 13.9758 13.6808 13.8165C14.17 13.5672 14.5677 13.1695 14.817 12.6803C14.9763 12.3677 15.0409 12.033 15.0711 11.6624C15.1004 11.3043 15.1004 10.8638 15.1004 10.3251V10.325V10.2999V9.4999C15.1004 9.16853 14.8318 8.8999 14.5004 8.8999C14.169 8.8999 13.9004 9.16853 13.9004 9.4999V10.2999C13.9004 10.8699 13.8999 11.2612 13.8751 11.5646C13.8509 11.861 13.8065 12.0203 13.7478 12.1355C13.6136 12.3989 13.3994 12.6131 13.136 12.7473C13.0207 12.806 12.8615 12.8504 12.5651 12.8746C12.2617 12.8994 11.8703 12.8999 11.3004 12.8999H4.70039C4.13044 12.8999 3.73906 12.8994 3.43565 12.8746C3.13925 12.8504 2.98004 12.806 2.8648 12.7473C2.60138 12.6131 2.3872 12.3989 2.25298 12.1355C2.19427 12.0203 2.14986 11.861 2.12565 11.5646C2.10086 11.2612 2.10039 10.8699 2.10039 10.2999V9.4999C2.10039 9.16853 1.83176 8.8999 1.50039 8.8999C1.16902 8.8999 0.900391 9.16853 0.900391 9.4999V10.2999Z"
												fill="currentColor" />
										</svg>
										Video
									</b-button>
									<b-button @click="onDownloadCustomRange" class="justify-content-center flex-grow-1" size="md" variant="outline-secondary" style="width: 50%">
										<svg class="mr-2" width="16" height="15" viewBox="0 0 16 15" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd"
												d="M11.9247 5.92417C12.159 5.68985 12.159 5.30995 11.9247 5.07564C11.6903 4.84132 11.3104 4.84132 11.0761 5.07564L8.60039 7.55137V0.999902C8.60039 0.668532 8.33176 0.399902 8.00039 0.399902C7.66902 0.399902 7.40039 0.668532 7.40039 0.999902V7.55137L4.92465 5.07564C4.69034 4.84132 4.31044 4.84132 4.07613 5.07564C3.84181 5.30995 3.84181 5.68985 4.07613 5.92417L7.57613 9.42417C7.81044 9.65848 8.19034 9.65848 8.42465 9.42417L11.9247 5.92417ZM0.900391 10.2999L0.900391 10.325C0.900384 10.8637 0.900378 11.3043 0.929632 11.6624C0.959912 12.033 1.0245 12.3677 1.18377 12.6803C1.43304 13.1695 1.83079 13.5672 2.32002 13.8165C2.63261 13.9758 2.96733 14.0404 3.33794 14.0707C3.69598 14.0999 4.13655 14.0999 4.67524 14.0999H4.67526H4.70039H11.3004H11.3255H11.3255C11.8642 14.0999 12.3048 14.0999 12.6628 14.0707C13.0335 14.0404 13.3682 13.9758 13.6808 13.8165C14.17 13.5672 14.5677 13.1695 14.817 12.6803C14.9763 12.3677 15.0409 12.033 15.0711 11.6624C15.1004 11.3043 15.1004 10.8638 15.1004 10.3251V10.325V10.2999V9.4999C15.1004 9.16853 14.8318 8.8999 14.5004 8.8999C14.169 8.8999 13.9004 9.16853 13.9004 9.4999V10.2999C13.9004 10.8699 13.8999 11.2612 13.8751 11.5646C13.8509 11.861 13.8065 12.0203 13.7478 12.1355C13.6136 12.3989 13.3994 12.6131 13.136 12.7473C13.0207 12.806 12.8615 12.8504 12.5651 12.8746C12.2617 12.8994 11.8703 12.8999 11.3004 12.8999H4.70039C4.13044 12.8999 3.73906 12.8994 3.43565 12.8746C3.13925 12.8504 2.98004 12.806 2.8648 12.7473C2.60138 12.6131 2.3872 12.3989 2.25298 12.1355C2.19427 12.0203 2.14986 11.861 2.12565 11.5646C2.10086 11.2612 2.10039 10.8699 2.10039 10.2999V9.4999C2.10039 9.16853 1.83176 8.8999 1.50039 8.8999C1.16902 8.8999 0.900391 9.16853 0.900391 9.4999V10.2999Z"
												fill="currentColor" />
										</svg>
										Audio only
									</b-button>
								</div>
							</b-col>
							<b-col cols="12">
								<b-button class="flex-grow-1" variant="primary" size="md" @click="onExportCustomRange" style="width: 100%">
									<span v-if="stream.type === 'live'">Save as VOD</span>
									<span v-else>Download range</span>
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { bytes, date } from '@/filtersNew'
import prettyMilliseconds from 'pretty-ms';
import TimeLine from '../../views/Highlights/TimeLine.vue';
import IconBase from '@/components/icon/IconBase.vue'
import IconChevronDown from '@/components/icon/IconChevronDown.vue';
import DropDownMenu from '@/components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '@/components/Atoms/DropDownMenuItem.vue';

export default {
	name: 'DvrArchiveVideoPreviewModal',
	components: {
		TimeLine,
		IconBase,
		IconChevronDown,
		DropDownMenu,
    DropDownMenuItem,
	},
	props: {
		video: {
			type: Object,
			required: true
		},
		stream: {
				type: Object,
				required: true
		}
	},
	data() {
		return {
			processing: false,
			active: false,
			rangeStart: 0,
			rangeEnd: 0,
			slider: 0,
			timeline: {
				start: 0,
				end: 0,
			},
			videoUrl: '',
			duration: 0,
		};
	},
	mounted() {
		this.setupUrl()
		this.$refs.modalDvrArchivePreviewVideo.$on('shown', this.onInit);
		this.$refs.modalDvrArchivePreviewVideo.$on('hide', this.onDismiss);
		window.addEventListener('message', this.receiveMessage);
	},
	computed: {
		rangeStartComp() {
			return this.rangeStart * 1000; 
		},
		rangeEndComp() {
			return this.rangeEnd * 1000; 
		},
		audioTracks() {
			const tracks = [
				'Audio track 1',
				'Audio track 2',
				'Audio track 3',
				'Audio track 4'
			]
			if (this.video.audioTrack?.name) {
				const filteredTracks = tracks.filter(track => track !== this.video.audioTrack.name);
				return [this.video.audioTrack.name, ...filteredTracks];
			}
			return tracks
		}
	},
	methods: {
		onTimeLineRangeChanged(args) {
			this.rangeStart = Math.round(args[0] / 1000);
			this.rangeEnd = Math.round(args[1] / 1000);
			this.duration = this.rangeEnd - this.rangeStart;
			this.setupUrl();
		},
		setupUrl() {
			this.videoUrl = this.getIframeUrl();
		},
		receiveMessage (event) {
			if (event.data) {
				try {
					const data = JSON.parse(event.data);
					if((data.start || data.start === 0) && data.end) {
						this.rangeStart = Math.round(data.start);
						this.rangeEnd = Math.round(data.end);
					}
				} catch(err) {}
			}
		},
		onExportCustomRange() {
			if (this.rangeEnd === 0) {
				this.rangeEnd = this.video.duration
			}

			const customRange = {
				from: this.rangeStart,
				duration: this.rangeEnd - this.rangeStart
			}

			// if (this.video.audioTrack) {
			// 	switch (this.video.audioTrack.name) {
			// 		case 'Audio track 1':
			// 			this.video.filterTrack = 'v1a1'
			// 			break;
			// 		case 'Audio track 2':
			// 			this.video.filterTrack = 'v1a2'
			// 			break;
			// 		case 'Audio track 3':
			// 			this.video.filterTrack = 'v1a3'
			// 			break;
			// 		case 'Audio track 4':
			// 			this.video.filterTrack = 'v1a4'
			// 			break;
			// 		default:
			// 			break;
			// 	}
			// }

			if (this.stream.type === 'restream') {
				this.$emit('request-dvr-archive-export-custom-range', customRange);
				this.dismiss();
				return
			}

			this.video.customRange = customRange;
			this.$emit('request-dvr-archive-export-custom-range', this.video);
			this.dismiss();
		},
		onDownloadCustomRange() {
			try {
				if (this.rangeEnd === 0) {
					this.rangeEnd = this.video.duration
				}
	
				const customRange = {
					from: this.rangeStart,
					duration: this.rangeEnd - this.rangeStart
				}

				if (this.video.audioTrack) {
					switch (this.video.audioTrack.name) {
						case 'Audio track 1':
							this.video.filterTrack = 'v1a1'
							break;
						case 'Audio track 2':
							this.video.filterTrack = 'v1a2'
							break;
						case 'Audio track 3':
							this.video.filterTrack = 'v1a3'
							break;
						case 'Audio track 4':
							this.video.filterTrack = 'v1a4'
							break;
						default:
							break;
					}
					if (this.video.audioTrack.audioOnly) {
						this.video.filterTrack = this.video.filterTrack.replace('v1', '');
					}
				}
	
				this.video.downloadUrl = this.video.downloadUrl.replace(
					".castr.io",
					".global.ssl.fastly.net"
				);
	
				const splitDnUrl = this.video.downloadUrl.split("archive-");
				let downloadUrl = `${splitDnUrl[0]}archive-${customRange.from}-${customRange.duration}.mp4?packing=compat`;

				if (this.video.filterTrack) downloadUrl += `&filter.tracks=${this.video.filterTrack}`
				
				window.location.href = downloadUrl;
				this.dismiss();
			} catch(e) {
				console.log('Error downloading selected region of live-to-vod', e);
			}
		},
		onInit() {
			this.active = true;
		},
		onDismiss() {
			this.active = false;
			this.rangeStart = 0;
			this.rangeEnd = 0;
		},
		onConfirm() {
			this.$emit('modal-confirm');
			this.dismiss();
		},
		dismiss() {
			this.$refs.modalDvrArchivePreviewVideo.hide();
			// lazy clear
			setTimeout(() => {
				this.processing = false;
			}, 1000);
		},
		initSlider() {
			this.rangeStart = this.video.from;
			this.rangeEnd = this.video.from + this.video.duration;
		},
		getIframeUrl() {
			this.timeline.start = this.video.from * 1000;
			this.timeline.end = (this.video.from + this.video.duration) * 1000;

			const duration = this.duration || this.video.duration;
			const from = this.rangeStart || this.video.from;
			if(!this.rangeEnd) {
				this.rangeStart = this.video.from;
				this.rangeEnd = this.video.from + this.video.duration;
			}
			const srcServer = this.video.server;
			const range = `${from}-${duration}`;
			const prefix = this.video.abr ? 'edge-en' : 'edge';
			const key = this.video.abr ? `ngrp:${this.stream.key}_all` : this.stream.key;

    		let url = `https://${srcServer}/archives/${prefix}/${key}/archive-${range}.m3u8`;
			if (this.stream.type === 'restream') {
    			url = `https://${srcServer}/store/${key}/archive-${range}.m3u8`;
			}
			if (this.stream.type === 'scheduled') {
				if (this.video.type === 'live-to-vod') {
					url = `https://vod-archives.castr.com/${this.video.id}/index.m3u8`;
				} else {
					url = `https://castr-preview.global.ssl.fastly.net/recorded-preview/${this.stream._id}/${this.video.id}/index.m3u8`;
				}
				this.slider = '1';
			}

			if (this.video.namedHls) {
    			url = `https://${srcServer}/${this.video.episodeId}/archive-${range}.m3u8`;
			}

			if (this.video.audioTrack) {
					switch (this.video.audioTrack.name) {
						case 'Audio track 1':
							url += '?filter.tracks=v1a1'
							break;
						case 'Audio track 2':
							url += '?filter.tracks=v1a2'
							break;
						case 'Audio track 3':
							url += '?filter.tracks=v1a3'
							break;
						case 'Audio track 4':
							url += '?filter.tracks=v1a4'
							break;
						default:
							break;
					}
					if (this.video.audioTrack.audioOnly) {
						url = url.replace('v1', '');
					}
				}

			const appPath = process.env.NODE_ENV === 'production' ? '/app/' : '/';
			return `${appPath}static/player/index.html?url=${url}&slider=1&autoplay=true`;
		},
		humanTimeRange(duration) {
			return moment.utc(moment.duration(duration,"s").asMilliseconds()).format("HH:mm:ss")
		},
		humanTime(duration) {
			return prettyMilliseconds(duration * 1000, {unitCount: 2})
		},
		bytesFormated(item) {
			return bytes(item)
		},
		dateFormated(item) {
			return date(item)
		},
		selectAudioTrack(trackName) {
			if (this.video.audioTrack) {
				this.video.audioTrack.name = trackName;
			} else {
				this.video.audioTrack = { name: trackName };
			}
			this.$refs.audioTrackDropdown.toggleDropdown();
		},
	},
	beforeDestroy() {
		window.removeEventListener('message', this.receiveMessage)
	},
	watch: {
		video: {
			deep: true,
			handler: function(_newValue, _oldValue) {
				this.setupUrl()
			}
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.placeholder-wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  z-index:0;
}
iframe.embed {
  max-width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: -7px;
  position: relative;
  z-index:1;
}
.btn-icon {
	top: -15px;
	right: -10px;
}
.list-group-item {
	text-overflow: ellipsis;
	/* overflow: hidden; */
}
@media (max-width: 767px) {
  iframe.embed {
		height: 186px;
	}
}
</style>

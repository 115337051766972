<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import FormButton from './FormButton.vue';
import { createPopper } from '@popperjs/core'
import { useRoute } from 'vue-router'

const isVisible = ref(false)

const route = useRoute()

const props = defineProps({
  type: {
    type: String,
    default: 'main',
  },
  size: {
    type: String,
    default: 'md',
  },
  isIcon: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
  submenu: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  position: {
    type: String,
    default: 'left'
  },
  zIndexVisible: {
    type: String,
    default: 'z-11'
  },
  listClasses: {
    type: String,
    default: ''
  },
  listWidth: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
  },
  wrapperClass: {
    type: String,
  },
  maxHeight: {
    type: String,
  },
  to: {
    type: String,
  },
  customDropdownClass: {
    type: String,
  },
  onClick: Function,
  preventHide: {
    type: Boolean,
    default: false
  }
})

const positionClass = computed(() => {
  if (props.submenu) {
    switch (props.position) {
      case 'left': return 'left-0 w-full top-0'
      case 'mobile-right': return 'right-0 md:left-0'
      case 'mobile-left': return 'left-0 md:right-0'
      case 'right': return 'right-0'
    }
  } else {
    switch (props.position) {
      case 'left': return 'left-0'
      case 'top-left': return 'left-0 bottom-8'
      case 'mobile-right': return 'right-0 md:left-0 md:right-auto'
      case 'mobile-left': return 'left-0 md:right-0'
      case 'right': return 'right-0'
      case 'top-right': return 'right-0 bottom-8'
    }
  }
})
const btnDropdownRef = ref()
const DropdownRef = ref()

const root = ref(null)

const toggleDropdown = () => {
  if (!props.disabled) isVisible.value = !isVisible.value
  if (btnDropdownRef.value && DropdownRef.value) {
    createPopper(btnDropdownRef.value, DropdownRef.value, {
      placement: props.position,
    })
  }
}

defineExpose({ toggleDropdown })

const forceClose = (event) => {
  if (!root.value?.contains(event.target)) {
    isVisible.value = false
  }
}

onMounted(() => {
  window.addEventListener('click', forceClose)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', forceClose)
})

watch(() => route.params, async () => {
  if (!props.preventHide) isVisible.value = false
})

const maxHeightClass = computed(() => props.maxHeight ? `max-h-${props.maxHeight} overflow-y-auto` : 'max-h-[300px] overflow-y-auto')

const classes = computed(() => props.disabled ? 'flex items-center justify-center h-8 w-8 text-surface-8 disabled:text-surface-5 leading-none' : 'flex justify-center items-center h-8 w-8 text-surface-8 hover:text-white leading-none')

const rootComputedClass = computed(() => isVisible.value ? props.zIndexVisible : '' )
</script>

<template>
  <div ref="root" class="dropdown-wrapper relative" :class="rootComputedClass">
    <FormButton :fullWidth="props.fullWidth" :isIcon="isIcon" ref="btnDropdownRef" :disabled="disabled" :type="props.type" :size="size"
      @click="toggleDropdown" :customClasses="props.isIcon ? classes : customClasses">
      {{ label }}
      <slot name="toggle-button" />
    </FormButton>
    <div :class="[isVisible ? 'block' : 'hidden', wrapperClass, listWidth, positionClass, customDropdownClass, { 'w-full': props.fullWidth }]"
      class="absolute bg-surface-2 rounded-md z-30 shadow-dropdown">
      <ul :class="['w-full' ? props.fullWidth : '', maxHeightClass, listClasses]">
        <slot name="menu-items" />
      </ul>
    </div>
  </div>
</template>

<style></style>

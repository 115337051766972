<template>
	<div class="platform-list-section">
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />
		<div v-else-if="!streamPlatforms.length" class="placeholder">
			<div class="flex">
				<div class="w-full md:w-8/12 flex">
					<FormButton size="md" v-if="stream.type === 'restream'"
						v-b-modal.modal-add-platform>
						<icon-base class="mr-2" fill="none">
							<icon-plus />
						</icon-base>
						Add Platform
					</FormButton>
					<drop-down-menu v-if="stream.type === 'scheduled' || stream.type === 'live'" type="main">
						<template #toggle-button>
							<div class="flex text-nowrap items-center">
								<icon-base class="mr-2">
									<icon-plus />
								</icon-base>
								<span>Add Platform</span>
							</div>
							<icon-base width-view-box="10" height-view-box="12" iconColor="none" width="10" height="12"
								class="text-dark-8 ml-2">
								<icon-chevron-up-down />
							</icon-base>
						</template>
						<template #menu-items>
							<drop-down-menu-item v-b-modal.modal-add-platform>
								New Publish Platform
							</drop-down-menu-item>
							<drop-down-menu-item v-b-modal="stream.type === 'scheduled' ? 'modal-add-castr-stream' : 'modal-add-castr-stream-2'">
								Add Stream Within Castr
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
					<DestinationDropdown :platformUsage="platformUsage" @destinations-changed="destinationsChanged"
						v-if="shouldShowDestinationDropdown" :stream="stream" class="ml-md-2" />
					<FormButton size="md" v-if="stream.type === 'subsecond'" v-b-modal.modal-add-castr-stream>
						<icon-base class="mr-2" fill="none">
							<icon-plus />
						</icon-base>
						Add Stream within Castr
					</FormButton>
				</div>
			</div>
			<div class="flex flex-col items-center justify-center" v-if="stream.type !== 'subsecond'">
				<p class="text-xl mt-6 mb-1 text-surface-9">No Platforms yet</p>
				<p class="text-surface-8 text-sm mb-4">Add a stream and platform to get started</p>
				<FormButton type="secondary" class="mb-2" v-b-modal.modal-demo-video>
					<icon-base class="mr-2" fill="none">
						<icon-camera />
					</icon-base>
					{{ stream.type === 'scheduled' ? 'Pre-recorded Stream Demo' : 'Livestreams Demo' }}
				</FormButton>
			</div>
			<div class="text-surface-8 text-sm font-medium mt-5" v-else>
				<p class="mb-2">Recommended Encoder Settings</p>
				<ul class="pl-4 mb-0">
					<li class="mb-2">Bitrate: 2000 kbps</li>
					<li class="mb-2">Keyframe Interval: 2 seconds & Keyframe aligned</li>
					<li class="mb-2">Rate Control: Constant Bit Rate (CBR)</li>
					<li class="mb-2">Tune: Zero Latency</li>
					<li class="mb-2">Profile: Baseline</li>
				</ul>
				<p>If you have questions please reach out via <span class="text-white text-underline cursor-pointer"
						onclick="Intercom('show')">live chat</span></p>
			</div>
		</div>
		<div v-else>
			<div class="flex flex-wrap mb-3 items-center">
				<div class="flex flex-wrap items-center"
					:class="stream.type === 'subsecond' ? 'md:w-4/12' : 'w-full md:w-8/12'">
					<FormButton v-if="stream.type === 'restream'" size="md"
						v-b-modal.modal-add-platform>
						<icon-base class="mr-2" fill="none">
							<icon-plus />
						</icon-base>
						Add Platform
					</FormButton>
					<drop-down-menu v-if="stream.type === 'scheduled' || stream.type === 'live'" type="main">
						<template #toggle-button>
							<div class="flex text-nowrap items-center">
								<icon-base class="mr-2">
									<icon-plus />
								</icon-base>
								<span>Add Platform</span>
							</div>
							<icon-base iconColor="none"
								class="text-dark-8 ml-2">
								<icon-chevron-up-down />
							</icon-base>
						</template>
						<template #menu-items>
							<drop-down-menu-item v-b-modal.modal-add-platform>
								New Publish Platform
							</drop-down-menu-item>
							<drop-down-menu-item v-b-modal="stream.type === 'scheduled' ? 'modal-add-castr-stream' : 'modal-add-castr-stream-2'">
								Add Stream Within Castr
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
					<DestinationDropdown :platformUsage="platformUsage" @destinations-changed="destinationsChanged"
						v-if="shouldShowDestinationDropdown" :stream="stream" class="ml-2" />
					<FormButton size="md" v-if="stream.type === 'subsecond'" v-b-modal.modal-add-castr-stream>
						<icon-base class="mr-2" fill="none">
							<icon-plus />
						</icon-base>
						Add Stream within Castr
					</FormButton>
				</div>
				<div v-if="stream.type === 'subsecond'" class="w-full md:w-4/12">
					<SubSecondRecommendedSettings class="mt-3 mt-md-0" />
				</div>
				<div class="text-right w-full md:w-4/12">
					<div class="inline-block ml-md-4 master-toggle-area">
						<div class="d-inline-flex align-items-center" id="toggle-all-disabled">
							<form-switch label-position="left" label-styles="text-sm text-surface-9"
								@change="toggleGroupStatus(groupToggleState)"
								:disabled="cannotTogglePlatforms || groupToggleProcessing || platformToggleProcessing"
								v-model="groupToggleState">
								Toggle All
							</form-switch>
						</div>
						<b-tooltip target="toggle-all-disabled" placement="top" v-if="cannotTogglePlatforms && memberPermission">
							<span class="text-dark-8 text-small">
								You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
									:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
						</b-tooltip>
					</div>
				</div>
			</div>
			<!-- AIO streams platforms -->
			<h3 v-if="platformCastrLiveFiltered.length > 0" class="text-s-l text-500 text-dark-8 mt-5 mb-0">Streams</h3>
			<div class="platform-list">
				<div v-for="(platform) in platformCastrLiveFiltered" :key="platform._id" :class="stream.type"
					class="flex-row platform-item align-items-stretch py-3 gap-x-4 w-full">
					<div class="platform-item__description">
						<h4 class="text-lg font-medium w-full">{{ platform.streamObject ? platform.streamObject.name : platform.name
							}}
						</h4>
						<div class="flex md:flex-wrap grow-1 items-center justify-between w-full">
							<span class="platform-item__server flex text-dark-9 truncate">
								<img
									:src="getCountryFlag(platform.streamObject.uiRegion ? platform.streamObject.uiRegion.name : platform.streamObject.region.name)"
									class="region-flag mr-2" width="20">
								{{ platform.streamObject.uiRegion ? platform.streamObject.uiRegion.name :
									platform.streamObject.region.name }}
							</span>
							<Spinner v-if="platform.removingProcessing" text="" classes="text-dark-8 my-3"
								spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<div class="mr-0 ml-auto" v-else>
								<div v-if="(platform.enabled && mediaPulse && mediaPulse.push_stats)"
									class="inline-block platform-connect-status-container">
									<div class="status-box flex items-center mr-md-2">
										<div v-if="platform.enabled && platform.connected" class="text-success text-sm flex items-center">
											<icon-base class="mr-2" fill="none">
												<icon-checkmark />
											</icon-base>
											<span class="d-none d-md-inline">Connected</span>
										</div>
										<div v-else class="text-s-s text-dark-8 d-flex relative">
											<Spinner text="" classes="text-dark-8 my-3 absolute -top-8 right-4"
												spinner-color="var(--c-dark-8)" spinner-size="15px" />
										</div>
									</div>
								</div>
							</div>
							<div class="flex items-center" v-bind:id="`${platform.tooltipId}`">
								<ejs-tooltip
									v-if="platform.connected && platform.streamObject.enabled && ((mediaPulse && !mediaPulse.alive) || (mediaPulse && mediaPulse.alive && !platform.enabled))"
									content="Adding an online stream may cause issues. Please add offline ones only to avoid possible issues.">
									<icon-base class="mx-2 text-danger" fill="none">
										<icon-info />
									</icon-base>
								</ejs-tooltip>
								<Badge v-if="platform.connected" type="online" class="mr-2">
									Live
								</Badge>
								<ejs-tooltip v-if="!platform.enabled && !platform.streamObject.enabled"
									content="You can't stream to a disabled stream, please enable this stream first.">
									<icon-base class="mx-2 text-danger" fill="none">
										<icon-info />
									</icon-base>
								</ejs-tooltip>
								<Badge v-if="!platform.connected" type="offline" class="mr-2">
									<span v-if="platform.streamObject.enabled">Offline</span>
									<span v-if="!platform.streamObject.enabled">Disabled</span>
								</Badge>
								<ejs-tooltip v-if="cannotTogglePlatforms" :content="tooltipText">
									<FormSwitch disabled />
								</ejs-tooltip>
								<ejs-tooltip v-else-if="pushStreamFailOverEnabled(platform)"
									content="This stream has back-up enabled. Please disable back-up for this to work.">
									<FormSwitch disabled />
								</ejs-tooltip>
								<FormSwitch :id="'platform-toggle' + platform._id" v-else label-position="left"
									label-styles="text-sm text-surface-9" @change="onTogglePlatformStatus(platform)"
									:disabled="cannotTogglePlatforms || platform.statusProcessing || platform.tooltipId !== undefined || pushStreamFailOverEnabled(platform)"
									v-model="platform.enabled" />

								<b-tooltip v-bind:target="`${platform.tooltipId ? platform._id : ''}`" placement="top"
									triggers="hover focus">
									<span>Infinite loop mode does not support Facebook, please use Schedule Date time mode instead.</span>
								</b-tooltip>
								<drop-down-menu isIcon no-border type="secondary" position="right" :disabled="deleteBlock">
									<template #toggle-button>
										<img src="@/assets/icons/settings.svg" alt="Settings">
									</template>
									<template #menu-items>
										<drop-down-menu-item class="md:hidden"
											v-if="platform.serviceMeta && platform.serviceMetaEditor && (hasMetaProp('title', platform) || hasMetaProp('description', platform) || hasMetaProp('game', platform))"
											:disabled="platform.tooltipId !== undefined" v-b-toggle="platform._id">
											Metadata
										</drop-down-menu-item>
										<drop-down-menu-item @click="requestPlatformDelete(platform)">
											<div class="flex items-center hover:text-error">
												<icon-base class="mr-2" fill="none">
													<icon-bin />
												</icon-base>
												Remove
											</div>
										</drop-down-menu-item>
									</template>
								</drop-down-menu>
							</div>
						</div>
						<div class="flex w-full">
							<span class="platform-item__status mr-2 flex text-tiny" v-if="platform.linkedServiceCreds">
								<icon-base class="text-success mr-1" fill="none">
									<icon-checkmark />
								</icon-base>
								Linked
							</span>
							<a v-if="platform.serviceChannelUrl" :href="platform.serviceChannelUrl" target="_blank"
								class="platform-item__link flex items-center text-tiny">
								Open
								<icon-base class="ml-1" fill="none">
									<icon-link-external />
								</icon-base>
							</a>
						</div>
					</div>
				</div>
			</div>

			<!-- Transcoder streams platforms -->
			<h3 v-if="platformCastrTranscoderFiltered.length > 0" class="text-s-l text-500 text-dark-8 mt-5 mb-0">Transcoders</h3>
			<div class="platform-list">
				<div v-for="(platform) in platformCastrTranscoderFiltered" :key="platform._id" :class="stream.type"
					class="flex-row platform-item align-items-stretch py-3 gap-x-4 w-full">
					<div class="platform-item__description">
						<h4 class="text-lg font-medium w-full">{{ platform.streamObject ? platform.streamObject.name : platform.name
							}}
						</h4>
						<div class="flex md:flex-wrap grow-1 items-center justify-between w-full">
							<span class="platform-item__server flex text-dark-9 truncate">
								<img
									:src="getCountryFlag(platform.streamObject.uiRegion ? platform.streamObject.uiRegion.name : platform.streamObject.region.name)"
									class="region-flag mr-2" width="20">
								{{ platform.streamObject.uiRegion ? platform.streamObject.uiRegion.name :
									platform.streamObject.region.name }}
							</span>
							<Spinner v-if="platform.removingProcessing" text="" classes="text-dark-8 my-3"
								spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<div class="mr-0 ml-auto" v-else>
								<div v-if="(platform.enabled && mediaPulse && mediaPulse.push_stats)"
									class="inline-block platform-connect-status-container">
									<div class="status-box flex items-center mr-md-2">
										<div v-if="platform.enabled && platform.connected" class="text-success text-sm flex items-center">
											<icon-base class="mr-2" fill="none">
												<icon-checkmark />
											</icon-base>
											<span class="d-none d-md-inline">Connected</span>
										</div>
										<div v-else class="text-s-s text-dark-8 d-flex relative">
											<Spinner text="" classes="text-dark-8 my-3 absolute -top-8 right-4"
												spinner-color="var(--c-dark-8)" spinner-size="15px" />
										</div>
									</div>
								</div>
							</div>
							<div class="flex items-center" v-bind:id="`${platform.tooltipId}`">
								<ejs-tooltip
									v-if="platform.connected && platform.streamObject.enabled && ((mediaPulse && !mediaPulse.alive) || (mediaPulse && mediaPulse.alive && !platform.enabled))"
									content="Adding an online stream may cause issues. Please add offline ones only to avoid possible issues.">
									<icon-base class="mx-2 text-danger" fill="none">
										<icon-info />
									</icon-base>
								</ejs-tooltip>
								<Badge v-if="platform.connected" type="online" class="mr-2">
									Live
								</Badge>
								<ejs-tooltip v-if="!platform.enabled && !platform.streamObject.enabled"
									content="You can't stream to a disabled stream, please enable this stream first.">
									<icon-base class="mx-2 text-danger" fill="none">
										<icon-info />
									</icon-base>
								</ejs-tooltip>
								<Badge v-if="!platform.connected" type="offline" class="mr-2">
									<span v-if="platform.streamObject.enabled">Offline</span>
									<span v-if="!platform.streamObject.enabled">Disabled</span>
								</Badge>
								<ejs-tooltip v-if="cannotTogglePlatforms" :content="tooltipText">
									<FormSwitch disabled />
								</ejs-tooltip>
								<ejs-tooltip v-else-if="pushStreamFailOverEnabled(platform)"
									content="This stream has back-up enabled. Please disable back-up for this to work.">
									<FormSwitch disabled />
								</ejs-tooltip>
								<FormSwitch :id="'platform-toggle' + platform._id" v-else label-position="left"
									label-styles="text-sm text-surface-9" @change="onTogglePlatformStatus(platform)"
									:disabled="cannotTogglePlatforms || platform.statusProcessing || platform.tooltipId !== undefined || pushStreamFailOverEnabled(platform)"
									v-model="platform.enabled" />

								<b-tooltip v-bind:target="`${platform.tooltipId ? platform._id : ''}`" placement="top"
									triggers="hover focus">
									<span>Infinite loop mode does not support Facebook, please use Schedule Date time mode instead.</span>
								</b-tooltip>
								<drop-down-menu isIcon no-border type="secondary" position="right" :disabled="deleteBlock">
									<template #toggle-button>
										<img src="@/assets/icons/settings.svg" alt="Settings">
									</template>
									<template #menu-items>
										<drop-down-menu-item class="md:hidden"
											v-if="platform.serviceMeta && platform.serviceMetaEditor && (hasMetaProp('title', platform) || hasMetaProp('description', platform) || hasMetaProp('game', platform))"
											:disabled="platform.tooltipId !== undefined" v-b-toggle="platform._id">
											Metadata
										</drop-down-menu-item>
										<drop-down-menu-item @click="requestPlatformDelete(platform)">
											<div class="flex items-center hover:text-error">
												<icon-base class="mr-2" fill="none">
													<icon-bin />
												</icon-base>
												Remove
											</div>
										</drop-down-menu-item>
									</template>
								</drop-down-menu>
							</div>
						</div>
						<div class="flex w-full">
							<span class="platform-item__status mr-2 flex text-tiny" v-if="platform.linkedServiceCreds">
								<icon-base class="text-success mr-1" fill="none">
									<icon-checkmark />
								</icon-base>
								Linked
							</span>
							<a v-if="platform.serviceChannelUrl" :href="platform.serviceChannelUrl" target="_blank"
								class="platform-item__link flex items-center text-tiny">
								Open
								<icon-base class="ml-1" fill="none">
									<icon-link-external />
								</icon-base>
							</a>
						</div>
					</div>
				</div>
			</div>
			<h3 v-if="stream.type !== 'subsecond'" class="text-lg font-medium text-dark-8 mt-5 mb-0">Platforms</h3>
			<div class="platform-list">
				<div v-for="(platform, index) in platformRegularFiltered" :key="platform._id" :class="stream.type"
					bg-variant="transparent" no-body class="flex flex-row platform-item align-items-stretch py-3">
					<a :href="platform.serviceChannelUrl" target="_blank"
						class="platform-item__icon align-self-center w-24 flex shrink-0 items-center justify-center">
						<icon-base v-if="platform.template === 'custom'" class="" fill="none" width="24px" height="24px"
							widthViewBox="24" heightViewBox="24">
							<icon-settings />
						</icon-base>
						<img v-else-if="platform.icon" :src="platform.icon">
						<i v-else class="fas fa-spinner fa-spin" />
					</a>
					<div
						class="platform-item__description grow px-2 px-md-0 ml-md-3 max-w-[calc(100%-60px)] md:max-w-[calc(100%-7rem)]">
						<Title class="w-full h-8 p-2 -mx-2 flex items-center"
							:class="{ 'hover:bg-surface-3': !platform.reusableDestination }"
							:editable="platform.reusableDestination ? false : true" @edit="selectedPlatform = platform"
							:title="platform.editorName" @changed="onPlatformNameChange" />
						<div class="w-max-full basis-full flex grow-1 items-center justify-between max-w-full">
							<span class="platform-item__server text-tiny truncate text-surface-8 max-w-full grow-0 shrink">
								{{ getServerUrl(platform) }}
							</span>
							<div v-if="platform.removingProcessing">
								<Spinner text=" " classes="text-surface-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="14px" />
							</div>
							<div class="mr-0 ml-auto" v-else>
								<div v-if="(platform.enabled && mediaPulse && mediaPulse.push_stats)"
									class="inline-block platform-connect-status-container">
									<div class="status-box flex items-center mr-md-2">
										<div v-if="platform.enabled && platform.connected"
											class="text-success text-sm inline-flex items-center">
											<icon-base class="text-success mr-1" fill="none">
												<icon-checkmark />
											</icon-base>
											<span class="d-none d-md-inline">Connected</span>
										</div>
										<div v-else class="text-sm text-surface-8 flex relative">
											<span class="d-none d-lg-block absolute -top-8 -right-2">
												<Spinner text="" classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
													spinner-size="15px" />
											</span>
											<span class="d-block d-lg-none">
												<Spinner text="" classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
													spinner-size="15px" />
											</span>
										</div>
									</div>
								</div>
							</div>
							<b-tooltip :target="`${platform.tooltipId || platform._id}`" placement="top"
								v-if="cannotTogglePlatforms && memberPermission">
								<span class="text-dark-8 text-small">
									You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
										:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
							</b-tooltip>
							<div class="flex flex-nowrap items-center" v-bind:id="`${platform.tooltipId || platform._id}`">
								<FormButton size="sm" type="secondary"
									:disabled="platform.tooltipId !== undefined || (!platform.isTokenValid && platform.template === 'youtube events')"
									v-if="platform.serviceMeta && platform.serviceMetaEditor && (hasMetaProp('title', platform) || hasMetaProp('description', platform) || hasMetaProp('game', platform))"
									@click="toggleMetadata(platform._id)">
									{{ isMetadataShown(platform._id) ? 'Hide Metadata' : 'Show Metadata' }}
								</FormButton>
								<FormSwitch label-position="left" label-styles="text-sm text-surface-9" :id="'toggle' + index"
									@change="onTogglePlatformStatus(platform)"
									:disabled="cannotTogglePlatforms || platform.statusProcessing || !platform.isTokenValid || platform.tooltipId !== undefined || (!platform.isTokenValid && platform.template === 'youtube events')"
									v-model="platform.enabled" />
								<b-tooltip v-bind:target="`${platform.tooltipId ? platform._id : ''}`" placement="top"
									triggers="hover focus">
									<span>Infinite loop mode does not support Facebook, please use Schedule Date time mode instead.</span>
								</b-tooltip>
								<drop-down-menu position="right" type="secondary" isIcon no-border :disabled="deleteBlock">
									<template #toggle-button>
										<img src="@/assets/icons/settings.svg" alt="Settings">
									</template>
									<template #menu-items>
										<drop-down-menu-item class="md:hidden" @click="toggleMetadata(platform._id)"
											:disabled="platform.tooltipId !== undefined || (!platform.isTokenValid && platform.template === 'youtube events')"
											v-if="platform.serviceMeta && platform.serviceMetaEditor && (hasMetaProp('title', platform) || hasMetaProp('description', platform) || hasMetaProp('game', platform)) && !(!platform.isTokenValid && platform.template === 'youtube events')"
											v-b-toggle="platform._id">
											Metadata
										</drop-down-menu-item>
										<drop-down-menu-item v-if="!platform.reusableDestination" @click="requestPlatformModify(platform)">
											<div class="flex items-center">
												<icon-base class="mr-2" fill="none">
													<icon-edit />
												</icon-base>
												Edit
											</div>
										</drop-down-menu-item>
										<drop-down-menu-item @click="requestPlatformDelete(platform)">
											<div class="flex items-center hover:text-error">
												<icon-base class="mr-2" fill="none">
													<icon-bin />
												</icon-base>
												Remove
											</div>
										</drop-down-menu-item>
									</template>
								</drop-down-menu>
							</div>
						</div>
						<div class="flex w-full">
							<Badge v-if="platform.reusableDestination">
								<icon-base width="12" height="12" icon-name="link" classIcon=" mr-2">
									<icon-link />
								</icon-base>
								Reusable destinations
							</Badge>
							<span class="platform-item__status mr-2 flex items-center text-tiny"
								v-if="platform.linkedServiceCreds && platform.isTokenValid">
								<icon-base class="text-success mr-1" fill="none">
									<icon-checkmark />
								</icon-base>
								Linked
							</span>
							<!-- when the facebook token has expired -->
							<span
								class="platform-item__status mr-2 platform-item__status--error flex items-center text-error text-tiny flex-wrap"
								v-if="!platform.isTokenValid && platform.template === 'facebook live'">
								<template v-if="!facebookOauthState[platform.fbAccount].isLoading">
									<icon-base class="text-danger" fill="none">
										<icon-info />
									</icon-base>
									The API connection has expired. <a v-if="!facebookOauthState[platform.fbAccount].isLoading"
										class="platform-item__status--error" href=""
										@click.prevent="getFacebookUrl(platform.fbAccount)"><u>Reconnect</u></a>
								</template>
								<!-- show reconnection failure message -->
								<template v-if="facebookOauthState[platform.fbAccount].errorMessage">
									<br>Reconnection failed: {{ facebookOauthState[platform.fbAccount].errorMessage }}
								</template>

								<div v-if="facebookOauthState[platform.fbAccount].isLoading">
									<span class="d-none d-lg-block">
										<Spinner text="Connecting..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
											spinner-size="15px" />
									</span>
									<span class="d-block d-lg-none">
										<Spinner text="" classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
									</span>
								</div>
							</span>

							<span class="platform-item__status mr-2 platform-item__status--error"
								v-if="!platform.isTokenValid && platform.template === 'youtube events'">
								<template v-if="!youtubeOauthState[platform.ytAccount].isLoading">
									<icon-base class="mx-2 text-danger" fill="none">
										<icon-info />
									</icon-base>
									The API connection has expired. Please remove this and re-add the platform.
								</template>
								<!-- show reconnection failure message -->
								<template v-if="youtubeOauthState[platform.ytAccount].errorMessage">
									<br>Reconnection failed: {{ youtubeOauthState[platform.ytAccount].errorMessage }}
								</template>

								<div v-if="youtubeOauthState[platform.ytAccount].isLoading">
									<span class="d-none d-lg-block ">
										<Spinner text="Connecting..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)"
											spinner-size="15px" />
									</span>
									<span class="d-block d-lg-none ">
										<Spinner text="" classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
									</span>
								</div>
							</span>

							<a v-if="platform.serviceChannelUrl && !(!platform.isTokenValid && platform.template === 'youtube events')"
								:href="getYoutubeChannelUrl(platform.serviceChannelUrl)" target="_blank"
								class="platform-item__link flex items-center text-tiny">
								Open
								<icon-base class="ml-1" fill="none">
									<icon-link-external />
								</icon-base>
							</a>
						</div>
						<div
							v-if="platform.serviceMeta && platform.serviceMetaEditor && (hasMetaProp('title', platform) || hasMetaProp('description', platform) || hasMetaProp('game', platform))"
							class="platform-item__meta">
							<CollapseContainer :ref="'collapse' + platform._id">
								<div v-if="platform.config.type === 'userFBtimeline'" class="platform-server">
											It's not supported to edit Title and Description of a stream on your Facebook Timeline. <br>
											Please
											remove this platform and re-add it if you want to edit.
										</div>
										<div v-if="hasMetaProp('title', platform)" class="meta-row">
											<label class="text-s-s">
												Stream title {{ platform.showTitleLimitTracking ? '(' +
													platform.serviceMetaEditor.form.title.length
													+
												'/100)' : '' }}
											</label>
											<FormInput v-model="platform.serviceMetaEditor.form.title" :maxlength="platform.maxLengthTitle"
												:disabled="platform.config.postCreationMetaEditing === false || platform.serviceMetaEditor.formProcessing.title"
												@change="onMetaPropChange('title', platform)" />
											<b-row class="mt-3" v-if="platform.serviceMetaEditor.changes.title">
												<b-col class="flex">
													<FormButton class="mr-2" size="md" :disabled="platform.serviceMetaEditor.formProcessing.title"
														@click="saveMetaProp('title', platform)">
														{{ platform.serviceMetaEditor.formProcessing.title ? 'Saving ..' : 'Save' }}
													</FormButton>
													<FormButton class="mr-2" size="md" type="secondary"
														:disabled="platform.config.type === 'userFBtimeline'"
														@click="requestAllPlatformsUpdate('title', platform)">
														{{ platform.serviceMetaEditor.formProcessing.title ? 'Saving ..' : 'Save for All Platforms*'
														}}
													</FormButton>
												</b-col>
											</b-row>

											<span v-if="platform.serviceMetaEditor.changes.title">
												<label class="platform-item__status mr-2" style="font-size:12px;">*means Facebook, YouTube, and
													Twitch.</label>
											</span>
										</div>

										<div v-if="hasMetaProp('description', platform)" class="w-100">
											<label class="text-s-s mt-3">
												Description {{ platform.showTitleLimitTracking ? '(' +
													platform.serviceMetaEditor.form.description.length + '/5000)' : '' }}
											</label>
											<div>

												<b-textarea v-model="platform.serviceMetaEditor.form.description" size="md" ref="textarea"
													:disabled="platform.config.postCreationMetaEditing === false || platform.serviceMetaEditor.formProcessing.title"
													:maxlength="platform.maxLengthDescription" @change="onMetaPropChange('description', platform)"
													rows="1" />

												<div class="flex mt-3" v-if="platform.serviceMetaEditor.changes.description">
													<FormButton class="mr-2" size="md"
														:disabled="platform.serviceMetaEditor.formProcessing.description"
														@click="saveMetaProp('description', platform)">
														{{ platform.serviceMetaEditor.formProcessing.description ? 'Saving ..' : 'Save' }}
													</FormButton>
													<FormButton class="mr-2" size="md" type="secondary"
														:disabled="platform.config.type === 'userFBtimeline'"
														@click="requestAllPlatformsUpdate('description', platform)">
														{{ platform.serviceMetaEditor.formProcessing.description ? 'Saving ..' : 'Save for All Platforms*'}}
													</FormButton>
												</div>

												<span v-if="platform.serviceMetaEditor.changes.description">
													<label class="platform-item__status mr-2">*means Facebook, YouTube, and
														Twitch.</label>
												</span>
											</div>
										</div>

										<div v-if="platform.template === 'youtube events' || platform.template === 'youtube'" class="w-100 mt-2">
											<label class="text-s-s">
												Privacy Status
											</label>
											<drop-down-menu ref="youtubePrivacyDropdownInList" type="secondary" customClasses="w-full text-sm h-8 bg-surface-3 hover:bg-surface-4 rounded-md flex items-center px-3 capitalize text-tiny"
											customDropdownClass="w-full"
											>
												<template #toggle-button>
													{{ platform.serviceMetaEditor.form.privacyStatus || selectedPrivacyStatus }}
													<icon-base
														class="ml-auto">
														<icon-chevron-down />
													</icon-base>
												</template>
												<template #menu-items>
													<drop-down-menu-item customClasses="max-w-full w-full h-auto flex flex-col text-wrap text-left items-start px-3 py-2 text-tiny hover:bg-surface-4" @click="onPrivacySettingsChanged('public', platform)">
														Public
													</drop-down-menu-item>
													<hr>
													<drop-down-menu-item customClasses="max-w-full w-full h-auto flex flex-col text-wrap text-left items-start px-3 py-2 text-tiny hover:bg-surface-4" @click="onPrivacySettingsChanged('unlisted', platform)">
														Unlisted
													</drop-down-menu-item>
													<hr>
													<drop-down-menu-item customClasses="max-w-full w-full h-auto flex flex-col text-wrap text-left items-start px-3 py-2 text-tiny hover:bg-surface-4" @click="onPrivacySettingsChanged('private', platform)">
														Private
													</drop-down-menu-item>
												</template>
											</drop-down-menu>

											<div>
												<div class="flex mt-3" v-if="platform.serviceMetaEditor.changes.privacyStatus">
													<FormButton class="mr-2" size="md"
														:disabled="platform.serviceMetaEditor.formProcessing.privacyStatus"
														@click="saveMetaProp('privacyStatus', platform)">
														{{ platform.serviceMetaEditor.formProcessing.privacyStatus ? 'Saving ..' : 'Save' }}
													</FormButton>
												</div>
											</div>
										</div>

										<div v-if="hasMetaProp('game', platform)" class="meta-row">
											<label class="text-sm mt-3">
												Category (game)
											</label>
											<div>
												<FormInput v-model="platform.serviceMetaEditor.form.game" class="mb-3"
													@input="onChangeGameName($event, platform, index)" placeholder="Enter a category or game" />

												<!-- <button v-show="canSaveMetadataProp('game', platform)" -->
												<FormButton v-if="platform.serviceMetaEditor.changes.game" class="mr-2" size="md"
													:disabled="platform.serviceMetaEditor.formProcessing.game"
													@click="onSaveTwitchCategory(platform)">
													{{ platform.serviceMetaEditor.formProcessing.game ? 'Saving ..' : 'Save' }}
												</FormButton>
											</div>
										</div>
							</CollapseContainer>
						</div>
					</div>
				</div>
			</div>
		</div>
		<demo-video-modal :videoid="(stream.type == 'scheduled') ? 'GFvZ8ttEszBeIHGAPbDsx' : 'nlAJJ_mROavGyHPRs8ZZw'" />
		<add-platform-modal :stream="stream" :platform-deleted="platformDeleted" :platform-usage-data="platformUsage"
			@platform-saved="onNewPlatform" />
		<add-castr-stream-modal :stream="stream" :platform-castr-filtered="platformCastrFiltered"
			:platform-deleted="platformDeleted" :platform-added="platformAdded" :platform-usage-data="platformUsage"
			@platform-castr-saved="addCastrPlatforms" @platform-saved="onNewPlatform" />
		<add-castr-stream-2-modal :stream="stream" :platform-castr-filtered="platformCastrFiltered"
			:platform-deleted="platformDeleted" :platform-added="platformAdded" :platform-usage-data="platformUsage"
			@platform-castr-saved="addCastrPlatforms" @platform-saved="onNewPlatform" />
		<edit-platform-modal :stream="stream" :platform="configurablePlatform" @platform-updated="onPlatformUpdated" />

		<confirm-modal modal-id="platform-delete-confirm" modal-type="danger"
			message="Would you like to remove selected publish platform"
			@modal-confirm="onPlatformDeleteConfirm(configurablePlatform)" />

		<confirm-modal :message="appNotificationMsg" modal-id="modal-trial-publish-limit" modal-size="md"
			ok-text="Upgrade now" cancel-text="Ok fine" @modal-confirm="navigatePaymentsPage" />
		<confirm-modal message="Warning! Your Facebook stream is live"
			body="Toggle off this right now will end your livestream on Facebook." modal-id="toggle-off-warning"
			modal-size="md" ok-text="Toggle off" cancel-text="Go back" @modal-confirm="onToggleOffWarningConfirmed" />

		<confirm-modal modal-id="all-platforms-update-confirm"
			message="Do you want to save for all platforms? This feature supports Facebook Page/Group, YouTube, and Twitch only."
			cancel-text="Cancel" ok-text="Save for All Platforms"
			@modal-confirm="onAllPlatformsUpdateConfirm(configurableField, configurablePlatform)" />
		<alert-modal :message="this.alertMessage" modal-id="platform-is-linked" button-alignment="text-right"
			ok-text="Got it, thanks" />
	</div>
</template>

<script>
import _ from 'lodash';
import { v4 } from 'uuid';
import { mapGetters } from 'vuex';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import StreamStatsService from '@/services/StreamStatsService';
import IntegrationService from '@/services/IntegrationService';
import DestinationService from "@/services/DestinationService"
import utils from '@/utils';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import AddPlatformModal from '@/components/modals/AddPlatformModal.vue';
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import AddCastrStreamModal from '@/components/modals/AddCastrStreamModal.vue';
import AddCastrStream2Modal from '@/components/modals/AddCastrStream2Modal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import EditPlatformModal from '@/components/modals/ConfigurePlatformModal.vue';
import ResizableTextarea from './ResizableTextarea.vue';
import SubSecondRecommendedSettings from '@/components/SubSecondStream/SubSecondRecommendedSettings.vue'
import DestinationDropdown from "@/components/Destination/DestinationDropdown.vue";
import FormButton from '@/components/Atoms/FormButton.vue';
import FormSwitch from '@/components/Atoms/FormSwitch.vue';
import IconBase from '@/components/icon/IconBase.vue'
import IconPlus from '@/components/icon/IconPlus.vue';
import IconChevronUpDown from '@/components/icon/IconChevronUpDown.vue'
import IconLink from '@/components/icon/IconLink.vue'
import IconInfo from '@/components/icon/IconInfo.vue'
import IconCamera from '../icon/IconCamera.vue';
import DropDownMenu from '../Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../Atoms/DropDownMenuItem.vue';
import IconCheckmark from '../icon/IconCheckmark.vue';
import { TooltipComponent } from "@syncfusion/ej2-vue-popups"
import Badge from '../Badge.vue';
import IconBin from '../icon/IconBin.vue';
import IconLinkExternal from '../icon/IconLinkExternal.vue';
import Title from './Title.vue';
import IconEdit from '../icon/IconEdit.vue';
import {
	AccordionComponent,
	AccordionItemsDirective,
	AccordionItemDirective
} from "@syncfusion/ej2-vue-navigations";
import FormInput from '../Atoms/FormInput.vue';
import IconSettings from '../icon/IconSettings.vue';
import CollapseContainer from '../Atoms/CollapseContainer.vue';
import IconChevronDown from '../icon/IconChevronDown.vue';

export default {
	name: 'PlatformList',
	components: {
		Spinner,
		AddPlatformModal,
		AddCastrStreamModal,
		AddCastrStream2Modal,
		DemoVideoModal,
		AlertModal,
		ConfirmModal,
		EditPlatformModal,
		ResizableTextarea,
		SubSecondRecommendedSettings,
		DestinationDropdown,
		IconBase,
		IconLink,
		IconSettings,
		IconCheckmark,
		IconChevronUpDown,
		FormButton,
		FormSwitch,
		FormInput,
		IconPlus,
		IconCamera,
		IconInfo,
		IconBin,
		IconEdit,
		DropDownMenu,
		DropDownMenuItem,
		IconLinkExternal,
		Badge,
		Title,
		"ejs-tooltip": TooltipComponent,
		"ejs-accordion": AccordionComponent,
		"e-accordionitems": AccordionItemsDirective,
		"e-accordionitem": AccordionItemDirective,
		CollapseContainer,
		IconChevronDown,
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		}
	},
	data() {
		return {
			scopeAlive: true,
			streamPlatforms: [],
			// processing: true,
			configurablePlatform: {},
			configurableField: null,
			platformToggleProcessing: false,
			groupToggleProcessing: false,
			groupToggleState: false,
			appNotificationMsg: '',
			showSettings: false,
			platformDeleted: false,
			platformAdded: false,
			platformUsage: {},
			activeItem: null,
			schedulerConfig: {},
			focusedPlatform: {},
			castrPlatforms: [],
			streams: [],
			regions: [],
			platformRefreshInterval: null,
			alertMessage: '',
			deleteBlock: false,
			facebookOauthState: {},
			facebookOauthErrors: {},
			youtubeOauthState: {},
			youtubeOauthErrors: {},
			twitchCategories: [],
			selectedPlatform: {},
			selectedPrivacyStatus: 'public'
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			hasDestinations: "User/hasDestinations"
		}),
		shouldShowDestinationDropdown() {
			return (this.stream.type === 'live' || this.stream.type === 'scheduled') && this.hasDestinations ? true : false
		},
		platformCastrFiltered: function () {
			let result = this.streamPlatforms

			const filterValue = 'castr.io'

			const filter = streamPlatform =>
				streamPlatform.server.toLowerCase().includes(filterValue) && streamPlatform.streamObject

			return result.filter(filter)
		},
		platformRegularFiltered: function () {
			let result = this.streamPlatforms

			const filterValue = 'castr.io'

			const filter = streamPlatform => !streamPlatform.streamObject
			return result.filter(filter)
		},
		user() {
			return this.$store.state.User;
		}
		,
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		userEmail() {
			return this.$store.state.User.email;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		cannotTogglePlatforms() {
			if (!this.activeTeam || !this.memberPermission) return false;
			const permissionMap = {
				'live': this.memberPermission.liveStreamPlatformToggle,
				'restream': this.memberPermission.recordedStreamPlatformToggle,
				'scheduled': this.memberPermission.preRecordedStreamPlatformToggle,
			}
			return !permissionMap[this.stream?.type];
		},
		tooltipText() {
			return `
				<span class="text-dark-8 text-small">
					You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
					:href="'mailto:${this.userEmail}'">${this.userEmail}</a> to gain access.
				</span>`
		},
		platformCastrLiveFiltered() {
			return this.platformCastrFiltered.filter(platform => 
				platform.streamObject && platform.streamObject.type === 'live'
			);
		},
		
		platformCastrTranscoderFiltered() {
			return this.platformCastrFiltered.filter(platform => 
				platform.streamObject && platform.transcoderObject
			);
		}
	},
	watch: {
		mediaPulse() {
			// console.log('media pulse changed',this.mediaPulse)
			this.checkPlatformConnectStatus();
		},
		bookList: {
			streamPlatforms(val, oldVal) {
				console.log(streamPlatforms[3].enabled)
			},
			deep: true
		},
	},
	async beforeMount() {
		if (this.stream.type === 'scheduled') this.schedulerConfig = await StreamService.getStreamScheduleSettings(this.stream._id) || {};

	},
	async mounted() {
		FormSwitch.compatConfig = { MODE: 3 }
		FormInput.compatConfig = { MODE: 3 }
		this.$store.dispatch('Ui/setLoadingStatus', true);
		if (this.stream.type === 'scheduled') {
			this.schedulerConfig = await StreamService.getStreamScheduleSettings(this.stream._id) || {};
			if (this.platformToggleProcessing === false) {
				await this.fetchStreamPlatformList();
			}
			this.$root.$on('scheduleMode', async data => {
				if (this.schedulerConfig) this.schedulerConfig.mode = data;
				if (this.platformToggleProcessing === false) await this.fetchStreamPlatformList();
			});
		}

		await this.normalizePlatformList(this.stream.platforms);

		this.fetchPlatformUsage()

		// keep refreshing fetching stream platforms
		const platformRefreshSpan = 5000;
		this.platformRefreshInterval = setInterval(async () => {
			try {
				this.platformRefreshInterval = await this.platformRefresh()

			} catch (error) {
				console.log(error);
			}
		}, platformRefreshSpan);
		this.regions = await StreamService.getAvailableRegions('list');
	},
	destroyed() {
		this.scopeAlive = false;
		clearInterval(this.platformRefreshInterval)
	},
	methods: {
		onPrivacySettingsChanged(status, platform) {
			this.selectedPrivacyStatus = status;
			platform.serviceMetaEditor.form.privacyStatus = status;
			this.onMetaPropChange('privacyStatus', platform)
			if(_.isArray(this.$refs.youtubePrivacyDropdownInList)) {
				this.$refs.youtubePrivacyDropdownInList.forEach(x => x.toggleDropdown())
			} else {
				this.$refs.youtubePrivacyDropdownInList.toggleDropdown();
			}
		},
		toggleMetadata(platformId) {
			console.log(this.$refs['collapse' + platformId]);
			this.$refs['collapse' + platformId][0].toggleView()
			setTimeout(() => {
				this.triggerInput(platformId)
			}, "10")
		},
		isMetadataShown(platformId) {
			if (this.$refs['collapse' + platformId]?.length > 0) return this.$refs['collapse' + platformId][0]?.isVisible
		},
		triggerInput(platformId) {
			this.$nextTick(() => {
				const refName = `resize-${platformId}`;
				// this.$refs[refName][0].$el.dispatchEvent(new Event("input"));
			});
		},
		pushStreamFailOverEnabled(platform) {
			return _.get(platform, ['streamObject', 'failoverIngest'], false)
		},
		onSaveTwitchCategory(platform) {
			this.saveMetaProp('game', platform);
		},
		onChangeGameName: _.debounce(async function (name, platform, index) {
			if (!platform.linkedServiceCreds || !name) return;
			this.twitchCategories = await IntegrationService.getTwitchCategories(platform.linkedServiceCreds, name);
			const refName = `twitch-dropdown-${index}`;
			if (this.twitchCategories?.length) this.$refs[refName][0].show();
		}, 250),
		onSelectTwitchCategory(category, platform, index) {
			this.onMetaPropChange('game', platform);
			platform.serviceMetaEditor.form.game_id = category.id;
			platform.serviceMetaEditor.form.game = category.name;
			const refName = `twitch-dropdown-${index}`;
			this.$refs[refName][0].hide();
			this.$forceUpdate();
		},
		getServerUrl(platform) {
			const host = platform.server?.replace(`:${platform.port}`, '')
			return platform.mode === 'srt' ?
				`${host}:${platform.port}${platform.key ? '?streamid=' + platform.key : ''}` :
				platform.server
		},
		async toggleYoutubeAutoStart(platform) {
			if (platform.enabled || this.mediaPulse?.alive) {
				this.youtubeMessage = 'You cannot toggle auto start while the stream is active!'
				return this.$root.$emit('bv::show::modal', 'auto-start-yt');
			}
			// console.log('autostart');
			if (platform.template === 'youtube events') {
				this.focusedPlatform = platform;
				const platformIndex = _.findIndex(this.streamPlatforms, (item) => item._id === platform._id);
				const newState = !this.streamPlatforms[platformIndex].enableAutoStart
				this.$nextTick(() => {
					this.streamPlatforms[platformIndex].enableAutoStart = newState;
				});
				await StreamService.toggleYoutubeAutoStart(this.stream._id, platform._id, newState)
			}
		},
		getFacebookUrl(fbAccount) {
			const oauthFactoryName = 'facebook'
			const pathname = `integrations/${oauthFactoryName}/oauth`;
			const connectId = v4();

			const searchParams = `redirect=1&user=${this.user._id || this.user.id}&stream=${this.stream._id
				}&connectId=${connectId}&fbAccount=${fbAccount}`;

			const routeUrl = `${process.env.VUE_APP_APP_API_BASE}/${pathname}?${searchParams}`;

			this.facebookOauthState[fbAccount].isLoading = true
			this.facebookOauthState[fbAccount].errorMessage = ''
			this.listenAckEventResponse(connectId, fbAccount, this.reloadPlatforms);

			if (window.todesktop) {
				window.todesktop.contents.openUrlInBrowser(routeUrl);
			} else {
				window.open(routeUrl, '_blank');
			}
		},
		getYoutubeUrl(ytAccount) {
			const oauthFactoryName = 'youtube'
			const pathname = `integrations/${oauthFactoryName}/oauth`;
			const connectId = v4();

			const searchParams = `redirect=1&user=${this.user._id || this.user.id}&stream=${this.stream._id
				}&connectId=${connectId}&ytAccount=${ytAccount}`;

			const routeUrl = `${process.env.VUE_APP_APP_API_BASE}/${pathname}?${searchParams}`;

			this.youtubeOauthState[ytAccount].isLoading = true
			this.youtubeOauthState[ytAccount].errorMessage = ''
			this.listenAckEventResponseYT(connectId, ytAccount, this.reloadPlatforms);

			if (window.todesktop) {
				window.todesktop.contents.openUrlInBrowser(routeUrl);
			} else {
				window.open(routeUrl, '_blank');
			}
		},
		async listenAckEventResponseYT(key, ytAccount, callback) {
			if (!this.youtubeOauthState[ytAccount].isLoading) return
			const ackRes = await IntegrationService.getAckByKey(key);
			if (ackRes && ackRes.success) {
				this.youtubeOauthState[ytAccount].isLoading = false
				if (ackRes?.data?.error) {
					this.youtubeOauthState[ytAccount].errorMessage = ackRes?.data?.message
				}

				await callback(ackRes.data);
			} else {
				setTimeout(() => {
					this.listenAckEventResponse(key, ytAccount, callback);
				}, 4000);
			}
		},
		async listenAckEventResponse(key, fbAccount, callback) {
			if (!this.facebookOauthState[fbAccount].isLoading) return
			const ackRes = await IntegrationService.getAckByKey(key);
			// console.log('ackRes', ackRes, 'processing: ', this.facebookOauthState[fbAccount])
			if (ackRes && ackRes.success) {
				this.facebookOauthState[fbAccount].isLoading = false
				if (ackRes?.data?.error) {
					this.facebookOauthState[fbAccount].errorMessage = ackRes?.data?.message
				}

				await callback(ackRes.data);
			} else {
				setTimeout(() => {
					this.listenAckEventResponse(key, fbAccount, callback);
				}, 4000);
			}
		},
		async reloadPlatforms() {
			await this.normalizePlatformList(this.stream.platforms);
		},
		navigatePaymentsPage() {
			window.location.href = '/app/subscribe';
		},
		async fetchStreamPlatformList() {
			if (this.platformToggleProcessing) return
			try {
				const streamObj = await StreamService.getStream(this.stream._id);
				await this.normalizePlatformList(streamObj.platforms);
			} catch (e) {
				console.log('platform-list-err', e);
			}
		},
		async normalizePlatformList(platformList) {
			if (this.platformToggleProcessing) return;
			// get stream details
			try {
				// normalize data
				const streamPlatforms = _.cloneDeep(
					platformList || this.stream.platforms
				);
				for (let i = 0; i < streamPlatforms.length; i++) {
					streamPlatforms[i] = await this.normalizePlatform(streamPlatforms[i]);
					if (streamPlatforms[i].template === "youtube events") {
						streamPlatforms[i].maxLengthTitle = 100;
						streamPlatforms[i].maxLengthDescription = 5000;
						streamPlatforms[i].showTitleLimitTracking = true;

						if (streamPlatforms[i].linkedServiceCreds) {
							const authToken = await StreamService.getAccessTokenByServiceCred(streamPlatforms[i].linkedServiceCreds)
							try {
								const metadata = await IntegrationService.isYoutubeTokenValid(streamPlatforms[i].linkedServiceCreds, streamPlatforms[i].serviceMeta?.subChannel)
								if (_.isBoolean(metadata.isTokenValid)) {
									streamPlatforms[i].isTokenValid = metadata.isTokenValid
								}
							} catch (error) {
								streamPlatforms[i].isTokenValid = false
							}

							streamPlatforms[i].ytAccount = authToken?._id

							if (!this.youtubeOauthState[authToken?._id]) {
								this.$set(this.youtubeOauthState, authToken?._id, { isLoading: false, errorMessage: '' })
							}
						} else {
							streamPlatforms[i].isTokenValid = true
						}

						if (!streamPlatforms[i].isTokenValid) {
							streamPlatforms[i].enabled = false
						}
					} else {
						streamPlatforms[i].showTitleLimitTracking = false;
						streamPlatforms[i].maxLengthTitle = 1000;
						streamPlatforms[i].maxLengthDescription = 5000;
					}

					if (!_.isBoolean(streamPlatforms[i].isTokenValid)) streamPlatforms[i].isTokenValid = true

					if (streamPlatforms[i].template === 'facebook live' && streamPlatforms[i]?.linkedServiceCreds) {
						const authToken = await StreamService.getAccessTokenByServiceCred(streamPlatforms[i].linkedServiceCreds)
						streamPlatforms[i].isTokenValid = await IntegrationService.isFacebookTokenValid(streamPlatforms[i].linkedServiceCreds)
						streamPlatforms[i].fbAccount = authToken?.account

						if (!this.facebookOauthState[authToken?.account]) {
							this.$set(this.facebookOauthState, authToken?.account, { isLoading: false, errorMessage: '' })
						}

						if (!streamPlatforms[i].isTokenValid) {
							streamPlatforms[i].enabled = false
						}
					}
				}

				this.streamPlatforms = _.map(streamPlatforms, (item) => {
					const platform = _.find(
						this.streamPlatforms,
						(i) => i._id === item._id
					);
					if (platform && platform.hasOwnProperty("connected")) {
						item.connected = platform.connected;
					}
					return item;
				});
				this.$store.dispatch('Ui/setLoadingStatus', false);
				// this.processing = false;

				this.computeGroupToggleState();
			} catch (err) {
				// redirect to stream list
				this.$router.push({ name: "ChannelList" });
				this.$notify({
					group: "error",
					title: err.error,
					text: err.message,
				});
			}

			this.checkPlatformConnectStatus();
		},
		async normalizePlatform(platform) {
			let serviceMetaEditor = {};
			if (platform.serviceMeta) {
				serviceMetaEditor = {
					formProcessing: _.mapValues(platform.serviceMeta, () => false),
					form: _.assign({}, platform.serviceMeta),
					changes: {}
				};
			}

			let platformTemplate = await IntegrationService.getPublishPlatform(platform.template);
			platformTemplate = platformTemplate || {};

			const subChannelMeta = _.get(platform, 'serviceMeta.subChannel');
			let isFBUserTimeline = false;

			if (!_.isEmpty(subChannelMeta)) {
				isFBUserTimeline = subChannelMeta.split('@')[0] === 'user';
			}


			platform = {
				...platform,
				serviceMetaEditor,
				editorName: platform.name,
				icon: platformTemplate.icon,
				config: !isFBUserTimeline ? (platformTemplate.config || {}) : { ...platformTemplate.config, postCreationMetaEditing: false, type: 'userFBtimeline' },
				removingProcessing: false
			};
			if (this.schedulerConfig.mode === 'loop' && platform.template === 'facebook live' && platform.linkedServiceCreds) {
				platform.tooltipId = platform._id;
				platform.enabled = false;
			}
			return platform;
		},
		checkPlatformConnectStatus(specificPlatform) {
			_.each(this.streamPlatforms, (platform, index) => {
				if (!specificPlatform || specificPlatform._id === platform._id) {
					const platformPushUrl = composePlatformPushUrl(platform);
					const connectStatus = computePlatformStatus(
						platformPushUrl,
						this.mediaPulse
					);
					// We don't check the platform which has streamObject property, because pulse API doesn't return fully pushs data of these streams
					// For those platforms, we do a separated fetching function on mounted
					if (platform.connected !== connectStatus && !platform.streamObject) {
						const pchanges = _.assign({}, platform, {
							connected: connectStatus
						});
						this.$set(this.streamPlatforms, index, pchanges);
					}
				}
			});

			this.$emit('platform-list', this.streamPlatforms);
		},
		async onNewPlatform() {
			this.platformAdded = !this.platformAdded
			this.$emit('refresh-stream', () => {
				setTimeout(() => {
					this.normalizePlatformList();
				}, 200);
			});
		},
		async onPlatformNameChange(newName) {
			if (this.selectedPlatform.name === newName) return;

			// try changing stream name
			try {
				await StreamService.setStreamPlatformName(
					this.stream._id,
					this.selectedPlatform._id,
					newName
				);
				this.selectedPlatform = {}
			} catch (err) {
				this.selectedPlatform = {}

				// let platforms = _.cloneDeep(this.stream.platforms);
				// this.stream.platforms = platforms;
				// let platforms = _.cloneDeep(this.streamPlatforms);
				// this.streamPlatforms = platforms;

				this.$notify({
					group: 'error',
					title: "Couldn't change platform name",
					text: err.message
				});
			}
		},
		onPlatformUpdated(targetPlatform, updates) {
			// platform.server = updates.server;
			// platform.streamKey = updates.streamKey;

			const platIndex = _.findIndex(this.streamPlatforms, {
				_id: targetPlatform._id
			});
			if (platIndex > -1) {
				const updatedPlatform = _.assign(
					{},
					this.configurablePlatform,
					updates
				);
				this.streamPlatforms = utils.updateArrayItem(
					this.streamPlatforms,
					updatedPlatform,
					platIndex
				);

				// update parent model
				this.$emit('platform-list', this.streamPlatforms);
				// this.$emit('stream-updated', { platforms: this.streamPlatforms })

				this.checkPlatformConnectStatus(updatedPlatform);
			}
		},
		toggleMetaEditor(platform) {
			platform.metaContianerVisible = !platform.metaContianerVisible;
		},
		canSaveMetadataProp(propname, platform) {
			if (!propname || !platform) return;
			return (
				platform.serviceMetaEditor.form[propname] !==
				platform.serviceMeta[propname]
			);
		},
		onMetaPropChange(propname, platform) {
			if (!propname || !platform) return;
			const hasChanged =
				platform.serviceMetaEditor.form[propname] !==
				platform.serviceMeta[propname];
			platform.serviceMetaEditor.changes[propname] = hasChanged;

			const pindex = _.findIndex(this.streamPlatforms, {
				_id: platform._id
			});
			this.streamPlatforms = utils.updateArrayItem(
				this.streamPlatforms,
				platform,
				pindex
			);
		},
		hasMetaProp(propname, platform) {
			if (!propname || !platform) return;
			return propname in platform.serviceMetaEditor.form;
		},
		async saveMetaProp(propname, platform) {
			if (!propname || !platform) return;
			const pindex = this.streamPlatforms.indexOf(platform);

			const newValue = platform.serviceMetaEditor.form[propname];
			platform.serviceMetaEditor.formProcessing[propname] = true;

			this.streamPlatforms = utils.updateArrayItem(
				this.streamPlatforms,
				platform,
				pindex
			);

			try {
				await IntegrationService.updateIntegrationMetadata(
					platform.linkedServiceCreds,
					platform.serviceMetaEditor.form
				);

				// update parent model
				this.$emit('platform-list', this.streamPlatforms);
				// this.$emit('stream-updated', { platforms: this.streamPlatforms })
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not update stream metadata'
				});
			}

			platform.serviceMetaEditor.formProcessing[propname] = false;
			// save changes
			platform.serviceMeta = _.assign({}, platform.serviceMeta, {
				[propname]: newValue
			});
			platform.serviceMetaEditor.changes[propname] = false;

			this.streamPlatforms = utils.updateArrayItem(
				this.streamPlatforms,
				platform,
				pindex
			);
		},
		async onAllPlatformsUpdateConfirm(propname, platform) {
			const newValue = platform.serviceMetaEditor.form[propname];
			this.streamPlatforms.forEach((item) => {
				if (['facebook live', 'youtube events', 'twitch'].indexOf(item.template) !== -1) {
					if (item.template === 'facebook live' && item.config.type === 'userFBtimeline') return
					if (item.template === 'twitch' && propname === 'description') return
					if (item.serviceMetaEditor.form && item.serviceMetaEditor.form[propname]) {
						item.serviceMetaEditor.form[propname] = newValue
						this.saveMetaProp(propname, item)
					}
				}
			})
		},
		computeGroupToggleState() {
			// const { platforms } = this.stream;
			const platforms = this.streamPlatforms;
			const platformCount = _.size(platforms);
			const enabledPlatformCount = _.size(
				_.filter(platforms, p => p.enabled)
			);

			this.groupToggleState = enabledPlatformCount > platformCount / 2;
		},
		toggleGroupStatus(state = this.groupToggleState) {
			// const ostate = oldState;
			const nstate = state;
			this.groupToggleProcessing = true;

			_toggle.call(this, 0, () => {
				// compute group toggle state
				this.computeGroupToggleState();
				this.groupToggleProcessing = false;
			});

			async function _toggle(index, cb) {
				const platforms = this.streamPlatforms;
				const platform = platforms[index];
				if (!platform) {
					return cb();
				}

				await this.togglePlatformStatus(platform, nstate, true);
				if (this.platformRegularFiltered[index]) {
					this.platformRegularFiltered[index].enabled = nstate;
				}
				_toggle.call(this, ++index, cb);
			}
		},
		async onToggleOffWarningConfirmed() {
			await this.togglePlatformStatus(this.focusedPlatform);
			this.focusedPlatform.enabled = !this.focusedPlatform.enabled;
		},
		async onTogglePlatformStatus(platform, forceState, groupToggleReq) {
			if (!platform.enabled && platform.template === 'facebook live' && this.mediaPulse.alive) {
				this.focusedPlatform = platform;
				this.$nextTick(() => {
					const platformIndex = _.findIndex(this.streamPlatforms, (item) => item._id === platform._id);
					this.streamPlatforms[platformIndex].enabled = true;
				});
				return this.$root.$emit('bv::show::modal', 'toggle-off-warning');
			}
			await this.togglePlatformStatus(platform, forceState, groupToggleReq)
		},
		async togglePlatformStatus(platform, forceState, groupToggleReq) {
			// do not toggle state if the token is invalid
			if (platform.template === 'facebook live' && !platform.isTokenValid) return

			// block toggle platform if dest has backup failover eanbled
			if (this.pushStreamFailOverEnabled(platform)) {
				return
			}

			this.platformToggleProcessing = true;
			const platformIndex = _.findIndex(this.streamPlatforms, (item) => item._id === platform._id);
			this.streamPlatforms[platformIndex].statusProcessing = true;

			const oldStatus = platform.enabled;
			const newStatus = forceState !== undefined ? forceState : !oldStatus;

			if (this.schedulerConfig.mode === 'loop' && platform.template === 'facebook live' && platform.linkedServiceCreds && newStatus === true) return

			const pindex = _.findIndex(
				this.streamPlatforms,
				p => p._id === platform._id
			);

			try {
				await StreamService.toggleStreamPlatform(
					this.stream._id,
					platform._id,
					forceState
				);

				// update parent model
				this.$emit('platform-list', this.streamPlatforms);
				// this.$emit('stream-updated', { platforms: this.streamPlatforms })

				// track event
				window.trackEvent(
					`${newStatus ? 'Enabled' : 'Disabled'} ${_.capitalize(
						platform.name
					)} platform in stream ${this.stream.name}`,
					platform
				);

				if (!groupToggleReq) {
					this.computeGroupToggleState();
				}
			} catch (err) {
				// change to old status
				platform.enabled = oldStatus;
				this.streamPlatforms = utils.updateArrayItem(
					this.streamPlatforms,
					_.assign({}, platform, { enabled: oldStatus }),
					pindex
				);

				this.$notify({
					group: 'error',
					title: "Couldn't toggle platform status",
					text: err.message
				});

				if (err && err.code === 403 && err.message) {
					this.appNotificationMsg = err.message;
					this.$root.$emit('bv::show::modal', 'modal-trial-publish-limit');
				}
			}
			platform.statusProcessing = false;
			this.platformToggleProcessing = false;
			this.streamPlatforms[platformIndex].statusProcessing = false;
		},
		requestPlatformModify(platform) {
			if (platform.linkedServiceCreds) {
				this.alertMessage = `Platform is connected to ${platform.template} account and cannot be edited but can be toggled or deleted`
				this.$root.$emit('bv::show::modal', 'platform-is-linked');
				return;
			}

			this.configurablePlatform = platform;
			this.$root.$emit('bv::show::modal', 'modal-configure-platform');
		},
		requestPlatformDelete(platform) {
			this.configurablePlatform = platform;
			this.$root.$emit('bv::show::modal', 'platform-delete-confirm');
		},
		requestAllPlatformsUpdate(field, platform) {
			this.configurablePlatform = platform;
			this.configurableField = field;
			this.$root.$emit('bv::show::modal', 'all-platforms-update-confirm');
		},
		async onPlatformDeleteConfirm() {
			const platform = this.configurablePlatform;
			platform.removingProcessing = true;
			this.deleteBlock = true;

			// try deleting stream platform
			try {
				if (platform.reusableDestination) {
					await DestinationService.removeDestinationFromStream(platform._id, this.stream._id)
					await this.$store.dispatch('Destination/getDestinations')
					this.destinationsChanged()
				} else {
					await StreamService.deleteStreamPlatform(this.stream._id, platform._id)
				}
				// popout platform object
				const platforms = this.streamPlatforms;
				const index = platforms.indexOf(this.configurablePlatform);
				this.streamPlatforms = _.concat(
					platforms.slice(0, index),
					platforms.slice(index + 1)
				);
				// update parent model
				this.$emit('platform-list', this.streamPlatforms);
				this.$emit('refresh-stream');
				this.platformDeleted = !this.platformDeleted;
				// this.$emit('stream-updated', { platforms: this.streamPlatforms })

				this.computeGroupToggleState();

				// track event
				window.trackEvent(
					`Deleted ${platform.name} platform in stream ${this.stream.name}`,
					platform
				);
				this.deleteBlock = false;
			} catch (err) {
				this.$notify({
					group: 'error',
					title: "Couldn't delete publish platform",
					text: err.message
				});
				platform.removingProcessing = false;
				this.deleteBlock = false;
			}
		},
		toggleSettings(platform_id) {
			this.showSettings = !this.showSettings;
			this.activeItem = platform_id;
		},
		addCastrPlatforms(video) {
			this.castrPlatforms.push(video);
		},
		getCountryFlag(region) {
			if (this.regions.length > 0) {
				let result =
					this.regions.find(reg => reg.name === region)?.identifier;
				return `https://assets.castr.io/countryflags/${result}/flat/64.png`;
			}
		},
		async platformRefresh() {
			if (!this.scopeAlive) return;

			// check if refresh is needed
			let shouldRefreshPlatformList = false;

			if (this.mediaPulse && this.mediaPulse.push_stats) {
				this.stream.platforms.forEach(p => {
					const isLinkedFb = p.enabled && p.template === 'facebook live' && p.linkedServiceCreds;
					if (isLinkedFb) {
						const pushUrl = composePlatformPushUrl(p);
						if (!this.mediaPulse.push_stats[pushUrl]) {
							shouldRefreshPlatformList = true;
						}
					}
					const isYoutubeEvents = p.enabled && p.template === 'youtube events';
					if (isYoutubeEvents) {
						const pushUrl = composePlatformPushUrl(p);
						if (!this.mediaPulse.push_stats[pushUrl]) {
							shouldRefreshPlatformList = true;
						}
					}
				});
			}

			// Fetch stats of the stream as platform
			this.streamPlatforms.map(async (platform, idx) => {
				if (platform.streamObject) {
					const pulseObject = await StreamStatsService.getStreamMediaPulse(platform.streamObject.key);
					if (pulseObject && pulseObject.alive) {
						platform.connected = true
					} else platform.connected = false
					this.$set(this.streamPlatforms, idx, platform)
					return platform
				}
			});

			if (shouldRefreshPlatformList && !document.hidden) {
				try {
					await this.fetchStreamPlatformList();
				} catch (e) { }
			}
		},
		getYoutubeChannelUrl(url) {
			if (!url) return ''
			let base = 'https://www.youtube.com/channel/';
			if (!url.startsWith(base)) return url;
			const channelId = url.substring(base.length);
			if (!channelId) return url;
			return `https://studio.youtube.com/channel/${channelId}/livestreaming/manage`
		},
		async fetchPlatformUsage() {
			const platformUsage = await StreamService.getPlatformUsage(this.stream.type);
			if (platformUsage) this.platformUsage = platformUsage
		},
		async destinationsChanged() {
			this.$emit('refresh-stream', () => {
				setTimeout(() => {
					this.normalizePlatformList();
				}, 200);
			});
			this.fetchPlatformUsage()
		}
	}
};

function composePlatformPushUrl(platform) {
	if (!platform) return;
	let pushUrl = platform.server;
	pushUrl = pushUrl.replace(/\/$/g, '');
	if (pushUrl.indexOf('ustream.tv') > -1 || pushUrl.indexOf('secdn.net') > -1) {
		pushUrl += '/';
	}
	if (!pushUrl.includes('srt://')) {
		pushUrl += '/' + platform.key;
	}
	return pushUrl;
}

function computePlatformStatus(platformPushUrl, mediaPulse) {
	let isSrt = false
	let srtPlatformKey = null
	if (platformPushUrl.includes('#!::r=') || platformPushUrl.includes('srt://')) {
		srtPlatformKey = new URL(platformPushUrl).hash
		isSrt = true
	}
	let connected = false;
	if (mediaPulse && mediaPulse.push_stats && !_.isEmpty(mediaPulse.push_stats)) {
		let pushedBytes = mediaPulse.push_stats[isSrt ? srtPlatformKey : platformPushUrl];
		if (!pushedBytes && !_.isEmpty(mediaPulse.push_stats)) {
			_.forEach(mediaPulse.push_stats, (val, key) => {
				const item = _.assign(val, { url: key })
				if (item.url && item.url === platformPushUrl) pushedBytes = item

				if (item.url.includes('srt://')) {
					// srt platform could be only matched based on host part
					const statsHost = new URL(item.url).pathname.replace('//', '').replace('/', '')
					const platformHost = new URL(platformPushUrl).pathname.replace('//', '').replace('/', '')

					if (statsHost === platformHost) pushedBytes = item
				}

			})
		}
		if (pushedBytes) {
			connected = pushedBytes.sent_bytes > 0 || pushedBytes.bytes > 0;
		}
	}
	return connected;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 767px) {
	.platform-item__icon {
		flex: 0 0 60px;
		text-align: center;
	}

	.platform-item__icon img {
		max-width: 60px;
	}

	.platform-item__server {
		width: 100%;
		max-width: 150px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.platform-item .custom-switch {
		flex: 0 0 44px;
		max-width: 44px;
		text-align: center;
	}

	.subtitle_input {
		height: 30px;
	}
}
</style>

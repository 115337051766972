<template>
	<div class="">
		<b-modal
			:id="modalId"
			ref="modalLayout"
			:size="modalSize"
			@hidden="resetModal"
			hide-header
			hide-footer
            :content-class="'body-margin-bottom'"
            body-class="body-padding-modal"
			centered
			:no-close-on-backdrop="closeoutsideClick"
            dialog-class="modal-dialog-bottom"
		>
		<b-row>
		</b-row>
		<b-row>
		</b-row>
		<slot>
		</slot>
		<b-row >
			<b-col class="flex items-center justify-between">
                            <MultiviewTile 
                                v-for="(item, index) in [2, 4, 6]" 
                                :tiles="item" 
                                :key="index" 
                                @tile-selected="tileSelected"
                                :selected="item == layout"
                                :fontClass="'text-dark-7 text-x-ss'"
                            />
			</b-col>
		</b-row>
		</b-modal>
	</div>
</template>

<script>
import MultiviewTile from '@/components/Multiview/MultiviewTile.vue'

export default {
	name: 'SelectLayoutModal',
	components: {
        MultiviewTile
    },
	props: {
		modalId: {
			type: String,
			default: 'modal-confirm2'
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
		closeoutsideClick: {
			type: Boolean,
			default: false
		},
		confirmCancelEvent: {
			type: Boolean,
			default: true
		},
		dismissAfterConfirm: {
			type: Boolean,
			default: true
		},
        layout: {
            type: Number,
            required: true
        }
	},
	data() {
		return {
			processing: false,
		};
	},
	methods: {
        tileSelected(newLayout) {
            if (this.layout === newLayout) {
			    this.$refs.modalLayout.hide();
                return
            }
			this.$emit('on-layout-resize', newLayout);
			this.$refs.modalLayout.hide();
        },
		resetModal() {
			this.$emit('modal-hidden');
		}
	},
};
</script>

<style>
.modal-dialog-bottom{
    align-items: flex-end !important;
}

.body-margin-bottom {
    margin-bottom: 35px;
}

.body-padding-modal {
    padding: 0.8rem;
    
}

</style>

<script setup>
import { ref, computed, inject } from 'vue';
import _ from 'lodash'
import { useNotification } from "@kyvg/vue3-notification"
import SubscriptionService from '@/services/SubscriptionService'
import SubscribePlanItemHeader from './SubscribePlanItemHeader.vue';
import SubscribePlanItemDescription from './SubscribePlanItemDescription.vue';
import FormButton from '../../components/Atoms/FormButton.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronLeft from '../../components/icon/IconChevronLeft.vue';
import IconInfoSmall from '../../components/icon/IconInfoSmall.vue';
import FormInput from '../../components/Atoms/FormInput.vue';
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconChevronDown from '../../components/icon/IconChevronDown.vue';
import { date } from "@/filtersNew"

const props = defineProps({
  subscriptionPackage: {
    type: Object,
  },
  isYearly: {
    type: Boolean
  }
})

const { notify } = useNotification()

const price = computed(() => props.isYearly ? props.subscriptionPackage.info?.yearly : props.subscriptionPackage.baseCharge)

const { selectPackage, availablePaymentMethods, selectedPaymentMethod, selectPaymentMethod } = inject('selectedPaymentMethod')

const availablePaymentMethodsFiltered = computed(() => price.value > 40 ? availablePaymentMethods.value.filter(method => method.value !== 'paypal') : availablePaymentMethods.value)

const paymentTypeDropdown = ref(null)

const showCouponCode = ref(false)
const couponCode = ref({
  code: '',
  status: null,
  isValid: false
})

const isDisabled = computed(() => couponCode.value.code.length === 0)

const isAnnualPackage = (pack) => {
  return /yearly/i.test(pack.name)
}
const getEndingDate = (pack) => {
  const today = new Date()
  if (isAnnualPackage(props.subscriptionPackage)) {
    today.setFullYear(today.getFullYear() + 1)
  } else {
    today.setMonth(today.getMonth() + 1)
  }
  return today
}

const selectPayment = (paymentMethod) => {
  selectPaymentMethod(paymentMethod)
  paymentTypeDropdown.value.toggleDropdown()
}

const applyCouponCode = async () => {
  try {
    if (couponCode.value.code.length === 0) {
      couponCode.value.status = 'The coupon code cannot be empty'
      return
    }
    const data = await SubscriptionService.checkCouponCode(props.subscriptionPackage._id, selectedPaymentMethod.value, couponCode.value.code)
    if (data.status === 'valid_code') {
      couponCode.value.isValid = true
      couponCode.value.status = "Promo code successfully redeemed. You\'ll see the discounted price on the next page"
    } else {
      couponCode.value.isValid = false
      couponCode.value.status = 'Your promo code is no longer valid'
    }
  } catch (e) {
    notify({ group: 'error', text: e ? e.message : 'Failed to check coupon code' })
  }
}

const requestCheckout = async () => {
  try {
    // Validate coupon code first
    if (couponCode.value.code) {
      await applyCouponCode()
      if (!couponCode.value.isValid) {
        notify({ group: 'error', text: couponCode.value.status })
        return
      }
    }
    // Create invoice
    const invoiceRes = await SubscriptionService.createInvoicePaymentRequest(props.subscriptionPackage._id, selectedPaymentMethod.value.value, couponCode.value.code)
    window.location.href = invoiceRes.invoiceUrl
  } catch (e) {
    notify({ group: 'error', text: e ? e.message : 'failed to create invoice0' })
  }
}

</script>

<template>
  <div class="max-w-[820px] mx-auto">
    <FormButton type="link" @click="selectPackage?.(0)" size="sm">
      <icon-base fill="none">
        <icon-chevron-left />
      </icon-base>
      Back
    </FormButton>
    <section class="bg-white p-2 rounded-lg flex !flex-wrap lg:!flex-nowrap text-dark-1 w-full">
      <div class="border border-surface-9 rounded-lg w-full lg:w-1/3">
        <SubscribePlanItemHeader is-cart :plan="subscriptionPackage" :is-yearly="props.isYearly" />
        <SubscribePlanItemDescription is-cart :plan="subscriptionPackage" :is-yearly="props.isYearly" />
      </div>
      <div class="!p-2 lg:!p-12 grow">
        <h2 class="font-medium mb-2 mt-6 lg:mt-0">Subscription Terms: ${{ price ? price :
          props.subscriptionPackage?.baseCharge }} for each month</h2>
        <div class="relative lg:h-32">
          <FormButton v-if="!showCouponCode" @click="showCouponCode = !showCouponCode"
            customClasses="text-orange text-sm font-medium h-8">Got a coupon code?
          </FormButton>
          <div class="flex gap-x-2" v-else>
            <FormInput customClasses="!text-surface-1 bg-white border border-surface-7" v-model="couponCode.code"
              placeholder="code"></FormInput>
            <FormButton :disabled="isDisabled" @click="applyCouponCode" class="shrink-0" label="Apply" />
          </div>
          <p class="text-tiny flex leading-4 !mt-2" v-if="couponCode.status"
            :class="couponCode.isValid ? 'text-success' : 'text-danger'">
            <icon-base v-if="!couponCode.isValid" class="mr-1">
              <icon-info-small />
            </icon-base>
            {{ couponCode.status }}
          </p>
        </div>
        <dl class="grid grid-cols-2 py-6 px-8 rounded-xl !border !border-surface-9">
          <dt class="font-medium">Valid till</dt>
          <dd class="text-right">{{ date(getEndingDate(props.subscriptionPackage), 'DD MMM, YYYY') }}</dd>
          <hr class="col-span-2 !border !border-surface-9 !my-4">
          <dt class="font-medium">Total</dt>
          <dd class="text-right">${{ props.subscriptionPackage.baseCharge }}</dd>
          <hr class="col-span-2 !border !border-surface-9 !my-4">
          <dt class="font-medium">Pay with</dt>
          <dd class="text-right">
            <drop-down-menu ref="paymentTypeDropdown" class="" position="right"
              wrapperClass="bg-white !border !border-surface-9"
              customClasses="ml-auto text-tiny disabled:text-surface-7 h-8 bg-white rounded-md flex items-center px-3 capitalize text-nowrap !border !border-surface-9">
              <template #toggle-button>
                {{ selectedPaymentMethod.label }}
                <icon-base class="ml-2">
                  <icon-chevron-down />
                </icon-base>
              </template>
              <template #menu-items>
                <drop-down-menu-item
                  customClasses="w-full bg-white text-dark-1 text-tiny text-left font-medium h-8 px-3 hover:!bg-surface-9"
                  v-for="(paymentMethod, index) in availablePaymentMethodsFiltered" :key="index"
                  @click="selectPayment(paymentMethod)">
                  {{ paymentMethod.label }}
                </drop-down-menu-item>
              </template>
            </drop-down-menu>
          </dd>
        </dl>
        <FormButton class="!my-4 text-center justify-center" full-width label="Proceed to Checkout"
          @click="requestCheckout" />
        <p class="text-surface-6 text-sm text-center">* All prices are in USD</p>
      </div>
    </section>
  </div>
</template>

<style></style>

<script setup>
import { ref } from 'vue'
import _ from 'lodash'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import MainSidebarItem from './MainSidebarItem.vue';
import IconCamera from '../icon/IconCamera.vue';
import IconCameraCinema from '../icon/IconCameraCinema.vue';
import IconTV from '../icon/IconTV.vue';
import IconLogout from '../icon/IconLogout.vue';
import IconLightning from '../icon/IconLightning.vue';
import IconTranscoder from '../icon/IconTranscoder.vue';
import IconHighlighter from '../icon/IconHighlighter.vue';
import IconHosting from '../icon/IconHosting.vue';
import IconPlayer from '../icon/IconPlayer.vue';
import IconPlaylist from '../icon/IconPlaylist.vue';
import IconShowcase from '../icon/IconShowcase.vue';
import IconBanknote from '../icon/IconBanknote.vue';
import IconOrigin from '../icon/IconOrigin.vue';
import IconLink from '../icon/IconLink.vue';
import IconGear from '../icon/IconGear.vue';
import IconTeam from '../icon/IconTeam.vue';
import IconCreditCard from '../icon/IconCreditCard.vue';
import IconCalendarPlus from '../icon/IconCalendarPlus.vue';
import IconUpgrade from '../icon/IconUpgrade.vue';
import IconBook from '../icon/IconBook.vue';
import IconHelp from '../icon/IconHelp.vue';
import IconDownload from '../icon/IconDownload.vue';
import IconMultiview from '../icon/IconMultiview.vue';

const props = defineProps({
  expanded: {
    type: Boolean,
  },
})

const { notify } = useNotification()
const router = useRouter()
const store = useStore()

const forceLogout = async (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  await store.dispatch('Auth/logout');
  process.env.VUE_APP_API_ENV === 'prod' ? window.location.href = '/signin' : router.push('/login');
  notify({ group: 'info', text: 'Logged out' });
  window.trackEvent('Logged Out');
}


</script>

<template>
  <nav class="flex flex-col grow-1 overflow-y-auto overscroll-y-contain overflow-x-hidden h-full max-h-[calc(100svh-120px)] md:max-h-[calc(100vh-175px)] scrollbar scrollbar-w-1 scrollbar-thumb-surface-5">
    <ul :class="{'mx-auto': !expanded}">
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconCamera" title="Livestream" to="/dashboard" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconLightning" title="Sub Second streaming" to="/sub-second" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconTranscoder" title="Transcoder" to="/transcoder" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconCameraCinema" title="Pre-Recorder Streams" to="/scheduler" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconHighlighter" title="Live Clipping" to="/highlight" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconMultiview" title="Multiview" to="/multiview" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconHosting" title="Video Hosting" to="/vods" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconPlayer" title="Dynamic Player" to="/dynamic" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconTV" title="TV Playout" to="/tv-playout" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconHighlighter" title="Analytics" to="/analytics" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconPlaylist" title="Playlists" to="/playlists" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconShowcase" title="Showcase" to="/showcase" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconBanknote" title="Paywall" to="/paywall/v2" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconLink" title="Destination" to="/destination" />
      </li>
    </ul>
    <hr class="!mb-3 w-full mt-auto" />
    <ul :class="{'mx-auto': !expanded}">
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconGear" title="Settings" to="/manage/account" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconTeam" title="Team" to="/manage/team" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconCreditCard" title="Billing" to="/manage/billing" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconUpgrade" title="Upgrade" to="/subscribe" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconHelp" title="Help" onclick="Intercom('show')" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconBook" title="Docs" to="https://docs.castr.com/" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconCalendarPlus" title="Book a Meeting" to="https://calendly.com/castr/sales?utm_source=header&utm_medium=website&utm_campaign=header-btn-1" />
      </li>
      <li>
        <MainSidebarItem :expanded="expanded" :icon="IconLogout" title="Logout" @click="forceLogout" />
      </li>
    </ul>
    <hr class="!my-3 w-full" />
    <MainSidebarItem :class="{'mx-auto': !expanded}" :expanded="expanded" :icon="IconDownload" title="Get Desktop App" to="https://desktop.castr.com/" />
  </nav>
</template>

<style scoped></style>

<script setup>
import _ from 'lodash'
import Tooltip from '../../components/Atoms/Tooltip.vue';
import IconHelp from '../../components/icon/IconHelp.vue';
import IconBase from '../../components/icon/IconBase.vue';
const props = defineProps({
  data: {
    type: Object,
  },
  isYearly: {
    type: Boolean
  }
})

</script>

<template>
  <div class="p-2 min-h-10 h-12 lg:h-auto text-balance flex items-center lg:block">
    <h5 class="inline leading-none lg:leading-6" :class="props.data?.title ? 'text-surface-6 font-bold' : 'text-black font-medium'">{{ data.name }}</h5><Tooltip v-if="!data.title" wrapperClass="!hidden lg:!inline-flex size-4 ml-2 relative top-1" :content="data.description">
        <IconBase classIcon="text-surface-6">
          <IconHelp />
        </IconBase>
      </Tooltip>
  </div>
</template>

<style></style>

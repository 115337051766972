import _ from "lodash";
import RequestError from "./RequestError";
import amProxyUrl from "./AmProxyUrl";
import api from "@/api";

export default {
  createMultiview,
  getMultiview,
  getMultiviews,
  updateMultiviews,
  deleteMultiview,
  nameChange,
  toggleMultiview
};

function toggleMultiview(id) {
  return makeRequest({
		path: `/multiviews/${id}/toggle`,
		method: 'post',
	});
}

function createMultiview(name, layout) {
	return makeRequest({
		path: '/multiviews',
		method: 'post',
		data: {
			name: name, layout: layout
		}
	});
}

function getMultiviews() {
	return makeRequest({
		path: '/multiviews',
		method: 'get',
	});
}

function getMultiview(id) {
  return makeRequest({
		path: `/multiviews/${id}`,
		method: 'get',
	});
}

function updateMultiviews(id, data) {
  return makeRequest({
		path: `/multiviews/${id}`,
		method: 'put',
    data: data
	});
}

function deleteMultiview(id) {
  return makeRequest({
		path: `/multiviews/${id}`,
		method: 'delete',
	});
}

function nameChange(playerId, name) {
  return makeRequest({
		path: `/multiview/${playerId}`,
		method: 'put',
        data: {
			name: name
		}
	});
}

async function makeRequest(reqConfig, preventProxy, throwReal) {
  if (typeof reqConfig === "string") {
    reqConfig = {
      path: reqConfig,
    };
  }

  if (preventProxy !== true) {
    reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
  }

  reqConfig.url = reqConfig.path;

  let res;
  try {
    res = await api.request(reqConfig);
  } catch (err) {
    if (throwReal) {
      throw err;
    }
    const edata = _.get(err, "response.data");
    throw new RequestError(edata);
  }

  return res && res.data;
}

<template>
  <path
    d="M12.6667 2.66666H3.33333C2.59695 2.66666 2 3.26361 2 3.99999V13.3333C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3333V3.99999C14 3.26361 13.403 2.66666 12.6667 2.66666Z"
    stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M2 6.66666H14" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M9.66667 10.6667L8.33333 10.6667M8.33333 10.6667L7 10.6667M8.33333 10.6667L8.33333 9.33334M8.33333 10.6667L8.33333 12"
    stroke="currentColor" stroke-width="1.2" stroke-linecap="round" />
  <path d="M10.6667 1.33334V4.00001" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"
    stroke-linejoin="round" />
  <path d="M5.33334 1.33334V4.00001" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"
    stroke-linejoin="round" />
</template>
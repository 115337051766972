<template>
  <g>
    <path d="M65 90C89.8521 90 110 69.8917 110 45C110 20.1083 89.7633 0 65 0C40.1479 0 20 20.1083 20 45C20 69.8917 40.1479 90 65 90Z" fill="#232D4D"/>
    <path d="M112.737 15.1649V74.0025C112.737 75.7288 111.299 77.1674 109.572 77.1674H20.1649C18.4386 77.2393 17 75.8008 17 74.0745V15.1649C17 13.3666 18.4386 12 20.1649 12H109.572C111.37 12 112.737 13.4386 112.737 15.1649Z" fill="#343E61" stroke="#404B72" stroke-miterlimit="10"/>
    <path d="M112.737 15.1649V19.6964H17V15.1649C17 13.3666 18.4386 12 20.1649 12H109.572C111.37 12 112.737 13.4386 112.737 15.1649Z" stroke="#404B72" stroke-miterlimit="10"/>
    <path d="M21.2438 17.1789C21.8397 17.1789 22.3227 16.6958 22.3227 16.0999C22.3227 15.504 21.8397 15.021 21.2438 15.021C20.6479 15.021 20.1649 15.504 20.1649 16.0999C20.1649 16.6958 20.6479 17.1789 21.2438 17.1789Z" fill="#404B72"/>
    <path d="M24.6244 17.1789C25.2203 17.1789 25.7033 16.6958 25.7033 16.0999C25.7033 15.504 25.2203 15.021 24.6244 15.021C24.0285 15.021 23.5455 15.504 23.5455 16.0999C23.5455 16.6958 24.0285 17.1789 24.6244 17.1789Z" fill="#404B72"/>
    <path d="M28.077 17.1789C28.6729 17.1789 29.156 16.6958 29.156 16.0999C29.156 15.504 28.6729 15.021 28.077 15.021C27.4812 15.021 26.9981 15.504 26.9981 16.0999C26.9981 16.6958 27.4812 17.1789 28.077 17.1789Z" fill="#404B72"/>
    <rect x="21" y="24" width="28" height="22" rx="1" fill="#404B72"/>
    <path d="M38.5 34.134C39.1667 34.5189 39.1667 35.4811 38.5 35.866L34 38.4641C33.3333 38.849 32.5 38.3679 32.5 37.5981L32.5 32.4019C32.5 31.6321 33.3333 31.151 34 31.5359L38.5 34.134Z" fill="#8794B5"/>
    <rect x="51" y="49" width="28" height="22" rx="1" fill="#404B72"/>
    <rect x="51" y="24" width="28" height="22" rx="1" fill="#404B72"/>
    <rect x="81" y="49" width="28" height="22" rx="1" fill="#404B72"/>
    <path d="M68.5 34.134C69.1667 34.5189 69.1667 35.4811 68.5 35.866L64 38.4641C63.3333 38.849 62.5 38.3679 62.5 37.5981L62.5 32.4019C62.5 31.6321 63.3333 31.151 64 31.5359L68.5 34.134Z" fill="#8794B5"/>
    <path d="M68.5 59.134C69.1667 59.5189 69.1667 60.4811 68.5 60.866L64 63.4641C63.3333 63.849 62.5 63.3679 62.5 62.5981L62.5 57.4019C62.5 56.6321 63.3333 56.151 64 56.5359L68.5 59.134Z" fill="#8794B5"/>
    <path d="M98.5 59.134C99.1667 59.5189 99.1667 60.4811 98.5 60.866L94 63.4641C93.3333 63.849 92.5 63.3679 92.5 62.5981L92.5 57.4019C92.5 56.6321 93.3333 56.151 94 56.5359L98.5 59.134Z" fill="#8794B5"/>
  </g>
</template>

<script setup>
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex'
import _ from 'lodash'
import DemoVideoModal from '@/components/modals/DemoVideoModal.vue';
import Spinner from "@/components/ui/Spinner.vue"
import AddMultiviewModal from "@/components/modals/AddMultiviewModal.vue"
import { useRouter } from 'vue-router'
import FormInput from '../../components/Atoms/FormInput.vue'
import IconBase from '../../components/icon/IconBase.vue'
import IconBin from '../../components/icon/IconBin.vue'
import IconPlus from '../../components/icon/IconPlus.vue'
import IconChevronDown from '../../components/icon/IconChevronDown.vue'
import IconSearch from '../../components/icon/IconSearch.vue'
import IconPlay from '../../components/icon/IconPlay.vue';
import IconBookOpen from '../../components/icon/IconBookOpen.vue';
import FormButton from '../../components/Atoms/FormButton.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue'
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue'
import ViewModeSwitcher from '../../components/ui/ViewModeSwitcher.vue';
import IconMultiviewEmptyPlaceholderImage from '../../components/icon/IconMultiviewEmptyPlaceholderImage.vue'
import IconMultiviewLockedPlaceholderImage from '../../components/icon/IconMultiviewLockedPlaceholderImage.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import { useNotification } from "@kyvg/vue3-notification"
import MultiviewService from '@/services/MultiviewService'
import MultiviewTeamPermissionError from "@/components/Multiview/MultiviewTeamPermissionError.vue"

const store = useStore()
const router = useRouter()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const { notify } = useNotification()

const searchWidgetInputElement = ref()
const showMultiviewModal = ref()

const sortBy = ref('Sort by Oldest')
const searchWidgetActive = ref(true)
const searchWidgetInput = ref('')
const sortDropdown = ref()
const selectedMultiviewId = ref(null)

const viewMode = ref('grid')
const modalDEMOVideo = ref(null);

const playouts = computed(() => store.getters['Streams/tvPlayouts'])
const loadingStatus = computed(() => store.getters['Ui/loadingStatus'])
const isLiveSubscriptionExpired = computed(() => store.getters['User/isLiveSubscriptionExpired'])
const hasLiveSubscription = computed(() => store.getters['User/hasLiveSubscription'])
const maxMultiviews = computed(() => store.getters['User/maxMultiviews'])
const teamPermissionError = computed(() => store.getters['Multiview/teamPermissionError'])
const userEmail = computed(() => store.getters['User/userEmail'])

const multiviews = computed(() => store.getters['Multiview/multiviews'])

const tvPlayoutsFiltered = computed(() => {
	const result = playouts.value.filter(tvPlayout => tvPlayout.name.toLowerCase().includes(searchWidgetInput.value.toLowerCase()))
	return result
})

const multiviewFiltered = computed(() => {
	return multiviews.value.filter(mv => mv.name.toLowerCase().includes(searchWidgetInput.value.toLowerCase()))
})

onMounted(async () => {
	store.dispatch('Ui/setLoadingStatus', true)
	try {
		await store.dispatch('Multiview/getMultiviews')
		store.dispatch('Multiview/setTeamPermissionError', { error: false })
	} catch(err){
		if (err && err.toString().includes('not have access to')) {
			store.dispatch('Multiview/setTeamPermissionError', { error: true })
		}
		
		notify({
			group: "error",
			text: err || "Multiview load failed",
		});
	}
	store.dispatch('Ui/setLoadingStatus', false)
})

const onNewMV = async (player) => {
	router.push({
		name: "MultiviewContent",
		params: {
			id: player._id
		},
		query: {'sourceTab' : 'livestream'}
	})
}

const onDeleteMultiviewRequest = (id) => {
	root.$emit("bv::show::modal", 'delete-multiview')
	selectedMultiviewId.value = id
}

const multiviewStreamsNum = (mv) => {
	return _.filter(mv.streams, (stream) => stream !== null).length;
}

const onMultiviewDelete = async () => {
	if (!selectedMultiviewId.value) {
		return
	}

	console.log('selectedMultiviewId.value', selectedMultiviewId.value)

	try {
		await MultiviewService.deleteMultiview(selectedMultiviewId.value)

		store.dispatch('Multiview/removeMultiview', { id: selectedMultiviewId.value })
		selectedMultiviewId.value = null

		notify({
			group: "success",
			text: "Multiview deleted",
		});
	} catch (err) {
		notify({
			group: "error",
			text: err.message || "Multiview delete failed",
		});
	}
}

const toggleView = (view) => {
	viewMode.value = view
}
const viewModeClasses = computed(() => {
	const gridClasses = 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
	const listClasses = 'grid-cols-1 divide-y divide-surface-2'
	return viewMode.value === 'grid'
		? gridClasses
		: listClasses
})
const toggleSearchWidget = () => {
	searchWidgetActive.value = !searchWidgetActive.value
	if (!searchWidgetActive.value) {
		searchWidgetInput.value = ''
	}
}

const onSearchWidgetBlur = (state) => {
	if (searchWidgetActive.value && !state) toggleSearchWidget()
}

const changeSortBy = (option) => {
	sortBy.value = option
	store.dispatch('Streams/setSortOption', sortBy.value)
	sortDropdown.value.toggleDropdown()
}
const showCreateMultiviewModal = () => {
	showMultiviewModal.value.toggleSidebar()
}
const showDemo = () => {
	modalDEMOVideo.value.show()
}

</script>

<template>
	<div class="container">
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-surface-8 mt-96" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />

		<main v-else-if="(!hasLiveSubscription || isLiveSubscriptionExpired || maxMultiviews === 0) && multiviews.length === 0">
			<header class="flex items-center mb-3 mt-5">
				<div class="">
					<h1 class="text-2xl font-medium m-0">
						Multiview
					</h1>
					<p class="text-dark-6 text-s-s m-0">
						<a target="_blank" class="text-reset mr-3 inline-flex items-center"
							href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							</svg>
							How Multiview works
						</a>
						<a class="text-reset mr-3 inline-flex items-center" @click.prevent="showDemo">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
								fill="#8794B5" />
							</svg>
							Multiview Demo
						</a>
					</p>
				</div>
                <FormButton type="primary" @click="showCreateMultiviewModal" class="ml-auto" :disabled="true">
                    <icon-base class="mr-1">
                        <icon-plus />
                    </icon-base>
                    Create Multiview
                </FormButton>
            </header>
            <section class="text-center mt-48 align-items-center">
                <div class="flex flex-col items-center">
                    <icon-base width-view-box="130" height-view-box="90" width="130" height="90" iconColor="none" class="mb-3">
                        <icon-multiview-locked-placeholder-image />
                    </icon-base>
                </div>
                <p class="text-sm text-surface-7 mb-3">Your current plan does not support this feature.</p>
                <p class="flex flex-col w-full items-center">
                    <FormButton type="secondary" class="mb-3 w-64 justify-center"
                        href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
                        <icon-base class="mr-2">
                            <icon-book-open />
                        </icon-base>
                        How Multiview works
                    </FormButton>
                    <FormButton type="secondary" class="mb-3 w-64 justify-center" @click.prevent="showDemo">
                        <icon-base class="mr-2">
                            <icon-play />
                        </icon-base>
                        Watch a tutorial
                    </FormButton>
                    <FormButton to="/subscribe" class="w-64 justify-center">
                        Upgrade Plan
                    </FormButton>
                </p>
            </section>
		</main>

		<main v-else-if="multiviews.length === 0">
			<header class="flex items-center mb-3 mt-5">
				<div class="">
					<h1 class="text-2xl font-medium m-0">
						Multiview
					</h1>
					<p class="text-dark-6 text-s-s m-0">
						<a target="_blank" class="text-reset mr-3 inline-flex items-center"
							href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							</svg>
							How Multiview works
						</a>
						<a class="text-reset mr-3 inline-flex items-center" @click.prevent="showDemo">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
								fill="#8794B5" />
							</svg>
							Multiview Demo
						</a>
					</p>
				</div>
				<FormButton type="primary" @click="showCreateMultiviewModal" class="ml-auto" :disabled="teamPermissionError">
					<icon-base class="mr-1">
						<icon-plus />
					</icon-base>
					Create Multiview
				</FormButton>
			</header>
			<section class="text-center mt-48 align-items-center">
				<div class="flex flex-col items-center">
					<icon-base width-view-box="130" height-view-box="90" width="130" height="90" iconColor="none" class="mb-3">
						<icon-multiview-empty-placeholder-image />
					</icon-base>
				</div>

				<MultiviewTeamPermissionError v-if="teamPermissionError" :email="userEmail" />
				<p v-else class="text-sm text-surface-7 mb-3">Monitor multiple streams easily</p>

				<p v-if="!teamPermissionError" class="flex flex-col w-full items-center">
					<FormButton type="secondary" class="mb-3 w-64 justify-center"
						href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
						<icon-base class="mr-2">
							<icon-book-open />
						</icon-base>
						How Multiview works
					</FormButton>
					<FormButton type="secondary" class="mb-3 w-64 justify-center" @click.prevent="showDemo">
						<icon-base class="mr-2">
							<icon-play />
						</icon-base>
						Watch a tutorial
					</FormButton>
					<FormButton class=" w-64 justify-center" @click="showCreateMultiviewModal">
						<icon-base class="mr-2">
							<icon-plus />
						</icon-base>
						Create Multiview
					</FormButton>
				</p>
			</section>
		</main>

		<main v-else-if="multiviews.length > 0">
			<header class="flex items-center mb-3 mt-5 !mt-24 md:!mt-14">
				<div class="">
					<h1 class="text-2xl font-medium m-0">
						Multiview
					</h1>
					<p class="text-dark-6 text-s-s m-0">
						<a target="_blank" class="text-reset mr-3 inline-flex items-center"
							href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							</svg>
							How Multiview works
						</a>
						<a class="text-reset mr-3 inline-flex items-center" @click.prevent="showDemo">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
								fill="#8794B5" />
							</svg>
							Multiview Demo
						</a>
					</p>
				</div>
				<FormButton :disabled ="(!hasLiveSubscription || isLiveSubscriptionExpired || maxMultiviews === 0)" @click="showCreateMultiviewModal" class="ml-auto">
					<icon-base
          				class="mr-2">
						<icon-plus />
					</icon-base>
					Create Multiview
				</FormButton>
			</header>
			<section class="flex w-100 flex-wrap md:!flex-nowrap items-center my-3">
				<!-- <div class="mr-0 md:mr-2 mb-2 mb-md-0 filter-wrapper">
					<drop-down-menu ref="sortDropdown" class="" type="secondary" customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize text-nowrap">
						<template #toggle-button>
							{{ sortBy }}
							<icon-base class="ml-2">
								<icon-chevron-down />
							</icon-base>
						</template>
						<template #menu-items>
							<drop-down-menu-item @click="changeSortBy('Sort by Oldest')">
								Sort by Oldest
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('Sort by Newest')">
								Sort by Newest
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('A to Z')">
								A to Z
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('Z to A')">
								Z to A
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
				</div> -->
				<div :class="{ active: searchWidgetActive }"
					class="flex items-center relative">
					<FormButton class="absolute z-10" isIcon type="link" @click="toggleSearchWidget">
						<icon-base>
							<icon-search />
						</icon-base>
					</FormButton>
					<FormInput ref="searchWidgetInputElement" v-model="searchWidgetInput"
						:placeholder="searchWidgetActive ? 'Search for multiviews ..' : ''" class="transition" customClasses="!pl-10" :class="searchWidgetActive ? 'w-[250px] !opacity-100' : 'w-px !opacity-0'"
						@blur="onSearchWidgetBlur"></FormInput>
				</div>
			</section>
			<section class="">
				<ul class="grid grid-cols-1 divide-y divide-surface-2">
					<li class="py-2" v-for="(multiview, index) in multiviewFiltered" :key="index">
						<div class="flex relative"
							:class="'flex-row items-center'">
							<router-link 
								class="text-reset hover:no-underline flex items-center" 
								:class="{'grid  w-full grid-cols-[1fr_2fr] md:grid-cols-[3rem_2fr_1fr_10px] !py-2 md:!py-0': true }" 
								:to="{ name: 'MultiviewContent', params: { id: multiview._id }, query: {'sourceTab' : 'livestream'} }">

								<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M18 20.6668C18 18.1527 18 16.8956 18.781 16.1145C19.5621 15.3335 20.8192 15.3335 23.3333 15.3335C25.8475 15.3335 27.1046 15.3335 27.8856 16.1145C28.6667 16.8956 28.6667 18.1527 28.6667 20.6668V23.3335C28.6667 25.8477 28.6667 27.1047 27.8856 27.8858C27.1046 28.6668 25.8475 28.6668 23.3333 28.6668C20.8192 28.6668 19.5621 28.6668 18.781 27.8858C18 27.1047 18 25.8477 18 23.3335V20.6668Z" stroke="#AEBAD6" stroke-width="1.2"/>
									<path d="M2.6665 11.3335C2.6665 13.8477 2.6665 15.1047 3.44755 15.8858C4.2286 16.6668 5.48568 16.6668 7.99984 16.6668C10.514 16.6668 11.7711 16.6668 12.5521 15.8858C13.3332 15.1047 13.3332 13.8477 13.3332 11.3335V8.66683C13.3332 6.15267 13.3332 4.89559 12.5521 4.11454C11.7711 3.3335 10.514 3.3335 7.99984 3.3335C5.48568 3.3335 4.2286 3.3335 3.44755 4.11454C2.6665 4.89559 2.6665 6.15267 2.6665 8.66683V11.3335Z" stroke="#AEBAD6" stroke-width="1.2"/>
									<path d="M18 7.3335C18 6.09099 18 5.46973 18.203 4.97967C18.4736 4.32627 18.9928 3.80713 19.6462 3.53648C20.1362 3.3335 20.7575 3.3335 22 3.3335H24.6667C25.9092 3.3335 26.5304 3.3335 27.0205 3.53648C27.6739 3.80713 28.193 4.32627 28.4637 4.97967C28.6667 5.46973 28.6667 6.09099 28.6667 7.3335C28.6667 8.57601 28.6667 9.19726 28.4637 9.68732C28.193 10.3407 27.6739 10.8599 27.0205 11.1305C26.5304 11.3335 25.9092 11.3335 24.6667 11.3335H22C20.7575 11.3335 20.1362 11.3335 19.6462 11.1305C18.9928 10.8599 18.4736 10.3407 18.203 9.68732C18 9.19726 18 8.57601 18 7.3335Z" stroke="#AEBAD6" stroke-width="1.2"/>
									<path d="M2.6665 24.6665C2.6665 25.909 2.6665 26.5303 2.86949 27.0203C3.14014 27.6737 3.65927 28.1929 4.31268 28.4635C4.80274 28.6665 5.42399 28.6665 6.6665 28.6665H9.33317C10.5757 28.6665 11.1969 28.6665 11.687 28.4635C12.3404 28.1929 12.8595 27.6737 13.1302 27.0203C13.3332 26.5303 13.3332 25.909 13.3332 24.6665C13.3332 23.424 13.3332 22.8027 13.1302 22.3127C12.8595 21.6593 12.3404 21.1401 11.687 20.8695C11.1969 20.6665 10.5757 20.6665 9.33317 20.6665H6.6665C5.42399 20.6665 4.80274 20.6665 4.31268 20.8695C3.65927 21.1401 3.14014 21.6593 2.86949 22.3127C2.6665 22.8027 2.6665 23.424 2.6665 24.6665Z" stroke="#AEBAD6" stroke-width="1.2"/>
								</svg>

								<div :class="'py-2 w-2/3 md:w-9/12'">
									<h2 class="text-bace font-medium mb-1">
										{{ multiview.name}}
									</h2>

									<p class="text-tiny mb-0 flex items-center" :class="'text-surface-7'">
										{{ multiviewStreamsNum(multiview) }}/{{ multiview.layout || 0 }} Streams
									</p>
								</div>

								<div class="text-right flex items-center justify-end">
									<router-link 
										:to="{ name: 'MultiviewContent', params: { id: multiview._id }, query: {'sourceTab' : 'livestream'} }">
										<FormButton type="secondary" size="sm" class="mr-2">
											Edit
										</FormButton>
									</router-link>
									<FormButton @click.prevent="onDeleteMultiviewRequest(multiview._id)" type="danger" size="sm" isIcon>
										<icon-base>
											<icon-bin />
										</icon-base>
									</FormButton>
								</div>
							</router-link>
						</div>
					</li>
				</ul>
			</section>
		</main>
		<main v-else>
			<header class="flex items-center mb-3 mt-5 !mt-24 md:!mt-14">
				<div class="">
					<h1 class="text-2xl font-medium m-0">
						Multiview
					</h1>
					<p class="text-dark-6 text-s-s m-0">
						<a target="_blank" class="text-reset mr-3 inline-flex items-center"
							href="https://docs.castr.com/en/articles/10363776-multiview-monitoring-multiple-livestreams-at-once">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path d="M8 15V5C8 3.61929 6.88071 2.5 5.5 2.5H1.5V12.5H5.75C6.99264 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 15V5C8 3.61929 9.11929 2.5 10.5 2.5H14.5V12.5H10.25C9.00736 12.5 8 13.5074 8 14.75V15Z"
								stroke="#8794B5" stroke-width="1.5" stroke-linejoin="round" />
							</svg>
							How Multiview works
						</a>
						<a class="text-reset mr-3 inline-flex items-center" @click.prevent="showDemo">
							<svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
							fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M9 2.8999C8.66863 2.8999 8.4 3.16853 8.4 3.4999C8.4 3.83127 8.66863 4.0999 9 4.0999H14.5C14.8314 4.0999 15.1 3.83127 15.1 3.4999C15.1 3.16853 14.8314 2.8999 14.5 2.8999H9ZM9 7.3999C8.66863 7.3999 8.4 7.66853 8.4 7.9999C8.4 8.33127 8.66863 8.5999 9 8.5999H14.5C14.8314 8.5999 15.1 8.33127 15.1 7.9999C15.1 7.66853 14.8314 7.3999 14.5 7.3999H9ZM2 11.8999C1.66863 11.8999 1.4 12.1685 1.4 12.4999C1.4 12.8313 1.66863 13.0999 2 13.0999H14.5C14.8314 13.0999 15.1 12.8313 15.1 12.4999C15.1 12.1685 14.8314 11.8999 14.5 11.8999H2ZM1 3.80567V8.19412C1 8.77296 1.62791 9.13362 2.12791 8.84195L5.88943 6.64771C6.38556 6.3583 6.38555 5.64145 5.88942 5.35204L2.1279 3.15784C1.62791 2.86618 1 3.22683 1 3.80567Z"
								fill="#8794B5" />
							</svg>
							Multiview Demo
						</a>
					</p>
				</div>
				<FormButton :disabled="(!hasLiveSubscription || isLiveSubscriptionExpired || maxMultiviews === 0)" @click="showCreateMultiviewModal" class="ml-auto" >
					<icon-base
          				class="mr-2">
						<icon-plus />
					</icon-base>
					Create Multiview
				</FormButton>
			</header>
			<section class="flex w-100 flex-wrap md:!flex-nowrap items-center my-3">
				<div class="mr-0 md:mr-2 mb-2 mb-md-0 filter-wrapper">
					<drop-down-menu ref="sortDropdown" class="" type="secondary" customClasses="w-full text-tiny disabled:text-surface-7 h-8 bg-surface-3 rounded-md flex items-center px-3 capitalize text-nowrap">
						<template #toggle-button>
							{{ sortBy }}
							<icon-base class="ml-2">
								<icon-chevron-down />
							</icon-base>
						</template>
						<template #menu-items>
							<drop-down-menu-item @click="changeSortBy('Sort by Oldest')">
								Sort by Oldest
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('Sort by Newest')">
								Sort by Newest
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('A to Z')">
								A to Z
							</drop-down-menu-item>
							<drop-down-menu-item @click="changeSortBy('Z to A')">
								Z to A
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
				</div>
				<div :class="{ active: searchWidgetActive }"
					class="flex items-center relative">
					<FormButton class="absolute z-10" isIcon type="link" @click="toggleSearchWidget">
						<icon-base>
							<icon-search />
						</icon-base>
					</FormButton>
					<FormInput ref="searchWidgetInputElement" v-model="searchWidgetInput"
						:placeholder="searchWidgetActive ? 'Search for multiviews ..' : ''" class="transition" customClasses="!pl-10" :class="searchWidgetActive ? 'w-[250px] !opacity-100' : 'w-px !opacity-0'"
						@blur="onSearchWidgetBlur"></FormInput>
				</div>
				<ViewModeSwitcher class="ml-auto" @toggle-view="toggleView" />
			</section>
			<section>
				<ul class="grid" :class="viewModeClasses">
					<li class="" v-for="(playout, index) in tvPlayoutsFiltered" :key="index">
					</li>
				</ul>
			</section>
		</main>
		<demo-video-modal ref="modalDEMOVideo" videoid="cm5nfhg2e05nqm5nfs3y3h7j1" />
		<add-multiview-modal @multiview-added="onNewMV" ref="showMultiviewModal" />

		<confirm-modal 
			modalId="delete-multiview" 
			message="Are you sure you want to delete your Multiview ?"
			modal-type="danger" 
			@modal-confirm="onMultiviewDelete" />
	</div>
</template>

<!-- <style scoped>
 .email-tooltip {
	display: inline;
	font-weight: 500 !important;
	font-size: 13px !important;
 }
</style> -->
